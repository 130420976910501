import { IconProps } from '../types';

export default function Book(props: IconProps) {
  const { fill = 'currentColor', style, className } = props;

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill={fill}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6818 7.76249C16.0166 7.67877 16.356 7.88237 16.4397 8.21724C16.5234 8.55211 16.3198 8.89145 15.9849 8.97516L12.6516 9.8085C12.3167 9.89221 11.9774 9.68861 11.8937 9.35374C11.81 9.01887 12.0136 8.67954 12.3484 8.59582L15.6818 7.76249Z"
        fill={fill}
      />
      <path
        d="M15.6818 11.0958C16.0166 11.0121 16.356 11.2157 16.4397 11.5506C16.5234 11.8854 16.3198 12.2248 15.9849 12.3085L12.6516 13.1418C12.3167 13.2255 11.9774 13.0219 11.8937 12.6871C11.81 12.3522 12.0136 12.0129 12.3484 11.9292L15.6818 11.0958Z"
        fill={fill}
      />
      <path
        d="M3.56035 8.21724C3.64407 7.88237 3.9834 7.67877 4.31827 7.76249L7.65161 8.59582C7.98648 8.67954 8.19008 9.01887 8.10636 9.35374C8.02264 9.68861 7.68331 9.89221 7.34844 9.8085L4.0151 8.97516C3.68023 8.89145 3.47663 8.55211 3.56035 8.21724Z"
        fill={fill}
      />
      <path
        d="M3.56035 11.5506C3.64407 11.2157 3.9834 11.0121 4.31827 11.0958L7.65161 11.9292C7.98648 12.0129 8.19008 12.3522 8.10636 12.6871C8.02264 13.0219 7.68331 13.2255 7.34844 13.1418L4.0151 12.3085C3.68023 12.2248 3.47663 11.8854 3.56035 11.5506Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9584 4.97982C18.9584 3.7604 17.9873 2.71461 16.7132 2.75899C15.7646 2.79204 14.5247 2.89071 13.5708 3.17628C12.7416 3.42452 11.8089 3.90939 11.0818 4.33018C10.4001 4.72473 9.5514 4.74441 8.86072 4.37919C8.03116 3.94054 6.93974 3.42059 5.99282 3.17071C5.19214 2.95943 4.17933 2.85795 3.35703 2.80741C2.0585 2.7276 1.04169 3.78436 1.04169 5.03366V14.3218C1.04169 15.602 2.07817 16.6014 3.30672 16.6783C4.10569 16.7283 5.00474 16.8244 5.67389 17.0009C6.50102 17.2192 7.52386 17.7045 8.3497 18.1447C9.37746 18.6926 10.6226 18.6926 11.6503 18.1447C12.4762 17.7045 13.499 17.2192 14.3262 17.0009C14.9953 16.8244 15.8944 16.7283 16.6933 16.6783C17.9219 16.6014 18.9584 15.602 18.9584 14.3218V4.97982ZM16.7568 4.00823C17.2691 3.99039 17.7084 4.41183 17.7084 4.97982V14.3218C17.7084 14.8933 17.2348 15.392 16.6153 15.4307C15.7986 15.4818 14.7993 15.5833 14.0072 15.7923C13.0319 16.0497 11.9023 16.5939 11.0623 17.0417C10.9221 17.1164 10.7754 17.1753 10.625 17.2183V5.82052C11.002 5.74507 11.3684 5.60856 11.708 5.41206C12.4167 5.0019 13.2458 4.5784 13.9293 4.37377C14.7202 4.13698 15.8244 4.04071 16.7568 4.00823ZM9.37502 5.84797C8.99584 5.78901 8.62418 5.66811 8.27641 5.48422C7.4624 5.05379 6.47565 4.59091 5.67389 4.37934C4.99704 4.20074 4.08534 4.10453 3.28035 4.05505C2.75205 4.02258 2.29169 4.45267 2.29169 5.03366V14.3218C2.29169 14.8933 2.76528 15.392 3.38475 15.4307C4.20149 15.4818 5.20079 15.5833 5.99282 15.7923C6.96811 16.0497 8.09774 16.5939 8.9377 17.0417C9.0779 17.1164 9.22459 17.1753 9.37502 17.2183V5.84797Z"
        fill={fill}
      />
    </svg>
  );
}
