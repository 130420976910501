import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const StatsContainer = styled.div<{ color: string; cursor?: string }>`
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  border-radius: 12px;
  width: 160px;
  cursor: ${(props) => props.cursor || 'default'};
`;

export const StatsTitle = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;
