import { DownOutlined } from '@ant-design/icons';
import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { HeaderActionWrapper } from '../../../Header.styles';
import { FaEllipsisVertical } from 'react-icons/fa6';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)<{ isPointerCursor?: boolean }>`
  cursor: ${(props) => (props.isPointerCursor ? 'pointer' : 'default')};
  display: flex;
  justify-content: center;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 1rem;
  }
  overflow: hidden;
`;
