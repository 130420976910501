/* eslint-disable no-var */
import { useEffect } from 'react';

import { TimeLine } from '@app/components/tables/AntdTableWrapper/components/Timeline';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import { DEFAULT_DATE_FORMAT } from '@app/components/tables/data-table/constants';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { useMounted } from '@app/hooks/useMounted';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import EventHistoryChart from './event-history-chart/EventHistoryChart';
import { TEventLogs } from '@app/pages/RecommendationHistory';
import { DatePicker } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';

interface IAllHistoryProps<T extends { id: number | string }> {
  tableColumns: TDataColumns<T>;
  actionItems: TActionItems<T>[];
  timelineRange?: RangeValue<moment.Moment>;
  hoveredDate?: string;
  setTimelineRange: (rangeValue: RangeValue<moment.Moment> | undefined) => void;
  setHoveredDate: (hoveredDate?: string) => void;
  setActiveDataTableProps: (dataTableProps: ReturnType<typeof useDataTable<T>>) => void;
  setActiveTableFilterProps: (tableFilterProps: ReturnType<typeof useTableFiltersV2<T>>) => void;
  handleAdditionalDetailsClicked: (props: { changeLogDate: string; eventsText: TEventLogs }) => void;
  onRowClickCallback?: (row: T) => void;
  setSelectedRows: (rows: TAccountRecommendationExtended[]) => void;
}

const AllHistory = <T extends { id: number | string }>({
  tableColumns,
  actionItems,
  timelineRange,
  hoveredDate,
  setTimelineRange,
  setHoveredDate,
  setActiveDataTableProps,
  setActiveTableFilterProps,
  handleAdditionalDetailsClicked,
  onRowClickCallback,
  setSelectedRows,
}: IAllHistoryProps<T>) => {
  const { isMounted } = useMounted();

  const tableFilterProps = useTableFiltersV2<T>({
    columns: tableColumns,
    model: 'accountRecommendationHistory',
    searchableColumns: ['productName', 'findings'],
    constantFilter: {
      date: { ge: timelineRange?.[0]?.toISOString(), le: timelineRange?.[1]?.toISOString() },
    },
    orderBy: {
      column: 'date',
      order: 'desc',
    },
    defaultPaginationParams: {
      top: 50,
      skip: 0,
    },
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  var dataTableProps = useDataTable<T>({
    data: tableFilterProps.data?.items || [],
    columns: tableColumns,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    defaultLimit: 50,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowMouseEnter(data, index) {
      setHoveredDate((data as any).date);
    },
    onRowMouseLeave(data, index) {
      setHoveredDate(undefined);
    },
    onRowClick: (data, index) => {
      onRowClickCallback?.(data);
    },
    onRowSelect: (selectedRows) => {
      /**
       * the accountRecommendationHistory odata returns a property "accountRecommendationId"
       * to identify the accountRecommendationId of a recommendation. The type "TAccountRecommendationExtended"
       * has an id property to serve the same purpose. So to make them consistent and so that other
       * components wont confuse the ids used to execute actions, the property
       * "accountRecommendationId" is mapped to "id"
       */

      const mappedRows: TAccountRecommendationExtended[] = selectedRows.map((row: any) => ({
        ...row,
        id: row.accountRecommendationId,
      }));

      setSelectedRows(mappedRows);
    },
  });

  const onDatePickerChange = (value: RangeValue<moment.Moment>, dateString: [string, string]) => {
    if (!value) {
      setTimelineRange(undefined);
      return;
    }

    setTimelineRange(value);
    tableFilterProps.buildOdataQueryFromParams({
      currentConstantFilter: { date: { ge: value?.[0]?.toISOString(), le: value?.[1]?.toISOString() } },
    });
  };

  useEffect(() => {
    if (!!isMounted.current) {
      setActiveDataTableProps(dataTableProps);
      setActiveTableFilterProps(tableFilterProps);
    }
  }, [isMounted.current]);

  if (!timelineRange) return;

  const startDate = timelineRange[0]!.toDate();
  const endDate = timelineRange[1]!.toDate();

  return (
    <>
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <DatePicker.RangePicker
              value={timelineRange}
              format={DEFAULT_DATE_FORMAT}
              onChange={onDatePickerChange}
              allowClear={false}
            />
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
        </DataTableV2.Actions>
        <DataTableV2.Charts>
          <EventHistoryChart
            onAdditionalDetailsClicked={handleAdditionalDetailsClicked}
            startDate={startDate}
            endDate={endDate}
            type="all"
          />
        </DataTableV2.Charts>
      </DataTableV2>
    </>
  );
};

export { AllHistory };
