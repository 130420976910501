import { getBlobFileUrl } from '@app/api/azure-storage.api';
import { TReport } from '@app/types/reports';
import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa6';
import { FONT_WEIGHT } from '@app/styles/themes/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey-100);
  border-radius: 10px;
  background-color: white;
  width: 304px;
  height: 450px;
  padding: 16px;
`;

const Thumbnail = styled.img`
  height: 248px;
  width: 97%;
  border-radius: 4px;
  align-self: center;
  &:hover {
    cursor: pointer;
  }
`;

const NoThumbnailContainer = styled.div`
  height: 210px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  border-radius: 4px;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ReportNameAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 16px;
`;

const ReportName = styled.span`
  font-size: 1.25rem;
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--grey-950);
`;

const Description = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: var(--grey-700);
  word-wrap: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GenerateReportText = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--blue-600);
  gap: 8px;

  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

type IReportCardProps = TReport & {
  onGenerateReportClick?: (e: React.MouseEvent) => void;
};

export default function ReportCard({ id, name, description, thumbnailUrl, onGenerateReportClick }: IReportCardProps) {
  const blobUrl = !!thumbnailUrl ? getBlobFileUrl(thumbnailUrl) : undefined;
  return (
    <Container>
      {blobUrl ? <Thumbnail src={blobUrl} alt="" /> : <NoThumbnailContainer>No thumbnail</NoThumbnailContainer>}
      <ContentContainer>
        <ReportNameAndDescriptionContainer>
          <ReportName>{name}</ReportName>
          <Description>{description}</Description>
        </ReportNameAndDescriptionContainer>
        <GenerateReportText
          onClick={(e) => {
            e.stopPropagation();
            onGenerateReportClick?.(e);
          }}
        >
          Generate Report <FaArrowRight />
        </GenerateReportText>
      </ContentContainer>
    </Container>
  );
}
