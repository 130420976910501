import { useEffect, useState } from 'react';
import { TUsersExtended } from '@app/types/users';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import styled from 'styled-components';
import {
  BaseModalCloseButton,
  BaseModalTitle,
  VerticalContainer,
} from '@app/components/common/BaseLayout/BaseLayout.styled';
import { Spin, Radio, MenuProps, RadioChangeEvent, Typography, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { TGroupExtended } from '@app/types/groupExtended';
import { getEnumValue } from '@app/services/enum.service';
import { useUpdateUserRoleAndGroup } from '@app/api/user.api';
import { BaseCancelButton, BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const FieldLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 18px;
  color: var(--grey-950);
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin: 10px 0px;
`;

const CancelButton = styled.button`
  border: 1px solid var(--blue-200);
  border-radius: 4px;
  background-color: transparent;
  padding: 12px 34px;
  color: var(--blue-800);
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  width: 20%;
`;

const ApplyButton = styled.button`
  border: ${(props) => (props.disabled ? '1px solid var(--grey-100)' : '1px solid var(--blue-600)')};
  border-radius: 4px;
  background-color: ${(props) => (props.disabled ? 'var(--grey-100)' : 'var(--blue-600)')};
  padding: 12px 34px;
  color: ${(props) => (props.disabled ? 'var(--grey-300)' : 'white')};
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 20%;
`;

const DropdownContainer = styled.div`
  width: 100%;
  border: 1px solid #e9eaeb;
  border-radius: 10px;
`;

const Title = styled.span`
  color: var(--grey-950);
  font-size: 0.75rem;
  font-weight: 500;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onOk?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onOk, onApplyDisabled }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <BaseCancelButton onClick={() => onCancel?.()}>Cancel</BaseCancelButton>
      <BaseCTAButton onClick={() => onOk?.()} disabled={onApplyDisabled}>
        Update
      </BaseCTAButton>
    </FooterContainer>
  );
};

interface IEditUserModalProps extends ModalFuncProps {
  user: TUsersExtended | null;
  groups?: TGroupExtended[];
}

const DEFAULT_ROLE = getEnumValue('UserRole', 'User') || 2;

const EditUserModal = ({ user, groups, ...modalProps }: IEditUserModalProps) => {
  const [role, setRole] = useState(DEFAULT_ROLE);
  const [selectedGroup, setSelectedGroup] = useState<TGroupExtended | null>(null);
  const [isAdminRole, setIsAdminRole] = useState(false);
  const { mutate: updateUserRoleAndGroup, isLoading } = useUpdateUserRoleAndGroup();
  const { Text } = Typography;
  const [isSelectOpen, setSelectOpen] = useState(false);

  const items: MenuProps['items'] = groups?.map((g) => ({
    label: g.name,
    key: g.id,
  }));

  const handleDropdownVisibleChange = (open: boolean) => {
    setSelectOpen(open);
  };

  useEffect(() => {
    if (user) {
      const enumValueUserRole = getEnumValue('UserRole', user.role.toString()) || DEFAULT_ROLE;
      setRole(enumValueUserRole);
      setIsAdminRole(enumValueUserRole === getEnumValue('UserRole', 'Admin'));
      setSelectedGroup(groups?.find((g) => Number(g.id) === user.groupId) || null);
    }
  }, [user, groups]);

  const onClick = (e: any) => {
    const group = groups?.find((g) => g.id.toString() === e.key);
    setSelectedGroup(group || null);
  };

  const handleUpdateUserRoleAndGroup = () => {
    if (user) {
      updateUserRoleAndGroup(
        {
          id: user.id,
          payload: {
            role: role,
            groupId: role === getEnumValue('UserRole', 'Admin') ? null : Number(selectedGroup?.id),
          },
        },
        {
          onSuccess: (data) => {
            if (data) {
              modalProps.onOk?.();
            }
          },
        },
      );
    }
  };

  const handleCloseModal = () => {
    setRole(DEFAULT_ROLE);
    setIsAdminRole(false);
    setSelectedGroup(null);
    modalProps.onCancel?.();
  };

  const handleRoleChange = (e: RadioChangeEvent) => {
    setRole(e.target.value as number);
    setIsAdminRole(e.target.value === getEnumValue('UserRole', 'Admin'));
  };

  return (
    <Modal
      {...modalProps}
      style={{ minWidth: '600px' }}
      title={<BaseModalTitle>Edit User</BaseModalTitle>}
      destroyOnClose={true}
      closeIcon={<BaseModalCloseButton />}
      footer={
        isLoading ? (
          <FooterContainer>
            <Spin />
          </FooterContainer>
        ) : (
          <ModalFooter onCancel={handleCloseModal} onOk={handleUpdateUserRoleAndGroup} />
        )
      }
    >
      <Container>
        <Text type="secondary" style={{ fontSize: '1rem', color: 'var(--grey-600)' }}>
          Define the actions users in this group can perform
        </Text>
        <FieldContainer>
          <FieldLabel>User Email Address:</FieldLabel>
          <Text style={{ fontSize: '0.875rem', color: 'var(--grey-700)' }}>{user?.email}</Text>
        </FieldContainer>

        <FieldContainer>
          <FieldLabel>Assigned Role:</FieldLabel>
          <Radio.Group name="radiogroup" value={role} onChange={handleRoleChange}>
            <Radio value={1}>
              <VerticalContainer>
                <Title>Administrator</Title>
                <Text type="secondary" style={{ fontSize: '0.75rem', color: 'var(--grey-600)' }}>
                  Users with this role have the highest permissions level and can perform all actions, including
                  account-level settings configurations
                </Text>
              </VerticalContainer>
            </Radio>

            <Radio value={2}>
              <VerticalContainer>
                <Title>User</Title>
                <Text type="secondary" style={{ fontSize: '0.75rem', color: 'var(--grey-600)' }}>
                  Permissions for this role are defined according to the permissions assigned to the user’s group
                </Text>
              </VerticalContainer>
            </Radio>
          </Radio.Group>
        </FieldContainer>

        {isAdminRole ? null : (
          <FieldContainer>
            <FieldLabel>Assigned Group:</FieldLabel>
            <DropdownContainer>
              <Select
                placeholder="No Group"
                value={selectedGroup?.id?.toString()}
                onChange={(value) => {
                  const group = groups?.find((g) => g.id.toString() === value);
                  setSelectedGroup(group || null);
                }}
                style={{ width: '100%', fontSize: '0.875rem', color: 'var(--grey-600)' }}
                bordered={false}
                open={isSelectOpen}
                suffixIcon={
                  <DownOutlined rotate={isSelectOpen ? 180 : 0} onClick={() => setSelectOpen(!isSelectOpen)} />
                }
                onDropdownVisibleChange={handleDropdownVisibleChange}
              >
                {groups?.map((group) => (
                  <Select.Option key={group.id.toString()} value={group.id.toString()}>
                    {group.name}
                  </Select.Option>
                ))}
              </Select>
            </DropdownContainer>
          </FieldContainer>
        )}
      </Container>
    </Modal>
  );
};

export default EditUserModal;
