import styled from 'styled-components';

const SkeletonList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
  height: 100%;

  .skeleton-item {
    height: 25px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1.5s infinite;

    @keyframes shimmer {
      0% {
        background-position: 200% 0;
      }
      100% {
        background-position: -200% 0;
      }
    }
  }
`;

export const ComplianceDriftsSkeleton = () => (
  <SkeletonList>
    <div className="skeleton-item" style={{ width: '100%' }}></div>
    <div className="skeleton-item" style={{ width: '100%' }}></div>
    <div className="skeleton-item" style={{ width: '100%' }}></div>
  </SkeletonList>
);
