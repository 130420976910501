import { Checkbox, Modal, ModalFuncProps } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { TDataColumns } from '../../types';

const ApplyButton = styled.button`
  background-color: var(--blue-600);
  color: var(--white);
  padding: 12px 60px;
  border: 1px solid var(--blue-600);
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
`;
const CancelButton = styled.button`
  background-color: var(--white);
  color: var(--blue-800);
  padding: 12px 60px;
  border: 1px solid var(--blue-800);
  border-radius: 4px;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 12px;
  padding: 16px;
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 250px;
`;

interface IModalFooterProps {
  onCancel: () => void;
  onApply: () => void;
}

const ModalFooter = ({ onCancel, onApply }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <CancelButton onClick={onCancel}>Cancel</CancelButton>
      <ApplyButton onClick={onApply}>Apply</ApplyButton>
    </FooterContainer>
  );
};

interface ITableColumnsModalProps<T> extends ModalFuncProps {
  columns: TDataColumns<T>;
  onApply?: (selectedColumns: TDataColumns<T>) => void;
}

export function TableColumnsModal<T>({ columns, onApply, ...modalProps }: ITableColumnsModalProps<T>) {
  const defaultCheckedColumns = columns.filter((col) => !col.hideColumn) || [];

  const [selectedCols, setSelectedCols] = useState(defaultCheckedColumns);

  const handleOnCheckboxChange = (isSelected: boolean, dataIndex: string) => {
    if (isSelected) {
      const newCol = columns.find((col) => col.dataIndex === dataIndex);

      if (!newCol) return;

      const newSelectedCols = [...selectedCols, newCol];
      setSelectedCols(newSelectedCols);
      return;
    }

    const newSelectedCols = selectedCols.filter((col) => col.dataIndex !== dataIndex);
    setSelectedCols(newSelectedCols);
  };

  const handleOnCancel = () => {
    modalProps.onCancel?.();
    setSelectedCols(defaultCheckedColumns);
  };

  const handleOnApply = () => {
    const newCols = columns.map((col) => {
      const selectedCol = selectedCols.find((sCol) => sCol.dataIndex === col.dataIndex);
      if (!!selectedCol) {
        return { ...col, hideColumn: false };
      }

      return { ...col, hideColumn: true };
    });

    onApply?.(newCols);
    modalProps.onCancel?.();
  };

  return (
    <Modal
      title="Add or remove displayed fields"
      {...modalProps}
      destroyOnClose={true}
      footer={<ModalFooter onCancel={handleOnCancel} onApply={handleOnApply} />}
    >
      <ColumnsContainer>
        {columns
          .filter((col) => col.dataIndex !== undefined && col.allowDisplay !== false)
          .sort((a, b) => (a.dataIndex as string).toLowerCase().localeCompare((b.dataIndex as string).toLowerCase()))
          .map((col, index) => (
            <div key={index}>
              <Checkbox
                defaultChecked={!!selectedCols.find((sCol) => sCol.dataIndex === col.dataIndex)}
                onChange={(e) => handleOnCheckboxChange(e.target.checked, col.dataIndex as string)}
              >
                {typeof col.title === 'string' ? col.title : col.altLabel}
              </Checkbox>
            </div>
          ))}
      </ColumnsContainer>
    </Modal>
  );
}
