import React, { useState, useEffect } from 'react';
import { BellFilled, BellOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseBadge } from '@app/components/common/BaseBadge/BaseBadge';
import { HeaderActionContainer, HeaderActionWrapper } from '@app/components/header/Header.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { AccountNotificationsOverlay } from './AccountNotificationsOverlay/AccountNotificationsOverlay';
import { useGetModelItems } from '@app/api/table.api';
import { getEnumValue } from '@app/services/enum.service';
import { TAccountNotifications } from '@app/types/accountNotifications';
import {
  useMarkNotificationAsRead,
  useHideNotification,
  useUnhideNotification,
} from '@app/api/accountNotification.api';
import { useQueryClient } from 'react-query';
import AccountNotificationsBell from '@app/components/icons/accountnotifications/AccountNotificationsBell';

interface AccountNotificationsDropdownProps {
  isMobile: boolean;
}

export const AccountNotificationsDropdown: React.FC<AccountNotificationsDropdownProps> = ({ isMobile }) => {
  const queryClient = useQueryClient();

  const { data } = useGetModelItems<TAccountNotifications>({
    model: 'accountnotificationsextended',
    queryParams: `$filter=(status eq ${getEnumValue('NotificationStatus', 'Enabled')})&$orderby=creationTime desc`,
    interval: 300000,
  });

  const [notifications, setNotifications] = useState<TAccountNotifications[]>(data?.items || []);
  const [isOpened, setOpened] = useState(false);

  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsRead();
  const { mutateAsync: hideNotification } = useHideNotification();
  const { mutateAsync: unhideNotification } = useUnhideNotification();

  useEffect(() => {
    if (data?.items) setNotifications(data.items);
  }, [data]);

  const unreadNotificationsCount = notifications.filter((n) => !n.isRead && !n.isHidden).length;

  const updateNotification = (notificationId: number, updates: Partial<TAccountNotifications>) => {
    setNotifications((prev) => prev.map((n) => (n.notificationId === notificationId ? { ...n, ...updates } : n)));
  };

  const processNotification = async (notificationId: number, action: 'read' | 'hide' | 'unhide') => {
    try {
      if (action === 'read') updateNotification(notificationId, { isRead: true });
      else if (action === 'hide') updateNotification(notificationId, { isHidden: true });
      else if (action === 'unhide') updateNotification(notificationId, { isHidden: false });

      const ACTION_MAP = {
        read: markNotificationAsRead,
        hide: hideNotification,
        unhide: unhideNotification,
      };

      await ACTION_MAP[action](notificationId);
      queryClient.invalidateQueries('accountnotificationsextended', { refetchActive: true });
    } catch (error) {
      console.error(`Error processing notification ${action}:`, error);
    }
  };

  const handleNotificationClick = (notificationId: number, actionType: 'notification' | 'hide' | 'unhide') => {
    const action = actionType === 'hide' ? 'hide' : actionType === 'unhide' ? 'unhide' : 'read';
    processNotification(notificationId, action);
    setOpened(false);
  };

  return (
    <BasePopover
      trigger="click"
      content={
        <AccountNotificationsOverlay
          data={notifications}
          onNotificationClick={handleNotificationClick}
          unreadCount={unreadNotificationsCount}
        />
      }
      open={isOpened}
      onOpenChange={setOpened}
      placement="bottomLeft"
      overlayClassName="notifications-dropdown"
    >
      <BaseBadge
        count={unreadNotificationsCount}
        style={{ fontSize: 12, backgroundColor: 'var(--orange-300)', color: 'var(--blue-950)' }}
      >
        <HeaderActionWrapper>
          <HeaderActionContainer>
            <BaseButton type={'text'} icon={<AccountNotificationsBell />} />
          </HeaderActionContainer>
        </HeaderActionWrapper>
      </BaseBadge>
    </BasePopover>
  );
};
