import { useMemo } from 'react';
import styled from 'styled-components';
import { differenceInDays, format } from 'date-fns';
import { Popover, Skeleton } from 'antd';
import pluralize from 'pluralize';

import { useGetModelItems } from '@app/api/table.api';
import { TChangeLogs, TDailyEvents } from '@app/types/changelogs';
import { useResponsive } from '@app/hooks/useResponsive';

const DATE_MINIMUM_DISTANCE_VISIBILITY = 8;
const EVENT_MARKER_MAX_DISTANCE = 98;

const TimeLineContainer = styled.div`
  background-color: white;
  border: 1px solid #e9eaeb;
  border-radius: 9px;
  margin-bottom: 24px;
  padding: 48px 80px;
  overflow: hidden;
`;

const GrayLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: #d9d9d9;
  position: absolute;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

const StartEndEvent = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #acacac;
  z-index: 10;
`;

const EventsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  animation: slideInLeft;
  animation-duration: 0.8s;
`;

const Event = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  z-index: 20;

  position: absolute;
`;

const EventDate = styled.span`
  animation: zoomIn;
  animation-duration: 2s;
`;

const EventMarker = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: green;
`;

// used for centering the events
const PlaceholderBlock = styled.div`
  visibility: hidden;
`;

const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export type TTimelineType = 'all' | 'configuration changes' | 'assignments' | 'exemptions';

interface TimeLineProps {
  startDate: Date;
  endDate: Date;
  hoveredDate?: string;
  setHoveredDate?: any;
  additionalQueryParams?: string;
  type?: TTimelineType;
  handleAdditionalDetailsClicked: (props: { changeLogDate: string; eventsText: string }) => void;
}

//! This component is deprecated, use EventHistoryChart instead.
export function TimeLine({
  startDate,
  endDate,
  hoveredDate,
  setHoveredDate,
  additionalQueryParams,
  type = 'all',
  handleAdditionalDetailsClicked,
}: TimeLineProps) {
  const dateRangeQueryParams = `$filter=date ge ${startDate.toISOString()} and date le ${endDate.toISOString()}${
    !!additionalQueryParams ? ` and ${additionalQueryParams}` : ''
  }&orderby=date asc`;
  const { data, isFetching } = useGetModelItems<TChangeLogs>({
    model: 'dailyChanges',
    queryParams: dateRangeQueryParams,
    customQueryKey: ['dailyChanges-odata', dateRangeQueryParams].join(','),
  });

  const dailyChangesItems = useMemo(() => {
    if (!data || !data.items) return [];
    const sorted = data.items.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return sorted.map((item, index) => {
      let shouldShowDate = false;
      const distancePercentage = eventMarkerDistance(startDate, endDate, new Date(item.date));
      if (index !== 0 && index + 1 !== data.items.length) {
        const nextDistancePercentage = eventMarkerDistance(startDate, endDate, new Date(data.items[index + 1].date));

        if (nextDistancePercentage - distancePercentage >= DATE_MINIMUM_DISTANCE_VISIBILITY) {
          shouldShowDate = true;
        }
      } else {
        // if (
        //   distancePercentage > DATE_MINIMUM_DISTANCE_VISIBILITY ||
        //   EVENT_MARKER_MAX_DISTANCE - distancePercentage > DATE_MINIMUM_DISTANCE_VISIBILITY
        // ) {
        //   shouldShowDate = true;
        // }
      }

      return {
        ...item,
        shouldShowDate,
        distancePercentage,
      };
    });
  }, [data]);

  const isHovered = (item: TChangeLogs) => {
    return hoveredDate === format(item.date, 'dd/MM/yyyy');
  };

  return (
    <TimeLineContainer>
      <InnerContainer>
        {isFetching ? (
          <SkeletonContainer>
            <Skeleton.Avatar active shape="circle" size="small" />
            <Skeleton.Input active block size="small" style={{ height: '4px', marginTop: '10px' }} />
            <Skeleton.Avatar active shape="circle" size="small" />
          </SkeletonContainer>
        ) : (
          <>
            <GrayLine />
            <StartEndEvent />
            <EventsContainer>
              {dailyChangesItems.map((item, i) => (
                <Popover
                  key={i}
                  open={isHovered(item)}
                  onOpenChange={(visible) => {
                    if (visible) {
                      setHoveredDate?.(format(item.date, 'dd/MM/yyyy'));
                    } else {
                      setHoveredDate?.(undefined);
                    }
                  }}
                  content={
                    <EventToolTip
                      data={item}
                      type={type}
                      handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
                    />
                  }
                >
                  <Event
                    style={{
                      left: `${item.distancePercentage}%`,
                    }}
                  >
                    {item.shouldShowDate && (
                      <EventDate
                        style={{
                          fontWeight: isHovered(item) ? 'bold' : 'normal',
                        }}
                      >
                        {format(item.date, 'dd/MM/yyyy')}
                      </EventDate>
                    )}

                    <EventMarker
                      style={{
                        width: eventMarkerSize({ data: item, type }),
                        height: eventMarkerSize({ data: item, type }),
                        backgroundColor: eventMarkerColor(item),
                        outline: isHovered(item) ? '4px solid blue' : '',
                      }}
                    />

                    {item.shouldShowDate && <PlaceholderBlock>placeholder</PlaceholderBlock>}
                  </Event>
                </Popover>
              ))}
            </EventsContainer>
            <StartEndEvent />
          </>
        )}
      </InnerContainer>
    </TimeLineContainer>
  );
}

export function eventMarkerColor(data: TDailyEvents, type?: TTimelineType): string {
  const becameCompliantNum =
    typeof data.became_compliant === 'string' ? parseInt(data.became_compliant, 10) : data.became_compliant;
  const becameNonCompliantNum =
    typeof data.became_non_compliant === 'string' ? parseInt(data.became_non_compliant, 10) : data.became_non_compliant;

  const markedExemptedNum =
    typeof data.marked_exempted === 'string' ? parseInt(data.marked_exempted, 10) : data.marked_exempted;

  const markedNonExemptedNum =
    typeof data.marked_non_exempted === 'string' ? parseInt(data.marked_non_exempted, 10) : data.marked_non_exempted;

  const numOfImprovements = becameCompliantNum + markedNonExemptedNum;
  const numOfRegressions = becameNonCompliantNum + markedExemptedNum;

  //For "assignments" tab, all markers are gray
  if (type === 'assignments') {
    return 'gray';
  }

  if (numOfImprovements > 0 && numOfRegressions === 0) {
    return 'green';
  }

  if (numOfImprovements == 0 && numOfRegressions > 0) {
    return 'red';
  }

  return 'gray';
}

function eventMarkerSize({ data, type }: { data: TChangeLogs; type: TTimelineType }) {
  const becameCompliantNum = type !== 'all' && type !== 'configuration changes' ? 0 : parseInt(data.becameCompliant);
  const becameNonCompliantNum =
    type !== 'all' && type !== 'configuration changes' ? 0 : parseInt(data.becameNonCompliant);
  const markedExemptedNum = type !== 'all' && type !== 'exemptions' ? 0 : parseInt(data.markedExempted);
  const markedNonExemptedNum = type !== 'all' && type !== 'exemptions' ? 0 : parseInt(data.markedNonExempted);
  const assignmentsNum = type !== 'all' && type !== 'assignments' ? 0 : parseInt(data.assignments);

  const numberOfChanges =
    becameCompliantNum + becameNonCompliantNum + markedExemptedNum + markedNonExemptedNum + assignmentsNum;
  const minSize = 16;
  const maxSize = 36;
  const markerSize = minSize + numberOfChanges * 2;

  if (markerSize > maxSize) {
    return `${maxSize}px`;
  }

  return `${markerSize}px`;
}

function eventMarkerDistance(startDate: Date, endDate: Date, targetDate: Date) {
  const totalDurationInDays = differenceInDays(endDate, startDate);

  const markerDurationInDays = differenceInDays(targetDate, startDate);

  const distancePercentage = (markerDurationInDays / totalDurationInDays) * 100;
  if (distancePercentage >= EVENT_MARKER_MAX_DISTANCE) return EVENT_MARKER_MAX_DISTANCE;
  if (distancePercentage <= 0) return 0;

  return distancePercentage;
}

interface EventToolTipProps {
  data: TChangeLogs;
  type: TTimelineType;
  handleAdditionalDetailsClicked: (props: { changeLogDate: string; eventsText: string }) => void;
}

function EventToolTip({ data, type, handleAdditionalDetailsClicked }: EventToolTipProps) {
  const { isBigScreen } = useResponsive();

  const becameCompliantNum = type !== 'all' && type !== 'configuration changes' ? 0 : parseInt(data.becameCompliant);
  const becameNonCompliantNum =
    type !== 'all' && type !== 'configuration changes' ? 0 : parseInt(data.becameNonCompliant);
  const riskyNonCompliantNum =
    type !== 'all' && type !== 'configuration changes' ? 0 : parseInt(data.riskyNonCompliant);
  const markedExemptedNum = type !== 'all' && type !== 'exemptions' ? 0 : parseInt(data.markedExempted);
  const markedNonExemptedNum = type !== 'all' && type !== 'exemptions' ? 0 : parseInt(data.markedNonExempted);
  const assignmentsNum = type !== 'all' && type !== 'assignments' ? 0 : parseInt(data.assignments);

  const eventText = `${
    becameNonCompliantNum > 0
      ? `${becameNonCompliantNum} ${pluralize('recommendation', becameNonCompliantNum)} became non-compliant ${
          riskyNonCompliantNum > 0
            ? `, including ${riskyNonCompliantNum} ${pluralize(
                'recommendation',
                riskyNonCompliantNum,
              )} with high risk level.`
            : '.'
        }`
      : ''
  }`;

  const additionalEventsText = `
  ${
    markedExemptedNum > 0
      ? `\n${markedExemptedNum} ${pluralize('recommendation', markedExemptedNum)} ${pluralize(
          'was',
          markedExemptedNum,
        )} marked as exempted.`
      : ''
  }${
    markedNonExemptedNum > 0
      ? `\n${markedNonExemptedNum} ${pluralize('recommendation', markedNonExemptedNum)} ${pluralize(
          'was',
          markedNonExemptedNum,
        )} marked as non-exempted.`
      : ''
  }${
    assignmentsNum > 0 ? `\n${assignmentsNum} ${pluralize('recommendation', assignmentsNum)} assignments changed.` : ''
  }`;

  // Temporarly disabled causes text in time logic for data here is wrong
  // const causesText = data.configChanges
  //   ? data.configChanges.map((changes) => {
  //       const createdNum = changes.created || 0;
  //       const updatedNum = changes.updated || 0;
  //       const deletedNum = changes.deleted || 0;

  //       return `${
  //         createdNum > 0
  //           ? `${createdNum} ${pluralize(changes.name, createdNum)} ${pluralize(`was`, createdNum)} created.`
  //           : ''
  //       }${
  //         updatedNum > 0
  //           ? `${updatedNum} ${pluralize(changes.name, updatedNum)} ${pluralize(`was`, updatedNum)} updated.`
  //           : ''
  //       }${
  //         deletedNum > 0
  //           ? `${deletedNum} ${pluralize(changes.name, deletedNum)} ${pluralize(`was`, deletedNum)} deleted.`
  //           : ''
  //       }`;
  //     })
  //   : '';
  const causesText = '';
  return (
    <div style={{ fontSize: '12px', color: '#303030', maxWidth: isBigScreen ? '10vw' : '13vw' }}>
      <span style={{ fontWeight: 'bold' }}>Date</span>
      <br />
      <>{format(data.date, 'dd/MM/yyyy')}</>
      <br />
      <br />
      <span style={{ fontWeight: 'bold' }}>Events</span>
      <br />
      <div>{eventText}</div>
      <div>{additionalEventsText}</div>
      <br />
      {becameCompliantNum > 0
        ? `\n${becameCompliantNum} ${pluralize('recommendation', becameCompliantNum)} became compliant.`
        : ''}
      {causesText.length > 0 && (
        <>
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>Causes</span>
          <br />
          {causesText}
        </>
      )}

      <br />
      <br />
      <span
        style={{
          fontWeight: 'bold',
          color: '#3A50D1',
          cursor: 'pointer',
        }}
        onClick={() =>
          handleAdditionalDetailsClicked?.({
            changeLogDate: data.date,
            eventsText: `${eventText}\n${additionalEventsText}${
              becameCompliantNum > 0
                ? `\n${becameCompliantNum} ${pluralize('recommendation', becameCompliantNum)} became compliant.`
                : ''
            }`,
          })
        }
      >
        Additional Details
      </span>
    </div>
  );
}
