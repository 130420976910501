import { IconProps } from '../types';

export default function CloudSyncIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M5.59429 8.89474C5.97302 6.79585 7.82149 5.20842 10.0367 5.20842C11.3513 5.20842 12.5363 5.7675 13.361 6.659C13.5954 6.91238 13.58 7.30781 13.3266 7.54221C13.0732 7.77661 12.6778 7.76122 12.4434 7.50783C11.8465 6.86262 10.9902 6.45842 10.0367 6.45842C8.50499 6.45842 7.22511 7.50185 6.87076 8.90451L7.06778 8.71561C7.31694 8.47672 7.71258 8.48504 7.95147 8.7342C8.19036 8.98336 8.18203 9.379 7.93287 9.61789L6.58084 10.9142C6.4581 11.0319 6.2927 11.0945 6.12279 11.0875C5.95288 11.0806 5.79314 11.0047 5.6804 10.8774L4.53243 9.58111C4.30358 9.3227 4.32755 8.92769 4.58596 8.69885C4.84438 8.47 5.23938 8.49397 5.46823 8.75239L5.59429 8.89474Z"
        fill={fill}
      />
      <path
        d="M13.8741 8.91333C14.0439 8.92006 14.2037 8.99567 14.3165 9.12274L15.4676 10.4183C15.6968 10.6764 15.6735 11.0714 15.4154 11.3007C15.1574 11.5299 14.7623 11.5066 14.5331 11.2485L14.4037 11.1029C14.0249 13.2049 12.1679 14.7925 9.94599 14.7925C8.63657 14.7925 7.45485 14.2413 6.62793 13.3602C6.39171 13.1085 6.40426 12.713 6.65595 12.4768C6.90765 12.2406 7.30318 12.2531 7.53939 12.5048C8.13821 13.1429 8.99362 13.5425 9.94599 13.5425C11.4852 13.5425 12.7687 12.4994 13.1256 11.0995L12.9333 11.2842C12.6843 11.5233 12.2887 11.5153 12.0496 11.2663C11.8105 11.0174 11.8184 10.6217 12.0674 10.3826L13.4164 9.08707C13.539 8.96933 13.7042 8.9066 13.8741 8.91333Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95251 1.04175C8.02885 1.04174 6.52132 1.04173 5.34508 1.19987C4.14113 1.36174 3.19111 1.69952 2.44544 2.4452C1.69976 3.19087 1.36198 4.14089 1.20011 5.34484C1.04197 6.52108 1.04198 8.02861 1.04199 9.95227V10.0479C1.04198 11.9716 1.04197 13.4791 1.20011 14.6553C1.36198 15.8593 1.69976 16.8093 2.44544 17.555C3.19111 18.3006 4.14113 18.6384 5.34508 18.8003C6.52132 18.9584 8.02885 18.9584 9.95252 18.9584H10.0481C11.9718 18.9584 13.4793 18.9584 14.6556 18.8003C15.8595 18.6384 16.8095 18.3006 17.5552 17.555C18.3009 16.8093 18.6387 15.8593 18.8005 14.6553C18.9587 13.4791 18.9587 11.9716 18.9587 10.0479V9.95227C18.9587 8.02861 18.9587 6.52108 18.8005 5.34484C18.6387 4.14089 18.3009 3.19087 17.5552 2.4452C16.8095 1.69952 15.8595 1.36174 14.6556 1.19987C13.4793 1.04173 11.9718 1.04174 10.0481 1.04175H9.95251ZM3.32932 3.32908C3.80404 2.85437 4.4463 2.58195 5.51164 2.43872C6.59495 2.29308 8.01847 2.29175 10.0003 2.29175C11.9822 2.29175 13.4057 2.29308 14.489 2.43872C15.5543 2.58195 16.1966 2.85437 16.6713 3.32908C17.146 3.80379 17.4185 4.44606 17.5617 5.5114C17.7073 6.59471 17.7087 8.01823 17.7087 10.0001C17.7087 11.9819 17.7073 13.4055 17.5617 14.4888C17.4185 15.5541 17.146 16.1964 16.6713 16.6711C16.1966 17.1458 15.5543 17.4182 14.489 17.5614C13.4057 17.7071 11.9822 17.7084 10.0003 17.7084C8.01847 17.7084 6.59495 17.7071 5.51164 17.5614C4.4463 17.4182 3.80404 17.1458 3.32932 16.6711C2.85461 16.1964 2.5822 15.5541 2.43897 14.4888C2.29332 13.4055 2.29199 11.9819 2.29199 10.0001C2.29199 8.01823 2.29332 6.59471 2.43897 5.5114C2.5822 4.44606 2.85461 3.80379 3.32932 3.32908Z"
        fill={fill}
      />
    </svg>
  );
}
