import { IconProps } from '../types';

export default function Dashboard(props: IconProps) {
  const { fill = 'currentColor', style, className } = props;

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      style={style}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37332 2.32718C4.62459 2.32716 4.00023 2.32714 3.50458 2.39377C2.98142 2.46411 2.50909 2.61884 2.12953 2.9984C1.74997 3.37795 1.59525 3.85029 1.52491 4.37345C1.45827 4.8691 1.45829 5.49344 1.45831 6.24216V6.32885C1.45829 7.07757 1.45827 7.70194 1.52491 8.19758C1.59525 8.72074 1.74997 9.19308 2.12953 9.57264C2.50909 9.95219 2.98142 10.1069 3.50458 10.1773C4.00023 10.2439 4.62457 10.2439 5.37329 10.2439H5.45998C6.2087 10.2439 6.83307 10.2439 7.32872 10.1773C7.85188 10.1069 8.32421 9.95219 8.70377 9.57264C9.08333 9.19308 9.23805 8.72074 9.30839 8.19758C9.37503 7.70194 9.37501 7.07759 9.37498 6.32887V6.24219C9.37501 5.49346 9.37503 4.8691 9.30839 4.37345C9.23805 3.85029 9.08333 3.37795 8.70377 2.9984C8.32421 2.61884 7.85188 2.46411 7.32872 2.39377C6.83307 2.32714 6.20873 2.32716 5.46001 2.32718H5.37332ZM3.01341 3.88228C3.12201 3.77368 3.28659 3.68433 3.67114 3.63263C4.07367 3.57851 4.61331 3.57718 5.41665 3.57718C6.21999 3.57718 6.75963 3.57851 7.16216 3.63263C7.54671 3.68433 7.71129 3.77368 7.81989 3.88228C7.92848 3.99088 8.01784 4.15546 8.06954 4.54001C8.12366 4.94254 8.12498 5.48217 8.12498 6.28552C8.12498 7.08886 8.12366 7.62849 8.06954 8.03102C8.01784 8.41557 7.92848 8.58015 7.81989 8.68875C7.71129 8.79735 7.54671 8.8867 7.16216 8.9384C6.75963 8.99252 6.21999 8.99385 5.41665 8.99385C4.61331 8.99385 4.07367 8.99252 3.67114 8.9384C3.28659 8.8867 3.12201 8.79735 3.01341 8.68875C2.90482 8.58015 2.81546 8.41557 2.76376 8.03102C2.70964 7.62849 2.70832 7.08886 2.70832 6.28552C2.70832 5.48217 2.70964 4.94254 2.76376 4.54001C2.81546 4.15546 2.90482 3.99088 3.01341 3.88228Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.54 11.4938C13.7913 11.4938 13.1669 11.4938 12.6713 11.5604C12.1481 11.6308 11.6758 11.7855 11.2962 12.1651C10.9166 12.5446 10.7619 13.017 10.6916 13.5401C10.6249 14.0358 10.625 14.6601 10.625 15.4088V15.4955C10.625 16.2442 10.6249 16.8686 10.6916 17.3643C10.7619 17.8874 10.9166 18.3597 11.2962 18.7393C11.6758 19.1189 12.1481 19.2736 12.6713 19.3439C13.1669 19.4106 13.7912 19.4105 14.5399 19.4105H14.6266C15.3753 19.4105 15.9998 19.4106 16.4954 19.3439C17.0185 19.2736 17.4909 19.1189 17.8704 18.7393C18.25 18.3597 18.4047 17.8874 18.4751 17.3643C18.5417 16.8686 18.5417 16.2443 18.5417 15.4956V15.4089C18.5417 14.6602 18.5417 14.0358 18.4751 13.5401C18.4047 13.017 18.25 12.5446 17.8704 12.1651C17.4909 11.7855 17.0185 11.6308 16.4954 11.5604C15.9997 11.4938 15.3754 11.4938 14.6266 11.4938H14.54ZM12.1801 13.0489C12.2887 12.9403 12.4533 12.851 12.8378 12.7993C13.2403 12.7452 13.78 12.7438 14.5833 12.7438C15.3867 12.7438 15.9263 12.7452 16.3288 12.7993C16.7134 12.851 16.878 12.9403 16.9866 13.0489C17.0952 13.1575 17.1845 13.3221 17.2362 13.7067C17.2903 14.1092 17.2917 14.6488 17.2917 15.4522C17.2917 16.2555 17.2903 16.7952 17.2362 17.1977C17.1845 17.5822 17.0952 17.7468 16.9866 17.8554C16.878 17.964 16.7134 18.0534 16.3288 18.1051C15.9263 18.1592 15.3867 18.1605 14.5833 18.1605C13.78 18.1605 13.2403 18.1592 12.8378 18.1051C12.4533 18.0534 12.2887 17.964 12.1801 17.8554C12.0715 17.7468 11.9821 17.5822 11.9304 17.1977C11.8763 16.7952 11.875 16.2555 11.875 15.4522C11.875 14.6488 11.8763 14.1092 11.9304 13.7067C11.9821 13.3221 12.0715 13.1575 12.1801 13.0489Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37332 11.4938H5.45998C6.20871 11.4938 6.83306 11.4938 7.32872 11.5604C7.85188 11.6308 8.32421 11.7855 8.70377 12.1651C9.08333 12.5446 9.23805 13.017 9.30839 13.5401C9.37503 14.0358 9.37501 14.6601 9.37498 15.4088V15.4955C9.37501 16.2442 9.37503 16.8686 9.30839 17.3643C9.23805 17.8874 9.08333 18.3597 8.70377 18.7393C8.32421 19.1189 7.85188 19.2736 7.32872 19.3439C6.83308 19.4106 6.20874 19.4105 5.46003 19.4105H5.37332C4.62461 19.4105 4.00022 19.4106 3.50458 19.3439C2.98142 19.2736 2.50909 19.1189 2.12953 18.7393C1.74997 18.3597 1.59525 17.8874 1.52491 17.3643C1.45827 16.8686 1.45829 16.2443 1.45831 15.4955V15.4088C1.45829 14.6601 1.45827 14.0358 1.52491 13.5401C1.59525 13.017 1.74997 12.5446 2.12953 12.1651C2.50909 11.7855 2.98142 11.6308 3.50458 11.5604C4.00023 11.4938 4.62458 11.4938 5.37332 11.4938ZM3.67114 12.7993C3.28659 12.851 3.12201 12.9403 3.01341 13.0489C2.90482 13.1575 2.81546 13.3221 2.76376 13.7067C2.70964 14.1092 2.70832 14.6488 2.70832 15.4522C2.70832 16.2555 2.70964 16.7952 2.76376 17.1977C2.81546 17.5822 2.90482 17.7468 3.01341 17.8554C3.12201 17.964 3.28659 18.0534 3.67114 18.1051C4.07367 18.1592 4.61331 18.1605 5.41665 18.1605C6.21999 18.1605 6.75963 18.1592 7.16216 18.1051C7.54671 18.0534 7.71129 17.964 7.81989 17.8554C7.92848 17.7468 8.01784 17.5822 8.06954 17.1977C8.12366 16.7952 8.12498 16.2555 8.12498 15.4522C8.12498 14.6488 8.12366 14.1092 8.06954 13.7067C8.01784 13.3221 7.92848 13.1575 7.81989 13.0489C7.71129 12.9403 7.54671 12.851 7.16216 12.7993C6.75963 12.7452 6.21999 12.7438 5.41665 12.7438C4.61331 12.7438 4.07367 12.7452 3.67114 12.7993Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.54 2.32718C13.7913 2.32716 13.1669 2.32714 12.6713 2.39377C12.1481 2.46411 11.6758 2.61884 11.2962 2.9984C10.9166 3.37795 10.7619 3.85029 10.6916 4.37345C10.6249 4.8691 10.625 5.49345 10.625 6.24218V6.32885C10.625 7.07758 10.6249 7.70193 10.6916 8.19758C10.7619 8.72074 10.9166 9.19308 11.2962 9.57264C11.6758 9.95219 12.1481 10.1069 12.6713 10.1773C13.1669 10.2439 13.7912 10.2439 14.54 10.2439H14.6266C15.3754 10.2439 15.9997 10.2439 16.4954 10.1773C17.0185 10.1069 17.4909 9.95219 17.8704 9.57264C18.25 9.19308 18.4047 8.72074 18.4751 8.19758C18.5417 7.70194 18.5417 7.07759 18.5417 6.32887V6.24219C18.5417 5.49346 18.5417 4.8691 18.4751 4.37345C18.4047 3.85029 18.25 3.37795 17.8704 2.9984C17.4909 2.61884 17.0185 2.46411 16.4954 2.39377C15.9997 2.32714 15.3754 2.32716 14.6267 2.32718H14.54ZM12.1801 3.88228C12.2887 3.77368 12.4533 3.68433 12.8378 3.63263C13.2403 3.57851 13.78 3.57718 14.5833 3.57718C15.3867 3.57718 15.9263 3.57851 16.3288 3.63263C16.7134 3.68433 16.878 3.77368 16.9866 3.88228C17.0952 3.99088 17.1845 4.15546 17.2362 4.54001C17.2903 4.94254 17.2917 5.48217 17.2917 6.28552C17.2917 7.08886 17.2903 7.62849 17.2362 8.03102C17.1845 8.41557 17.0952 8.58015 16.9866 8.68875C16.878 8.79735 16.7134 8.8867 16.3288 8.9384C15.9263 8.99252 15.3867 8.99385 14.5833 8.99385C13.78 8.99385 13.2403 8.99252 12.8378 8.9384C12.4533 8.8867 12.2887 8.79735 12.1801 8.68875C12.0715 8.58015 11.9821 8.41557 11.9304 8.03102C11.8763 7.62849 11.875 7.08886 11.875 6.28552C11.875 5.48217 11.8763 4.94254 11.9304 4.54001C11.9821 4.15546 12.0715 3.99088 12.1801 3.88228Z"
        fill={fill}
      />
    </svg>
  );
}
