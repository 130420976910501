import React from 'react';
import styled from 'styled-components';

interface CustomRadioInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  className?: string;
}

const CustomRadioInput = ({ className, ...props }: CustomRadioInputProps) => {
  return (
    <RadioContainer className={className}>
      <HiddenRadio type="radio" {...props} />
      <StyledRadio />
    </RadioContainer>
  );
};

export default CustomRadioInput;

export const RadioContainer = styled.label`
  cursor: pointer;
  display: inline-block;
`;

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  display: none;

  &:checked + div {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60%; // Adjust size to your preference
      height: 60%; // Adjust size to your preference
      background-color: var(--blue-500); // Inner circle color
      border-radius: 50%;
    }
    background-color: white; // Reset to default
    border: 2px solid var(--blue-500); // Outer ring color when selected
  }
`;

export const StyledRadio = styled.div`
  width: 20px; // Outer ring size
  height: 20px; // Outer ring size
  background: white;
  border-radius: 50%;
  border: 2px solid var(--blue-300); // Outer ring color by default
  position: relative;
`;
