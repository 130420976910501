import { IconProps } from '../types';

export default function CloseCircleIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M6.68678 6.47978C6.49152 6.28452 6.17494 6.28452 5.97968 6.47978C5.78442 6.67504 5.78442 6.99162 5.97968 7.18689L7.2928 8.50001L5.97969 9.81311C5.78443 10.0084 5.78443 10.325 5.97969 10.5202C6.17495 10.7155 6.49154 10.7155 6.6868 10.5202L7.99991 9.20711L9.313 10.5202C9.50826 10.7155 9.82484 10.7155 10.0201 10.5202C10.2154 10.3249 10.2154 10.0084 10.0201 9.8131L8.70701 8.50001L10.0201 7.1869C10.2154 6.99164 10.2154 6.67506 10.0201 6.47979C9.82486 6.28453 9.50827 6.28453 9.31301 6.47979L7.99991 7.7929L6.68678 6.47978Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 1.33334C4.04188 1.33334 0.833252 4.54197 0.833252 8.50001C0.833252 12.4581 4.04188 15.6667 7.99992 15.6667C11.958 15.6667 15.1666 12.4581 15.1666 8.50001C15.1666 4.54197 11.958 1.33334 7.99992 1.33334ZM1.83325 8.50001C1.83325 5.09425 4.59416 2.33334 7.99992 2.33334C11.4057 2.33334 14.1666 5.09425 14.1666 8.50001C14.1666 11.9058 11.4057 14.6667 7.99992 14.6667C4.59416 14.6667 1.83325 11.9058 1.83325 8.50001Z"
        fill={fill}
      />
    </svg>
  );
}
