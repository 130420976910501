/* eslint-disable no-var */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDropdown, ActionDropdownProps } from '@app/components/tables/AntdTableWrapper/components/ActionDropdown';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import CreateAlertButton from '@app/components/apps/recommendations/CreateAlertButton';
import { TInsight } from '@app/types/insight';
import useInsightsForm from '@app/components/apps/manage-insights/useInsightsForm';
import InsightsForm from '@app/components/apps/manage-insights/InsightsForm';
import { useCreateInsight, useDeleteInsight, useUpdateInsight } from '@app/api/insights.api';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { getEnumValue } from '@app/services/enum.service';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';

const manageInsightsTableCols: TDataColumns<TInsight> = [
  {
    title: 'Creation Time',
    dataIndex: 'creationTime',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'InsightStatus',
    showSortDirections: true,
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    allowFiltering: true,
    type: 'number',
    showSortDirections: true,
    width: '10vw',
  },
];

interface IInsightsManagementPageProps {
  title?: string;
}

export default function InsightsManagementPage(props: IInsightsManagementPageProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState<TInsight | undefined>(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { t } = useTranslation();
  const { mutateAsync: createInsight, isLoading: isCreating } = useCreateInsight();
  const { mutateAsync: updateInsight, isLoading: isUpdating } = useUpdateInsight();
  const { mutateAsync: deleteInsight, isLoading: isDeleting } = useDeleteInsight();
  const { toggle, ...insightsFormProps } = useInsightsForm({
    onOk: async (data) => {
      if (isEdit && !!selectedInsight) {
        await updateInsight({
          id: selectedInsight.id,
          data: data,
        });
      } else {
        await createInsight(data);
      }

      setIsEdit(false);
      setSelectedInsight(undefined);
      toggle();
      tableFilterProps.refetch();
    },
    onCancel: () => {
      setIsEdit(false);
      setSelectedInsight(undefined);
    },
    insight: selectedInsight,
  });

  const actionItems: TActionItems<TInsight>[] = [
    // {
    //   label: 'Edit Insight',
    //   key: 'edit',
    //   multiSelect: false,
    //   onClick: (rec) => {
    //     setIsEdit(true);
    //     setSelectedInsight(rec);
    //     toggle();
    //   },
    // },
    {
      label: 'Delete Insight',
      key: 'delete',
      multiSelect: false,
      onClick: (rec) => {
        setSelectedInsight(rec);
        setIsConfirmationModalOpen(true);
      },
    },
  ];

  const tableFilterProps = useTableFiltersV2<TInsight>({
    columns: manageInsightsTableCols,
    model: 'insight',
    searchableColumns: ['name'],
    constantFilter: {
      or: [{ status: getEnumValue('InsightStatus', 'Active') }, { status: getEnumValue('InsightStatus', 'Draft') }],
    },
    orderBy: {
      column: 'creationTime',
      order: 'desc',
    },
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  var dataTableProps = useDataTable<TInsight>({
    data: tableFilterProps.data?.items || [],
    columns: manageInsightsTableCols,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick: (data) => {
      setIsEdit(true);
      setSelectedInsight(data);
      toggle();
    },
  });

  // const dataTableProps = useDataTable<TInsight>({
  //   model: 'insight',
  //   columns: manageInsightsTableCols,
  //   stickyHeader: true,
  //   searchableColumns: ['name'],
  //   constantFilter: '(status eq 1 or status eq 2)',
  //   defaultOrder: {
  //     column: 'creationTime',
  //     order: 'desc',
  //   },
  //   actionItems,
  //   onRowClick: (data, index) => {
  //     setIsEdit(true);
  //     setSelectedInsight(data);
  //     toggle();
  //   },
  // });

  const handleModalClose = () => {
    setSelectedInsight(undefined);
    setIsConfirmationModalOpen(false);
  };

  const handleConfirm = async () => {
    if (!!selectedInsight) {
      await deleteInsight(selectedInsight.id);
      handleModalClose();
      tableFilterProps.refetch();
    }
  };

  return (
    <>
      <PageTitle>Manage Insights</PageTitle>
      <ComponentHeader title={t('common.manageInsights')} />
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <BaseCTAButton onClick={toggle}>Create Insight</BaseCTAButton>
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
        </DataTableV2.Actions>
      </DataTableV2>
      <InsightsForm {...insightsFormProps} isEdit={isEdit} isLoading={isCreating || isUpdating} />
      <ActionConfirmationModal
        open={isConfirmationModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message="Are you sure you want to delete this insight?"
        isLoading={isDeleting}
      />
    </>
  );
}
