import React from 'react';
import styled from 'styled-components';

interface PayloadItem {
  name: string;
  value: number;
  payload: { total: number; color: string };
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: PayloadItem[];
}

const TooltipContainer = styled.div`
  background-color: #fff;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
`;

const TooltipTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TooltipTitle = styled.p`
  margin: 0;
  font-weight: bold;
  color: var(--grey-600);
  font-size: 0.875rem;
`;

const TooltipContent = styled.p`
  margin: 0;
  font-size: 0.875rem;
`;

const Percentage = styled.span`
  font-weight: 500;
  color: var(--grey-950);
  font-size: 0.875rem;
  margin-left: 0.5rem;
`;

const Value = styled.span`
  font-weight: 500;
  color: var(--grey-950);
  font-size: 0.875rem;
  margin-left: 0.5rem;
`;

const Total = styled.span`
  font-weight: 400;
  color: var(--grey-600);
  font-size: 0.625rem;
`;

const LegendCircle = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export function CustomTooltipForNonCompliant({ active, payload }: CustomTooltipProps) {
  if (active && payload && payload.length) {
    const { name, value, payload: extraPayload } = payload[0];
    const percentage = Math.floor((value / extraPayload.total) * 100);
    return (
      <TooltipContainer>
        <TooltipTitleContainer>
          <LegendCircle color={extraPayload.color} />
          <TooltipTitle>{name}</TooltipTitle>
        </TooltipTitleContainer>
        <TooltipContent>
          <Percentage>{percentage}%</Percentage> <Value>{value}</Value>/<Total>{extraPayload.total}</Total>
        </TooltipContent>
      </TooltipContainer>
    );
  }

  return null;
}
