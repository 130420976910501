import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useGetNonCompliantRecommendationByProduct } from '@app/api/statistics.api';
import { RootState } from '@app/store/store';
import { TEnumEntity } from '@app/types/enumEntity';
import { useSelector } from 'react-redux';
import { Pie, PieChart, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import { usePagination } from '../../tables/AntdTableWrapper/hooks/usePagination';
import DashboardPagination from '../DashboardPagination';
import { Spin } from 'antd';
import { DashboardCenterContainer } from '../../common/BaseLayout/BaseLayout.styled';
import { NoData } from '../../common/NoData';
import { CustomTooltipForNonCompliant } from './Tooltip';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 200px;
`;

const ChartContainer = styled.div`
  position: relative;
  flex: 1;
  max-width: 40%;
  height: 100%;
`;

const LegendContainer = styled.div`
  flex: 1;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: flex-start;
`;

const LegendItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:hover span.legend-name {
    text-decoration: underline;
  }
`;

const LegendCircle = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const COLORS = ['#5375E6', '#B9AFF9', '#48D1A0', '#7B49E0', '#A1EA7B'];

const limit = 5;

export function NonCompliantByProduct() {
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredLegend, setHoveredLegend] = useState<{
    name: string;
    value: number;
    total: number;
    color: string;
  } | null>(null);
  const [randomTop, setRandomTop] = useState<number>(0);
  const [randomLeft, setRandomLeft] = useState<number>(0);
  const selectedProducts = useSelector<RootState>((state) => state.app.accountProducts) as TEnumEntity[] | null;

  const productIds = useMemo(() => {
    if (selectedProducts) {
      return selectedProducts.map((product) => product.id);
    }
    return [];
  }, [selectedProducts]);

  const totalRows = productIds.length;

  const paginatedProductIds = useMemo(() => {
    const start = (currentPage - 1) * limit;
    const end = start + limit;
    return productIds.slice(start, end);
  }, [currentPage, productIds]);

  const {
    data: NonCompliantRecommendationByProduct,
    isLoading,
    refetch,
    isRefetching,
  } = useGetNonCompliantRecommendationByProduct(paginatedProductIds.join(','));

  const paginationProps = usePagination({
    limit,
    skip: (currentPage - 1) * limit,
    totalRows,
    limitIntervals: [5],
    onNext() {
      if (currentPage * limit < totalRows) {
        setCurrentPage((prev) => prev + 1);
      }
    },
    onPrev() {
      if (currentPage > 1) {
        setCurrentPage((prev) => prev - 1);
      }
    },
    onPageClicked(callbackProps) {
      setCurrentPage(callbackProps.currentPage);
    },
  });

  const pieData = useMemo(() => {
    if (NonCompliantRecommendationByProduct?.byProduct) {
      return NonCompliantRecommendationByProduct.byProduct.map((product, index) => ({
        name: product.name,
        value: product.nonCompliant,
        total: product.total,
        color: COLORS[index % COLORS.length],
      }));
    }
    return [];
  }, [NonCompliantRecommendationByProduct]);

  useEffect(() => {
    refetch();
  }, [paginatedProductIds, refetch]);

  return (
    <>
      <Container>
        <ChartContainer>
          {isLoading || isRefetching ? (
            <DashboardCenterContainer>
              <Spin />
            </DashboardCenterContainer>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieData.length > 0 ? pieData : [{ name: 'No Data', value: 100, color: 'var(--grey-600)' }]}
                  dataKey="value"
                  nameKey="name"
                  outerRadius={90}
                  innerRadius={70}
                  blendStroke={true}
                  cornerRadius={20}
                  paddingAngle={-12}
                  isAnimationActive={false}
                >
                  {(pieData.length > 0 ? pieData : [{ name: 'No Data', value: 100, color: 'var(--grey-600)' }]).map(
                    (entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ),
                  )}
                </Pie>
                <text
                  x="50%"
                  y="40%"
                  textAnchor="middle"
                  dominantBaseline="central"
                  fontSize="24px"
                  fontWeight="bold"
                  color="var(--grey-950)"
                >
                  {pieData.length > 0 ? NonCompliantRecommendationByProduct?.nonCompliantCount ?? 0 : ''}
                </text>
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="central"
                  fontSize="14px"
                  color="var(--grey-950)"
                >
                  {pieData.length > 0 ? 'Non Compliant' : ''}
                </text>
                <text
                  x="50%"
                  y="60%"
                  textAnchor="middle"
                  dominantBaseline="central"
                  fontSize="14px"
                  color="var(--grey-950)"
                >
                  {pieData.length > 0 ? 'Recommendations' : ''}
                </text>
                {pieData.length > 0 ? <Tooltip content={<CustomTooltipForNonCompliant />} /> : ''}
              </PieChart>
            </ResponsiveContainer>
          )}
          {hoveredLegend && (
            <div style={{ position: 'absolute', left: `${randomLeft}%`, top: `${randomTop}%`, textWrap: 'nowrap' }}>
              <CustomTooltipForNonCompliant
                active
                payload={[
                  {
                    name: hoveredLegend.name,
                    value: hoveredLegend.value,
                    payload: { total: hoveredLegend.total, color: hoveredLegend.color },
                  },
                ]}
              />
            </div>
          )}
        </ChartContainer>
        {!isRefetching && (
          <LegendContainer>
            {pieData.length === 0 && !isLoading ? (
              <NoData />
            ) : (
              pieData.map((entry, index) => (
                <Link
                  to={`/recommendations?$filter=(contains(tolower(productName),'${encodeURIComponent(
                    entry.name.toLowerCase(),
                  )}'))%20and%20(isCompliant%20eq%20false)`}
                  key={entry.name}
                  style={{ display: 'inline-block', textDecoration: 'none', width: '100%' }}
                >
                  <LegendItem
                    onMouseEnter={() => {
                      setHoveredLegend(entry);
                      setRandomTop(Math.random() * 20 + 10);
                      setRandomLeft(Math.random() * 10 + 10);
                    }}
                    onMouseLeave={() => setHoveredLegend(null)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <LegendCircle color={COLORS[index % COLORS.length]} />
                      <span className="legend-name" style={{ fontSize: '0.85rem', color: 'var(--grey-950)' }}>
                        {entry.name}
                      </span>
                    </div>
                    <span style={{ fontSize: '0.85rem', color: 'var(--grey-950)' }}>{entry.value}</span>
                  </LegendItem>
                </Link>
              ))
            )}
          </LegendContainer>
        )}
      </Container>
      <DashboardPagination {...paginationProps} currentPage={currentPage} />
    </>
  );
}
