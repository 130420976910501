import styled from 'styled-components';

export { Typography as BaseTypography } from 'antd';
export type { TypographyProps as BaseTypographyProps } from 'antd';

export const BasePopoverContent = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--grey-600);
`;
