import { httpApi } from '@app/api/http.api';
import { TList } from '@app/types/generalTypes';
import { TDataColumns } from './types';
import { useQuery } from 'react-query';
import { updateAppValues } from '@app/api/table.api';

interface IGetModelItemsProps {
  model: string;
  queryParams?: string;
}

interface IGetTableModelItemsProps<T> {
  model: string;
  queryParams?: string;
  columns: TDataColumns<T>;
  customQueryKey?: string;
  trigger?: boolean;
  filterValuesMap?: Map<string, string>;
  customQueryFn?: (queryString: string, filterValuesMap?: Map<string, string>) => Promise<TList<T>>;
}

export const getModelItems = async <T>({ model, queryParams }: IGetModelItemsProps): Promise<TList<T>> => {
  const url = `/odata/${model}${queryParams || ''}`;

  const response = await httpApi.get(url);
  return response?.data;
};

export const useGetTableModelItems = <T>({
  model,
  columns,
  queryParams,
  customQueryKey,
  trigger,
  filterValuesMap,
  customQueryFn,
}: IGetTableModelItemsProps<T>) => {
  return useQuery({
    queryKey: [customQueryKey || `${model}-odata`, queryParams || ''],
    queryFn: !!customQueryFn
      ? () => customQueryFn(queryParams || '', filterValuesMap)
      : () => getModelItems<T>({ model, queryParams }),
    enabled: trigger || false,
    select(data) {
      let items: TList<T>['items'] = [];
      items = data.items?.map((item, index) => {
        const ret = updateAppValues(item, columns);
        return {
          ...ret,
          key: index,
        };
      });

      return {
        count: data.count,
        items,
      };
    },
  });
};
