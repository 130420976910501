import { HorizontalAlignedContainer, VerticalContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TRecommendationEventType } from '@app/types/recommendationHistory';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { COMPACT_TABS_COLORS, FONT_WEIGHT, RECOMMENDATION_DETAILS_MODAL_COLORS } from '@app/styles/themes/constants';
import styled from 'styled-components';

const CommentBlock = styled.div`
  padding: 8px;
  background-color: ${COMPACT_TABS_COLORS.lightGray};
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
  border-radius: 6px;
  font-size: 14px;
`;

const UserText = styled.span`
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.blueLink};
`;

const Badge = styled.div`
  font-weight: 400;
  font-size: 12px;
  background-color: #fff7ed;
  color: #7e2f10;

  width: fit-content;
  height: fit-content;
  padding: 2px 8px;
  border-radius: 6px;
`;

interface IAssignmentChangesProps {
  event: TRecommendationEventType;
  title?: string;
  showTimeOnly?: boolean;
}

const AssignementChanges = ({ event, title, showTimeOnly }: IAssignmentChangesProps) => {
  const { change_type, user, comment, date, assigned_to } = event;

  const assignmentText = (
    <>
      {title ? title : 'Recommendation'}{' '}
      {assigned_to ? (
        <>
          was assigned to <UserText>{assigned_to}</UserText>
        </>
      ) : (
        'was unassigned'
      )}
    </>
  );

  const assignmentMessage = (
    <>
      <UserText>{user}</UserText>{' '}
      {assigned_to ? (
        <>
          assigned this recommendation to <UserText>{assigned_to}</UserText>
        </>
      ) : (
        'unassigned this recommendation'
      )}
      {!comment || comment?.length === 0 ? '.' : ' with notes:'}
    </>
  );

  return (
    <VerticalContainer style={{ gap: '4px', marginBottom: '12px' }}>
      <HorizontalAlignedContainer>
        <span style={{ fontSize: '14px', fontWeight: '500', color: `${COMPACT_TABS_COLORS.darkGray}` }}>
          {assignmentText}
        </span>
      </HorizontalAlignedContainer>
      <span style={{ fontSize: '14px', fontWeight: `${FONT_WEIGHT.regular}`, color: 'var(--grey-700)' }}>
        {assignmentMessage}
      </span>
      <Badge>{assigned_to ? 'Assigned' : 'Unassigned'}</Badge>
      {!!comment && comment?.length !== 0 && (
        <CommentBlock>
          <HtmlEditor initialValue={comment} readOnly />
        </CommentBlock>
      )}
    </VerticalContainer>
  );
};

export { AssignementChanges };
