import { useNavigate, useSearchParams } from 'react-router-dom';

import { useBaseSearchInput } from '@app/components/common/BaseSearch/SearchInput/useBaseSearchInput';
import { TList } from '@app/types/generalTypes';
import { constructFilterStateFromUrl } from '@app/utils/utils';
import { format } from 'date-fns';
import buildQuery, { Filter } from 'odata-query';
import { useEffect, useState } from 'react';
import { useGetTableModelItems } from '../api';
import { ITableFiltersProps } from '../components/TableFilters';
import {
  DEFAULT_LIMIT,
  DEFAULT_SKIP,
  FILTER_QUERY_PARAM_KEY,
  ORDER_BY_QUERY_PARAM_KEY,
  SELECT_QUERY_PARAM_KEY,
  SKIP_QUERY_PARAM_KEY,
  TOP_QUERY_PARAM_KEY,
} from '../constants';
import { TBuildFilterProps, TOrderByProps, TPaginationProps, TTextOptionsFilter } from '../types';

/**
 * This hook will be responsible for the majority, if not all, of the changes in the odata query in the URL.
 * This hook will also be responsible for supplying the data that a component needs (e.g. DataTable, etc...).
 *
 * Note: There are 3 kinds of filter namely:
 *    - Query filters: This is the filters we see in the URL that are applied in the odata query.
 *    - Constant filters: This is same as the query filters but is not visible in the URL thus, this can't be changed by the user.
 *    - Search filters: This is same as the constant filters but the user can change this through the search input in the filter section.
 * When building filters, please use the buildQuery function in the odata-query package. Docs are on this link: https://www.npmjs.com/package/odata-query
 *
 *
 * TODO:
 * 1. Change the url whenever this hook is initialized to have an odata query that has select, top, skip, and if possible, filter.
 *    - If there are no values for the specific odata queries, create an odata query builder for those specific odata queries with the
 *      default values specified in the constants file. After creating, append the odata query to the URL and navigate to it with the
 *      replace value set to true (to prevent the issue of back button in the browser not navigating to the previous page).
 *        - $select = To initialize this, check forst of there is no value in the $select search params. If there's none, reference all the columns
 *                    in the props and filter them according to the hideColumn property (!hideColumn). Map the filtered results to the dataIndexes.
 *        - $top = To initialize this, check first if there is no value in the $top search params. If there's none, then initialize this by the DEFAULT_LIMIT
 *                 specified in the constants folder.
 *        - $skip = same as $top initialization steps.
 */

interface IUseTableFilters<T extends Record<string, unknown>>
  extends Omit<ITableFiltersProps<T>, 'searchInputProps' | 'canSearch' | 'children'> {
  model: string;
  customQueryKey?: string;
  bookmarkFeature?: boolean;
  orderBy?: TOrderByProps | TOrderByProps[];
  constantFilter?: Filter<T>;
  searchableColumns?: ITableFiltersProps<T>['columns'][number]['dataIndex'][]; // TODO: improve typescript so that the values present in the dataIndex will be its type;
  disableInitialOdataQueryBuild?: boolean;
  defaultPaginationParams?: TPaginationProps;
  removePagination?: boolean;
  onApplyFilterCallback?: () => void;
  onClearFilterCallback?: () => void;
  customQueryFn?: (queryString: string, filterValuesMap?: Map<string, string>) => Promise<TList<T>>;
}

export function useTableFiltersV2<T extends Record<string, unknown>>({
  model,
  columns,
  customQueryKey,
  orderBy,
  constantFilter,
  defaultPaginationParams,
  searchableColumns = [],
  bookmarkFeature = true,
  canFilter,
  disableInitialOdataQueryBuild = false,
  removePagination = false,
  customQueryFn,
  onApplyFilterCallback,
  onClearFilterCallback,
}: IUseTableFilters<T>) {
  const [odataQueryString, setOdataQueryString] = useState<string | undefined>(undefined);
  const [paginationParams, setPaginationParams] = useState<TPaginationProps | undefined>(defaultPaginationParams);

  const filterValuesMap = new Map<string, string>(
    constructFilterStateFromUrl(new URLSearchParams(window.location.search), columns)?.map((pqf) => [
      pqf.column,
      pqf.value,
    ]) || [],
  );

  const appliedFiltersMap = new Map<string, string>(
    constructFilterStateFromUrl(new URLSearchParams(window.location.search), columns)?.map((pqf) => [
      pqf.column,
      pqf.value,
    ]) || [],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const canSearch = searchableColumns.length > 0 ? true : false;
  const hasFilterQuery = !!searchParams.get(FILTER_QUERY_PARAM_KEY);

  const searchInputProps = useBaseSearchInput({
    placeholder: 'Search',
    debouncedFunc(value) {
      buildOdataQueryFromParams({ searchQueryString: value });
    },
  });

  const { data, isFetching, refetch } = useGetTableModelItems({
    model,
    queryParams: odataQueryString,
    customQueryKey,
    columns,
    trigger: !!odataQueryString, // on page mount, prevent fetching the items if the odata query is not formed.
    filterValuesMap,
    customQueryFn,
  });

  useEffect(() => {
    /**
     * All functions that are required to run in the beginning of page render should be
     * found here.
     */
    if (!disableInitialOdataQueryBuild) {
      initializeOdataQuery();
    }
  }, []);

  function initializeOdataQuery() {
    /**
     * Note: only initialize a query param if there are no values in it in the URL.
     */
    const { odataQuery, completeOdataQuery } = buildOdataQueryFromUrl();

    if (!!bookmarkFeature) {
      navigate(odataQuery, { replace: true });
    }

    setOdataQueryString(completeOdataQuery);
  }

  function buildOdataQueryFromUrl() {
    const selectQuery = !!searchParams.get(SELECT_QUERY_PARAM_KEY)
      ? searchParams.get(SELECT_QUERY_PARAM_KEY)?.split(',')
      : columns.filter((col) => !col.hideColumn).map((col) => col.dataIndex as string);

    const top = !!searchParams.get(TOP_QUERY_PARAM_KEY)
      ? parseInt(searchParams.get(TOP_QUERY_PARAM_KEY)!)
      : !!paginationParams
      ? paginationParams.top
      : DEFAULT_LIMIT;

    const skip = !!searchParams.get(SKIP_QUERY_PARAM_KEY)
      ? parseInt(searchParams.get(SKIP_QUERY_PARAM_KEY)!)
      : !!paginationParams
      ? paginationParams.skip
      : DEFAULT_SKIP;

    let orderbyQuery;
    if (bookmarkFeature) {
      orderbyQuery = !!searchParams.get(ORDER_BY_QUERY_PARAM_KEY)
        ? [searchParams.get(ORDER_BY_QUERY_PARAM_KEY)!]
        : !!orderBy
        ? Array.isArray(orderBy)
          ? orderBy.map((order) => `${order.column} ${order.order}`)
          : [`${orderBy.column} ${orderBy.order}`]
        : undefined;
    } else {
      orderbyQuery = !!orderBy
        ? Array.isArray(orderBy)
          ? orderBy.map((order) => `${order.column} ${order.order}`)
          : [`${orderBy.column} ${orderBy.order}`]
        : !!searchParams.get(ORDER_BY_QUERY_PARAM_KEY)
        ? [searchParams.get(ORDER_BY_QUERY_PARAM_KEY)!]
        : undefined;
    }

    const { completeFilters, visibleFilters } = buildFilters();

    const completeOdataQuery = buildQuery({
      top: removePagination ? undefined : top,
      skip: removePagination ? undefined : skip,
      orderBy: orderbyQuery,
      filter: completeFilters,
    });

    const odataQuery = buildQuery({
      select: selectQuery,
      top: removePagination ? undefined : top,
      skip: removePagination ? undefined : skip,
      orderBy: orderbyQuery,
      filter: visibleFilters,
    });

    return { odataQuery, completeOdataQuery };
  }

  function buildOdataQueryFromParams({
    selectFields,
    paginationProps,
    orderyByProps,
    searchQueryString,
    currentConstantFilter, // there are times that when the constant filter is changed, its not applying the current value supplied in the hook. If that happens, supply a value to this property.
    onQueryBuildCallback,
  }: TBuildFilterProps<T>) {
    const selectQuery = selectFields
      ? selectFields
      : !!searchParams.get(SELECT_QUERY_PARAM_KEY)
      ? searchParams.get(SELECT_QUERY_PARAM_KEY)?.split(',')
      : columns.filter((col) => !col.hideColumn).map((col) => col.dataIndex as string);

    const top = !!paginationProps
      ? paginationProps.top
      : !!searchParams.get(TOP_QUERY_PARAM_KEY)
      ? parseInt(searchParams.get(TOP_QUERY_PARAM_KEY)!)
      : DEFAULT_LIMIT;

    const skip = !!paginationProps
      ? paginationProps.skip
      : !!searchParams.get(SKIP_QUERY_PARAM_KEY)
      ? parseInt(searchParams.get(SKIP_QUERY_PARAM_KEY)!)
      : DEFAULT_SKIP;

    const orderbyQuery = !!orderyByProps
      ? Array.isArray(orderyByProps)
        ? orderyByProps.map((order) => `${order.column} ${order.order}`)
        : [`${orderyByProps.column} ${orderyByProps.order}`]
      : !!searchParams.get(ORDER_BY_QUERY_PARAM_KEY)
      ? [searchParams.get(ORDER_BY_QUERY_PARAM_KEY)!]
      : !!orderBy
      ? Array.isArray(orderBy)
        ? orderBy.map((order) => `${order.column} ${order.order}`)
        : [`${orderBy.column} ${orderBy.order}`]
      : undefined;

    const { completeFilters, visibleFilters } = buildFilters(searchQueryString, currentConstantFilter);

    const completeOdataQuery = buildQuery({
      top: removePagination ? undefined : top,
      skip: removePagination ? undefined : skip,
      orderBy: orderbyQuery,
      filter: completeFilters,
    });

    const odataQuery = buildQuery({
      select: selectQuery,
      top: removePagination ? undefined : top,
      skip: removePagination ? undefined : skip,
      orderBy: orderbyQuery,
      filter: visibleFilters,
    });

    if (!!bookmarkFeature) {
      navigate(odataQuery, { replace: true });
    }

    setPaginationParams({ top, skip });
    setOdataQueryString(completeOdataQuery);

    onQueryBuildCallback?.(completeOdataQuery);
  }

  function buildFilters(searchQueryString?: string, currentConstantFilter?: Filter<T>) {
    const visibleFilters: Filter<T> = []; // filters that are visible in the URL. Mostly used for the bookmark feature.
    const completeFilters: Filter<T> = []; // same as visible filters but with the constantFilter and searchedFilter removed.

    const constantFilterPlaceholder = !!currentConstantFilter ? currentConstantFilter : constantFilter;

    if (!!constantFilterPlaceholder) {
      if (Array.isArray(constantFilterPlaceholder)) {
        constantFilterPlaceholder.forEach((filter) => completeFilters.push(filter));
      } else {
        completeFilters.push(constantFilterPlaceholder);
      }
    }

    if (!!searchQueryString && searchableColumns.length > 0) {
      const searchQueryFilter = searchableColumns.reduce((filterArray, currentValue) => {
        return [
          ...filterArray,
          { [`tolower(${currentValue as string})`]: { contains: searchQueryString.toLowerCase() } },
        ];
      }, [] as Record<string, any>[]);

      completeFilters.push({ or: searchQueryFilter });
    }

    filterValuesMap.forEach((value, key) => {
      const column = columns.find((col) => col.dataIndex === key);
      const columnType = column?.type;

      switch (columnType) {
        case 'text':
          if (!!value) {
            const textFilter = { [`tolower(${key})`]: { contains: value.toLowerCase() } };
            visibleFilters.push(textFilter);
            completeFilters.push(textFilter);
          }
          return;
        case 'number':
          // value for number is structured as "operator numberValue" (e.g. eq 10)
          const operator = value.split(' ')[0];
          const numberValue = value.split(' ')[1]?.replaceAll("'", '');
          const numberFilter = { [key]: { [operator]: numberValue } };
          visibleFilters.push(numberFilter);
          completeFilters.push(numberFilter);
          return;
        case 'boolean':
          const booleanFilter = { [key]: { eq: value === 'true' ? true : value === 'false' ? false : value } };
          visibleFilters.push(booleanFilter);
          completeFilters.push(booleanFilter);
          return;
        case 'enum':
          // value is in a stringified array of numbers;
          const enumValues = JSON.parse(value);
          //   const enumFilter = { [key]: { in: enumValues } }; // TODO: investigate why in operator is not working in odata backend
          const enumFilter = {
            or: enumValues.map((enumVal: any) => ({
              [key]: {
                eq:
                  typeof enumVal === 'number' || (column as any).enumValuesKey === 'BooleanEnum'
                    ? enumVal === 'null'
                      ? null
                      : typeof enumVal === 'string'
                      ? enumVal.replaceAll("'", '')
                      : enumVal
                    : parseInt(enumVal),
              },
              // somehow, when going to the next pages, the filtermap value for enum is turning into a string instead of a number. So I have to check the type and parse it if its not a number
              // also, there's the case of booleans that have N/A as an option (BooleanEnum). We only need to parseint if its not a BooleanEnum
            })),
          };
          visibleFilters.push(enumFilter);
          completeFilters.push(enumFilter);
          return;
        case 'enumArray':
          const enumArrayValues = JSON.parse(value);
          const enumArrayFilter = {
            or: enumArrayValues.map((enumVal: any) => ({
              [key]: {
                eq: `-${typeof enumVal === 'string' ? enumVal.replaceAll("'", '') : enumVal}-`,
              },
              // somehow, when going to the next pages, the filtermap value for enum is turning into a string instead of a number. So I have to check the type and parse it if its not a number
              // also, there's the case of booleans that have N/A as an option (BooleanEnum). We only need to parseint if its not a BooleanEnum
            })),
          };
          visibleFilters.push(enumArrayFilter);
          completeFilters.push(enumArrayFilter);
          return;
        case 'textOptions':
          // value must be an array of string for columns that has textOptions as type and is multiselect
          const textOptionsFilterValue = JSON.parse(value) as string[];

          if (!(column as TTextOptionsFilter).multipleSelect) {
            const textFilter = {
              [`tolower(${key})`]: { contains: textOptionsFilterValue[0]?.replaceAll("'", '').toLowerCase() },
            };
            visibleFilters.push(textFilter);
            completeFilters.push(textFilter);
            return;
          }

          const filterArray = textOptionsFilterValue.map((val) => ({
            [`tolower(${key})`]: { contains: val.replaceAll("'", '').toLowerCase() },
          }));
          const textOptionsFilter = { or: filterArray };

          visibleFilters.push(textOptionsFilter);
          completeFilters.push(textOptionsFilter);
          return;
        case 'date':
          const dates = JSON.parse(value); // results in an array containing the first date and second date respectively.
          const dateFilter = { [key]: { ge: format(dates[0], 'yyyy-MM-dd'), le: format(dates[1], 'yyyy-MM-dd') } };
          visibleFilters.push(dateFilter);
          completeFilters.push(dateFilter);
          return;
      }
    });

    /**
     * Note: always place any string related function filters like contains in the start of the array.
     */

    completeFilters.sort((a: Filter<T>, b: Filter<T>) => {
      const filterA = JSON.stringify(a);

      if (filterA.indexOf('contains') > 0) return -1;

      return 1;
    });

    visibleFilters.sort((a: Filter<T>, b: Filter<T>) => {
      const filterA = JSON.stringify(a);

      if (filterA.indexOf('contains') > 0) return -1;

      return 1;
    });

    return {
      visibleFilters,
      completeFilters,
    };
  }

  function clearFilters() {
    filterValuesMap.clear();
    searchInputProps.onChange('');
    buildOdataQueryFromParams({});
    onClearFilterCallback?.();
  }

  function removeFilter(key: string) {
    filterValuesMap?.delete(key);
    buildOdataQueryFromParams?.({});
  }

  function resetFilterValuesMap() {
    filterValuesMap.clear();
    appliedFiltersMap.forEach((val, key) => {
      filterValuesMap.set(key, val);
    });
  }

  return {
    columns,
    searchInputProps,
    data,
    isFetching,
    odataQueryString,
    paginationParams,
    filterValuesMap,
    canSearch,
    canFilter,
    hasFilterQuery,
    removeFilter,
    refetch,
    buildOdataQueryFromParams,
    clearFilters,
    onApplyFilterCallback,
    resetFilterValuesMap,
  };
}
