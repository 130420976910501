import { getAccountProducts } from '@app/api/account.api';
import { getEnumValues, getRolesAndScreens } from '@app/api/app.api';
import { getUserScreenPermissions } from '@app/api/auth.api';
import { getAllLicensePlans, getAllLicenses } from '@app/api/license.api';
import { getAllProducts } from '@app/api/product.api';
import { getAllSubscriptionTypes } from '@app/api/subscriptionType.api';
import { TEnumEntity } from '@app/types/enumEntity';
import { TGroupPermission } from '@app/types/group';
import { TRoleAndScreen } from '@app/types/roleAndScreen';
import { TSubscriptionType } from '@app/types/subscriptionType';
import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface IApplicationState {
  appValues?: Record<string, unknown> | null;
  rolesAndScreens?: TRoleAndScreen[] | null;
  products?: TEnumEntity[] | null;
  accountProducts?: TEnumEntity[] | null;
  licenses?: TEnumEntity[] | null;
  licensePlans?: TEnumEntity[] | null;
  allowedScreensAndActions: { screens: string[]; actions: TGroupPermission[] } | null;
  finishedLoadingValues: boolean;
  subscriptionTypes: TSubscriptionType[] | null;
  pageTitle?: string;
}

const initialState: IApplicationState = {
  appValues: null,
  rolesAndScreens: null,
  products: null,
  accountProducts: null,
  licenses: null,
  licensePlans: null,
  allowedScreensAndActions: null,
  finishedLoadingValues: false,
  subscriptionTypes: null,
  pageTitle: undefined,
};

export const loadAppValues = createAsyncThunk('app/loadAppValues', async () => {
  try {
    const responseValues = await Promise.all([
      getEnumValues(),
      getAllProducts(),

      getAllLicenses(),
      getRolesAndScreens(),
      getUserScreenPermissions(),
      getAllLicensePlans(),
      getAllSubscriptionTypes(),
      getAccountProducts(),
    ]);

    let enumValues: Record<string, unknown> = {};
    if (responseValues[0]) {
      const keys = Object.keys(responseValues[0]);
      for (const key of keys) {
        const valueKeys = Object.keys((responseValues[0] as any)[key]);
        const values = [];

        for (const valKey of valueKeys) {
          // allow Deleted enum in user status
          if (key === 'UserStatus') {
            values.push({
              label: valKey,
              value: (responseValues[0] as any)[key][valKey],
            });
          } else {
            if (valKey !== 'Deleted') {
              values.push({
                label: valKey,
                value: (responseValues[0] as any)[key][valKey],
              });
            }
          }
        }
        enumValues = {
          ...enumValues,
          [key]: values,
        };
      }
      enumValues = {
        ...enumValues,
        Products: responseValues[1].map((product) => ({ value: product.name, label: product.displayName })),
        AccountProducts: responseValues[7].map((product) => ({ value: product.name, label: product.displayName })),
        Licenses: responseValues[2].map((license) => ({ value: license.name, label: license.displayName })),
        LicensePlans: responseValues[5].map((license) => ({ value: license.displayName, label: license.displayName })),
        /** this enum is used for multiselect boolean filters */
        BooleanEnum: [
          { label: 'True', value: 'true' },
          { label: 'False', value: 'false' },
          { label: 'N/A', value: 'null' },
        ],
      };
    }

    return {
      appValues: enumValues,
      products: responseValues[1],
      licenses: responseValues[2],
      licensePlans: responseValues[5],
      rolesAndScreens: responseValues[3],
      allowedScreensAndActions: responseValues[4],
      subscriptionTypes: responseValues[6],
      accountProducts: responseValues[7],
    };
  } catch (error) {
    return null;
  }
});

export const refetchSubscriptionTypes = createAsyncThunk('app/refetchSubscriptionTypes', async () => {
  try {
    const response = await getAllSubscriptionTypes();
    return response;
  } catch (error) {
    return null;
  }
});

export const setPageTitle = createAction<PrepareAction<string>>('app/setPageTitle', (title) => ({
  payload: title,
}));

export const appSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadAppValues.fulfilled, (state, action) => {
      state.appValues = action.payload?.appValues;
      state.products = action.payload?.products;
      state.licenses = action.payload?.licenses;
      state.licensePlans = action.payload?.licensePlans;
      state.rolesAndScreens = action.payload?.rolesAndScreens;
      state.allowedScreensAndActions = action.payload?.allowedScreensAndActions;
      state.subscriptionTypes = action.payload?.subscriptionTypes;
      state.finishedLoadingValues = true;
      state.accountProducts = action.payload?.accountProducts;
    });
    builder.addCase(refetchSubscriptionTypes.fulfilled, (state, action) => {
      state.subscriptionTypes = action.payload;
    });
    builder.addCase(setPageTitle, (state, action) => {
      state.pageTitle = action.payload;
    });
  },
});

export default appSlice.reducer;
