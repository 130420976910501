import { IconProps } from '../types';

export default function ChevronDownIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="15" height="9" viewBox="0 0 15 9" fill={fill} xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M7.5 9L15 1.71515L13.2367 0L7.5 5.57576L1.76456 0L0 1.71394L7.5 9Z" fill={fill} />
    </svg>
  );
}

export function ChevronDownIconV2(props: IconProps) {
  const { fill = 'currentColor', style } = props;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.95339 5.67461C3.1331 5.46495 3.44875 5.44067 3.65841 5.62038L7.99968 9.34147L12.341 5.62038C12.5506 5.44067 12.8663 5.46495 13.046 5.67461C13.2257 5.88428 13.2014 6.19993 12.9917 6.37964L8.32508 10.3796C8.13783 10.5401 7.86153 10.5401 7.67429 10.3796L3.00762 6.37964C2.79796 6.19993 2.77368 5.88428 2.95339 5.67461Z"
        fill={fill}
        style={style}
      />
    </svg>
  );
}
