import styled from 'styled-components';

export const StyledStatus = styled.div`
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  line-height: 15px;
  width: 100%;
`;

export const ComplianceStatusBadge = styled(StyledStatus)<{ isCompliant: boolean | null }>`
  ${(props) =>
    props.isCompliant == true
      ? `background-color: var(--green-50);
    color: var(--green-900);
    border: 0px solid transparent;`
      : props.isCompliant == false
      ? `background-color: var(--red-50);
    color: var(--red-900);
    border: 0px solid transparent;`
      : `background-color: var(--grey-50); /* light gray background */
  color: var(--grey-900); /* dark gray text */
  border-color: red; /* dark gray border */`};
`;

export const ExemptionStatusBadge = styled(StyledStatus)<{ isExempted: boolean }>`
  ${(props) =>
    props.isExempted &&
    `
    color: #ff7b00;
    background-color: #ffcd90;
    `}
`;

export const ChangeTypeBadge = styled(StyledStatus)<{ type: 'improvement' | 'regression' | 'assignment' }>`
  ${(props) => {
    return props.type === 'improvement'
      ? `background-color: #30af5b1a;
    color: var(--green-900);
    border: 0px solid transparent;`
      : props.type === 'regression'
      ? `background-color: var(--red-50);
    color: var(--red-900);
    border: 0px solid transparent;`
      : `background-color: var(--grey-50); /* light gray background */
  color: var(--grey-900); /* dark gray text */
  border-color: transparent; /* dark gray border */
  `;
  }};
`;
