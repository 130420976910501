import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { StyledButton } from '@app/components/common/StyledButton/StyledButton';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword.styles';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import LoginBGImage from '../../../assets/images/v2_login_background.png';
import styled from 'styled-components';

export const RememberMeText = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
`;

export const LoginContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const LoginBackground = styled.div`
  width: 100%;
  height: 100%;
  background: url('${LoginBGImage}') no-repeat center center;
  background-size: cover;
`;

export const LoginForm = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const LoginFormWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: 40%;
  padding: 2rem;
  gap: 6px;

  @media (width: 1920px) {
    width: 30%;
  }
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
  color: white;
  padding: 1rem;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const Logo = styled.img`
  object-fit: fill;
  padding: 1.25rem;
  max-width: 18rem;
  height: auto;
`;

export const LoginTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 500;
  font-family: Raleway;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-950);
  margin-bottom: 0.5rem !important;
`;

export const LoginDescription = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: var(--grey-700);
  text-align: center;
  font-family: Raleway;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;
`;

export const FormLabel = styled.label`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  margin-bottom: 0.5rem;
`;

export const TextField = styled(BaseInput)`
  height: 2.75rem;
  font-size: ${FONT_SIZE.xs};
  border-radius: 12px;
  border: 1px solid #e9eaeb;
  background: #fff;

  &::placeholder {
    color: #acacac;
  }

  .ant-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Set a background color */
  }
`;

export const PasswordField = styled(InputPassword)`
  height: 2.75rem;
  font-size: ${FONT_SIZE.xs};
  border-radius: 12px;
  border: 1px solid #e9eaeb;
  background: #fff;

  & ::placeholder {
    color: #acacac;
  }

  .ant-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Set a background color */
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.875rem;
  width: 100%;
`;

export const CheckBoxField = styled(BaseCheckbox)`
  display: flex;
  padding-left: 0.125rem;
  color: #acacac;
  font-size: ${FONT_SIZE.xs};

  & .ant-checkbox-inner {
    border-radius: 3px;
    transform: scale(1.375);
  }

  & .ant-checkbox-input {
    transform: scale(1.375);
  }
`;

export const ForgotPasswordText = styled.span`
  color: #3a50d1;
  font-size: ${FONT_SIZE.xs};
  text-decoration: none;
`;

export const LoginButton = styled(StyledButton)`
  width: 100%;
  margin-bottom: 1.25rem;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
  margin: 0.5rem 0;
`;

export const SocialLogin = styled(StyledButton)`
  width: 100%;
  border-color: #acacac;
  color: var(--grey-400);
  font-size: 1rem;
  font-family: Raleway;
`;

export const MicrosoftIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;

export const SignupText = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: #6d6d6d;
`;
