import styled from 'styled-components';

import LoginBGImage from '@app/assets/images/v2_login_background.png';
import Logo from '@app/assets/logo-dark.png';
import { PersonalDetails } from './PersonalDetails';
import { AccountInformation } from './AccountInformation';
import { useState } from 'react';
import { FaRegCopyright } from 'react-icons/fa6';
import { Steps } from 'antd';
import { FONT_WEIGHT } from '@app/styles/themes/constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url('${LoginBGImage}');
  background-size: cover;
  background-position: center;
`;

const FormContainer = styled.div<{ step: number }>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
  color: white;
  padding: 1rem;
`;

export const ContentContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: 40%;
  padding: 2rem;
  gap: 6px;

  @media (width: 1920px) {
    width: 30%;
  }
`;

export const LogoContainer = styled.img`
  object-fit: fill;
  padding: 1.25rem;
  max-width: 18rem;
  height: auto;
`;

const CustomSteps = styled(Steps)`
  .ant-steps-item-title {
    white-space: nowrap;
    font-size: 0.813rem !important;
    font-weight: 500;
  }
  .ant-steps-item-icon {
    border: none;
    background-color: var(--grey-100);
  }
  .ant-steps-item-finish .ant-steps-item-title {
    color: var(--green-800) !important;
    font-size: 0.825rem !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: var(--green-100);
    border-color: var(--green-100);
  }
  .ant-steps-item-finish .ant-steps-item-icon .anticon {
    color: var(--green-700);
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: var(--blue-500);
    border-color: var(--blue-500);
  }
  .ant-steps-item-process .ant-steps-item-title {
    font-size: 0.825rem !important;
    color: var(--grey-950) !important;
    font-weight: ${FONT_WEIGHT.semibold};
  }
  .ant-steps-item-tail:after {
    background-color: var(--grey-100) !important;
  }
`;

export function CreateAccountForm() {
  const params = new URLSearchParams(window.location.search);
  const [step, setStep] = useState(params.has('accountId') ? 2 : 1);

  const onChangeStep = () => {
    setStep(step === 1 ? 2 : 1);
  };

  return (
    <Container>
      <FormContainer step={step}>
        <LogoContainer src={Logo} />

        <ContentContainer>
          {/* Ant Design Steps */}
          <CustomSteps
            current={step - 1}
            onChange={onChangeStep}
            size="small"
            labelPlacement="vertical"
            items={[{ title: 'Account Details' }, { title: 'Account Information' }]}
            style={{ width: '70%' }}
          />

          {/* Conditionally Render Step Content */}
          {step === 1 ? <PersonalDetails /> : <AccountInformation />}
        </ContentContainer>

        <CopyrightWrapper>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <FaRegCopyright size="1rem" />
          </span>
          Griffin31. All rights reserved. Privacy • Terms
        </CopyrightWrapper>
      </FormContainer>
    </Container>
  );
}
