import { IconProps } from '../types';

export default function SettingsConnector(props: IconProps) {
  const { fill = 'none', style, className } = props;

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      style={style}
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 9.5C1.25 5.77208 4.27208 2.75 8 2.75H12C15.7279 2.75 18.75 5.77208 18.75 9.5C18.75 13.2279 15.7279 16.25 12 16.25H10C9.58579 16.25 9.25 15.9142 9.25 15.5C9.25 15.0858 9.58579 14.75 10 14.75H12C14.8995 14.75 17.25 12.3995 17.25 9.5C17.25 6.60051 14.8995 4.25 12 4.25H8C5.10051 4.25 2.75 6.60051 2.75 9.5C2.75 10.8451 3.25487 12.0705 4.08664 12.9998C4.36288 13.3085 4.33662 13.7826 4.02797 14.0589C3.71933 14.3351 3.24518 14.3088 2.96894 14.0002C1.90054 12.8065 1.25 11.2283 1.25 9.5ZM12 10.25C9.10051 10.25 6.75 12.6005 6.75 15.5C6.75 18.3995 9.10051 20.75 12 20.75H16C18.8995 20.75 21.25 18.3995 21.25 15.5C21.25 14.1549 20.7451 12.9295 19.9134 12.0002C19.6371 11.6915 19.6634 11.2174 19.972 10.9411C20.2807 10.6649 20.7548 10.6912 21.0311 10.9998C22.0995 12.1935 22.75 13.7717 22.75 15.5C22.75 19.2279 19.7279 22.25 16 22.25H12C8.27208 22.25 5.25 19.2279 5.25 15.5C5.25 11.7721 8.27208 8.75 12 8.75H14C14.4142 8.75 14.75 9.08579 14.75 9.5C14.75 9.91421 14.4142 10.25 14 10.25H12Z"
        fill={fill}
      />
    </svg>
  );
}
