import { Radio, RadioChangeEvent, Space } from 'antd';
import { TTableFilterContext, useTableFilterContext } from '../../store/context';
import { TBooleanFilter, TDataCol } from '../../types';
import { useEffect, useMemo, useState } from 'react';

export function BooleanFilter<T extends Record<string, unknown>>(props: TDataCol<T>) {
  const { filterValuesMap } = useTableFilterContext<TTableFilterContext<T>>();

  const [selectedBooleanFilter, setSelectedBooleanFilter] = useState<boolean | null | undefined>();

  const booleanFilterValue = useMemo(() => {
    return filterValuesMap?.get(props.dataIndex as string) || '';
  }, [filterValuesMap]);

  useEffect(() => {
    if (!booleanFilterValue) {
      setSelectedBooleanFilter(undefined);
      return;
    }

    const normalizedValue = booleanFilterValue.replaceAll("'", '');
    const parsedBooleanFilterValue = JSON.parse(normalizedValue);

    if (parsedBooleanFilterValue == true) {
      setSelectedBooleanFilter(true);
    } else if (parsedBooleanFilterValue == 'null') {
      setSelectedBooleanFilter(null);
    } else {
      setSelectedBooleanFilter(false);
    }
  }, [booleanFilterValue]);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedBooleanFilter(e.target.value);
    filterValuesMap?.set(props.dataIndex as string, e.target.value);
  };

  return (
    <div>
      <Radio.Group onChange={handleRadioChange} value={selectedBooleanFilter}>
        <Space direction="vertical">
          {(props as TBooleanFilter).hasNullOption && <Radio value={null}>N/A</Radio>}
          <Radio value={true}>True</Radio>
          <Radio value={false}>False</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
}
