import { TSubscriptionForm } from '@app/components/apps/manage-subscription/ManageSubscriptionForm';
import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';
import { useMutation, useQueryClient } from 'react-query';

export const getAllSubscriptionTypes = async () => {
  const subscriptionTypes = await httpApi.get('odata/subscriptionType?$filter=status eq 1');

  return subscriptionTypes?.data?.items;
};

export const getSubscriptionType = async (id: number) => {
  const subscriptionType = await httpApi.get(`odata/SubscriptionType?$filter=(id eq ${id})&$top=1`);
  return subscriptionType?.data?.items?.at(0);
};

const createSubscriptionType = async (data: TSubscriptionForm) => {
  const response = await httpApi.post<any>('/subscription-type', data);
  if (response?.status === 201) {
    notificationController.success({
      message: `Subscription Type Created`,
    });
  }
  return response.data;
};

export const useCreateSubscriptionType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSubscriptionType,
    onSuccess() {
      queryClient.invalidateQueries('subscriptionType-odata');
    },
  });
};

const updateSubscriptionType = async (payload: { id: number; data: TSubscriptionForm }) => {
  const { id, data } = payload;
  const response = await httpApi.patch<any>(`subscription-type/${id}`, data);
  if (response?.status === 200) {
    notificationController.success({
      message: `Subscription Type Updated`,
    });
  }
  return response.data;
};

export const useUpdateSubscriptionType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateSubscriptionType,
    onSuccess() {
      queryClient.invalidateQueries('subscriptionType-odata');
    },
  });
};

// const deleteSubscriptionType = async (id: number) => {
//   const response = await httpApi.delete<any>(`/subscriptionType/${id}`);

//   if (response?.status === 200) {
//     notificationController.success({
//       message: `Subscription Type Deleted`,
//     });
//   }

//   return response?.data;
// };

// const useDeteleSubscriptionType = () => {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: deleteSubscriptionType,
//     onSuccess() {
//       queryClient.invalidateQueries('subscriptionType-odata');
//     },
//   });
// };
