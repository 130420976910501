import React from 'react';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { TopbarTitle } from '../components/TopBarTitle/TopBarTitle';
import { ConnectorDropdown } from '../components/ConnectorDropdown/ConnectorDropdown';
import { AccountNotificationsDropdown } from '../components/accountNotifiactionsDropdown/accountNotificationsDropdown';
import { HeaderStats } from '../components/ConnectorDropdown/HeaderStats';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const userRole = useAppSelector((state) => state.user.user?.role);
  const leftSide = isTwoColumnsLayout ? (
    <BaseRow justify="space-between">
      <BaseCol>
        <TopbarTitle />
      </BaseCol>
    </BaseRow>
  ) : (
    <>
      <BaseCol>
        <TopbarTitle />
      </BaseCol>
    </>
  );

  return (
    <BaseRow justify="space-between" align="middle" style={{ backgroundColor: 'white' }}>
      {leftSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow
          justify="end"
          gutter={[0, 0]}
          style={{
            backgroundColor: 'var(--sider-background-color)',
            borderRadius: '25px',
            padding: '0 10px',
          }}
        >
          {userRole &&
            (userRole === getEnumValue('UserRole', 'Admin') || userRole === getEnumValue('UserRole', 'User')) && (
              <BaseRow>
                <BaseCol style={{ marginRight: '15px' }}>
                  <AccountNotificationsDropdown isMobile={false} />
                </BaseCol>

                <BaseCol>
                  <ConnectorDropdown />
                </BaseCol>
              </BaseRow>
            )}
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};
