import { IconProps } from '../types';

export default function WarningSquare(props: IconProps) {
  const { fill = 'currentColor', style, className } = props;

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 19"
      fill={fill}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00002 5.07716C9.3452 5.07716 9.62502 5.35698 9.62502 5.70216V10.7022C9.62502 11.0473 9.3452 11.3272 9.00002 11.3272C8.65484 11.3272 8.37502 11.0473 8.37502 10.7022V5.70216C8.37502 5.35698 8.65484 5.07716 9.00002 5.07716Z"
        fill={fill}
      />
      <path
        d="M9.83335 13.2022C9.83335 13.6624 9.46026 14.0355 9.00002 14.0355C8.53978 14.0355 8.16669 13.6624 8.16669 13.2022C8.16669 12.7419 8.53978 12.3688 9.00002 12.3688C9.46026 12.3688 9.83335 12.7419 9.83335 13.2022Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04784 0.910492H8.95221C7.02855 0.910482 5.52101 0.910474 4.34478 1.06861C3.14083 1.23048 2.19081 1.56826 1.44513 2.31394C0.69946 3.05961 0.361676 4.00963 0.19981 5.21358C0.0416687 6.38982 0.0416768 7.89734 0.0416872 9.821V9.91664C0.0416768 11.8403 0.0416687 13.3478 0.19981 14.5241C0.361676 15.728 0.69946 16.678 1.44513 17.4237C2.19081 18.1694 3.14083 18.5072 4.34478 18.669C5.52102 18.8272 7.02855 18.8272 8.95221 18.8272H9.04783C10.9715 18.8272 12.479 18.8272 13.6553 18.669C14.8592 18.5072 15.8092 18.1694 16.5549 17.4237C17.3006 16.678 17.6384 15.728 17.8002 14.5241C17.9584 13.3478 17.9584 11.8403 17.9584 9.91664V9.82102C17.9584 7.89735 17.9584 6.38982 17.8002 5.21358C17.6384 4.00963 17.3006 3.05961 16.5549 2.31394C15.8092 1.56826 14.8592 1.23048 13.6553 1.06861C12.479 0.910474 10.9715 0.910482 9.04784 0.910492ZM2.32902 3.19782C2.80373 2.72311 3.446 2.4507 4.51133 2.30747C5.59465 2.16182 7.01817 2.16049 9.00002 2.16049C10.9819 2.16049 12.4054 2.16182 13.4887 2.30747C14.554 2.4507 15.1963 2.72311 15.671 3.19782C16.1457 3.67254 16.4181 4.3148 16.5614 5.38014C16.707 6.46346 16.7084 7.88697 16.7084 9.86883C16.7084 11.8507 16.707 13.2742 16.5614 14.3575C16.4181 15.4228 16.1457 16.0651 15.671 16.5398C15.1963 17.0145 14.554 17.287 13.4887 17.4302C12.4054 17.5758 10.9819 17.5772 9.00002 17.5772C7.01817 17.5772 5.59465 17.5758 4.51133 17.4302C3.446 17.287 2.80373 17.0145 2.32902 16.5398C1.8543 16.0651 1.58189 15.4228 1.43866 14.3575C1.29302 13.2742 1.29169 11.8507 1.29169 9.86883C1.29169 7.88697 1.29302 6.46346 1.43866 5.38014C1.58189 4.3148 1.8543 3.67254 2.32902 3.19782Z"
        fill={fill}
      />
    </svg>
  );
}
