import React, { useState } from 'react';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import * as S from './ConnectorDropdownStyles';
import { ConnectorHeaderContainer, HeaderActionIcon } from '../../Header.styles';
import { HeaderStats } from './HeaderStats';
import ConnectorsLink from '@app/components/icons/connectors/ConnectorsLink';

export const ConnectorDropdown: React.FC = () => {
  const [isOpened, setOpened] = useState(false);

  return (
    <BasePopover trigger="click" content={<HeaderStats />} onOpenChange={setOpened}>
      <ConnectorHeaderContainer>
        <HeaderActionIcon type={'text'} icon={<ConnectorsLink />} />
        <S.ConnectorStatus />
      </ConnectorHeaderContainer>
    </BasePopover>
  );
};
