import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { MenuProps } from 'antd/lib/menu';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { TDataCol, TDataColumns, TFilterValue, TGroupDataCol } from '../types';
import { IFiltersProps } from '../components/Filters';
import { constructFilterStateFromUrl } from '@app/utils/utils';

interface IUseFiltersProps<T> {
  columns: TDataColumns<T>;
  defaultFilter?: string;
  onFiltersChanged?: (filters: TFilterValue[]) => void;
}

export function useFilters<T>({ columns, defaultFilter, onFiltersChanged }: IUseFiltersProps<T>): IFiltersProps<T> {
  const [searchColumnValue, setSearchColumnValue] = useState('');
  const [selectedFilterColumn, setSelectedFilterColumn] = useState<TGroupDataCol<T> | TDataCol<T> | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filters, setFilters] = useState<TFilterValue[]>([]);

  const columnFilters: ItemType[] = useMemo(() => {
    return columns
      .filter((col) => col.allowFiltering)
      .map((col, index) => ({
        key: col.dataIndex as string,
        label: col.title as string,
      }));
  }, [columns]);

  const displayedFilters = useMemo(() => {
    if (searchColumnValue === '')
      return columnFilters
        .filter((col: any) => {
          if (filters.length === 0) {
            return true;
          }
          const isAlreadyApplied = filters.find((f) => f.column.toLowerCase() === col.key.toLowerCase()) ? true : false;

          if (isAlreadyApplied) {
            return false;
          }

          return true;
        })
        .sort((a: any, b: any) => {
          if (a.label > b.label) {
            return 1;
          }

          if (a.label < b.label) {
            return -1;
          }

          return 0;
        });

    return columnFilters
      .filter((col: any) => col?.label.toLowerCase().indexOf(searchColumnValue.toLowerCase()) !== -1)
      .sort((a: any, b: any) => {
        if (a.label > b.label) {
          return 1;
        }

        if (a.label < b.label) {
          return -1;
        }

        return 0;
      });
  }, [columnFilters, searchColumnValue, filters]);

  useEffect(() => {
    if (defaultFilter) {
      parseDefaultFilters();
    }
  }, [defaultFilter]);

  const onFilterOptionClicked: MenuProps['onClick'] = (e) => {
    //to stop the closing of dropdown when an item is clicked
    e.domEvent.stopPropagation();

    const filterLabel = (columnFilters.find((col) => col?.key?.toString() === e.key) as any)?.label;
    const selectedColumn = columns.find((col) => col.title === filterLabel) as TDataCol<T>;

    setSelectedFilterColumn(selectedColumn);
    setSearchColumnValue('');
  };

  const onSearchColumn = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchColumnValue(e.target.value);
  };

  const toggleDropdown = (open: boolean) => {
    setIsDropdownOpen(open);

    if (open === true) {
      setSelectedFilterColumn(null);
      setSearchColumnValue('');
    }
  };

  const onBackClicked = () => {
    setSelectedFilterColumn(null);
    setSearchColumnValue('');
  };

  const onApply = (filterObj: TFilterValue) => {
    const newFilters = [...filters];

    const filterIndex = filters.findIndex(
      (filter) => filter.column === filterObj.column && filter.type === filterObj.type,
    );
    if (filterIndex > -1) {
      newFilters[filterIndex] = filterObj;
    } else {
      newFilters.push(filterObj);
    }

    toggleDropdown(false);
    setFilters(newFilters);
    onFiltersChanged?.(newFilters);
  };

  const onFilterRemove = (index: number) => {
    const newFilters = filters.filter((f, fIndex) => fIndex !== index);
    setFilters(newFilters);
    onFiltersChanged?.(newFilters);
  };

  const onClearFilters = () => {
    if (filters.length <= 0) return;

    setFilters([]);
    onFiltersChanged?.([]);
  };

  const parseDefaultFilters = () => {
    const filterObject = constructFilterStateFromUrl(new URLSearchParams(`$filter=${defaultFilter}`), columns);

    if (!filterObject) return;

    setFilters(filterObject);
    onFiltersChanged?.(filterObject);
  };

  return {
    columnFilters: displayedFilters,
    appliedFilters: filters,
    searchColumnValue,
    selectedFilterColumn,
    isDropdownOpen,
    onFilterOptionClicked,
    onSearchColumn,
    toggleDropdown,
    onBackClicked,
    onApply,
    onFilterRemove,
    onClearFilters,
  };
}
