import { IconProps } from '../types';

export default function FiltersIconV2(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1673 5.16675C12.1673 6.54746 11.048 7.66675 9.66732 7.66675C8.2866 7.66675 7.16732 6.54746 7.16732 5.16675C7.16732 3.78604 8.28661 2.66675 9.66732 2.66675C11.048 2.66675 12.1673 3.78604 12.1673 5.16675ZM9.66732 6.66675C10.4957 6.66675 11.1673 5.99517 11.1673 5.16675C11.1673 4.33832 10.4957 3.66675 9.66732 3.66675C8.83889 3.66675 8.16732 4.33832 8.16732 5.16675C8.16732 5.99517 8.83889 6.66675 9.66732 6.66675Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83398 11.8334C3.83398 13.2141 4.95327 14.3334 6.33398 14.3334C7.7147 14.3334 8.83398 13.2141 8.83398 11.8334C8.83398 10.4527 7.7147 9.33341 6.33398 9.33341C4.95327 9.33341 3.83398 10.4527 3.83398 11.8334ZM6.33398 13.3334C5.50556 13.3334 4.83398 12.6618 4.83398 11.8334C4.83398 11.005 5.50556 10.3334 6.33398 10.3334C7.16241 10.3334 7.83398 11.005 7.83398 11.8334C7.83398 12.6618 7.16241 13.3334 6.33398 13.3334Z"
        fill={fill}
      />
      <path
        d="M9.50065 11.8058C9.50065 11.5296 9.72451 11.3058 10.0007 11.3058H14.6673C14.9435 11.3058 15.1673 11.5296 15.1673 11.8058C15.1673 12.0819 14.9435 12.3058 14.6673 12.3058H10.0007C9.72451 12.3058 9.50065 12.0819 9.50065 11.8058Z"
        fill={fill}
      />
      <path
        d="M6.00065 4.63909C6.27679 4.63909 6.50065 4.86295 6.50065 5.13909C6.50065 5.41524 6.27679 5.63909 6.00065 5.63909L1.33398 5.63909C1.05784 5.63909 0.833984 5.41524 0.833984 5.13909C0.833984 4.86295 1.05784 4.63909 1.33398 4.63909L6.00065 4.63909Z"
        fill={fill}
      />
      <path
        d="M0.833984 11.8058C0.833984 11.5296 1.05784 11.3058 1.33398 11.3058H2.66732C2.94346 11.3058 3.16732 11.5296 3.16732 11.8058C3.16732 12.0819 2.94346 12.3058 2.66732 12.3058H1.33398C1.05784 12.3058 0.833984 12.0819 0.833984 11.8058Z"
        fill={fill}
      />
      <path
        d="M14.6673 4.63909C14.9435 4.63909 15.1673 4.86295 15.1673 5.13909C15.1673 5.41524 14.9435 5.63909 14.6673 5.63909H13.334C13.0578 5.63909 12.834 5.41524 12.834 5.13909C12.834 4.86295 13.0578 4.63909 13.334 4.63909H14.6673Z"
        fill={fill}
      />
    </svg>
  );
}
