/* eslint-disable no-var */
import ManageSubscriptionForm, {
  TSubscriptionForm,
} from '@app/components/apps/manage-subscription/ManageSubscriptionForm';
import useSubscriptionForm from '@app/components/apps/manage-subscription/useSubscriptionForm';
import CreateAlertButton from '@app/components/apps/recommendations/CreateAlertButton';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TSubscriptionType } from '@app/types/subscriptionType';
import { useCreateSubscriptionType, useUpdateSubscriptionType } from '@app/api/subscriptionType.api';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import { getEnumLabel, getEnumValue } from '@app/services/enum.service';
import { useDispatch } from 'react-redux';
import { refetchSubscriptionTypes } from '@app/store/slices/appSlice';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';

export default function SubscriptionTypesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formData, setFormData] = useState<TSubscriptionForm | undefined>();
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<TSubscriptionType | undefined>(undefined);
  const [recommendationFilterString, setRecommendationFilterString] = useState<string>('');
  const [newStatus, setNewStatus] = useState<number | undefined>(undefined);

  const { mutateAsync: createSubscriptionType, isLoading: isCreating } = useCreateSubscriptionType();
  const { mutateAsync: updateSubscriptionType, isLoading: isUpdating } = useUpdateSubscriptionType();

  const { toggle, ...subscriptionFormProps } = useSubscriptionForm({
    onOk: async (data) =>
      isEdit && selectedSubscriptionType ? confirmUpdateSubscriptionType(data) : confirmCreateSubscriptionType(data),
    onCancel: () => {
      setIsEdit(false);
      setSelectedSubscriptionType(undefined);
    },
    subscription: selectedSubscriptionType,
  });

  const tableActions: TActionItems<TSubscriptionType>[] = [
    {
      label: 'Edit',
      key: 'subscriptionTypeEdit',
      multiSelect: false,
      onClick: (rec) => {
        setIsEdit(true);
        setSelectedSubscriptionType(rec);
        setRecommendationFilterString(rec.recommendationsFilter || '');
        toggle();
      },
    },
    {
      label: 'Enable',
      key: 'enableSubscription',
      multiSelect: false,
      onClick: (rec) => handleSubscriptionTypeStatusChange(rec, true),
      show: (rec) => (rec.status as any) === getEnumLabel('SubscriptionTypeStatus', 2),
    },
    {
      label: 'Disable',
      key: 'disableSubscription',
      multiSelect: false,
      onClick: (rec) => handleSubscriptionTypeStatusChange(rec, false),
      show: (rec) => (rec.status as any) === getEnumLabel('SubscriptionTypeStatus', 1),
    },
  ];

  const subscriptionTypeTableCols: TDataColumns<TSubscriptionType> = [
    {
      title: 'Subscription Name',
      dataIndex: 'name',
      allowFiltering: true,
      type: 'text', //TODO: change to textOptions
      hideColumn: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'SubscriptionTypeStatus',
      showSortDirections: true,
      hideColumn: true,
    },
  ];

  const tableFilterProps = useTableFiltersV2<TSubscriptionType>({
    columns: subscriptionTypeTableCols,
    model: 'subscriptionType',
    searchableColumns: ['name'],
    orderBy: {
      column: 'id',
      order: 'desc',
    },
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  var dataTableProps = useDataTable<TSubscriptionType>({
    data: tableFilterProps.data?.items || [],
    columns: subscriptionTypeTableCols,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick: (data) => {
      setSelectedSubscriptionType(data);
      setRecommendationFilterString(data.recommendationsFilter || '');
      setIsEdit(true);
      toggle();
    },
  });

  // const dataTableProps = useDataTable<TSubscriptionType>({
  //   model: 'subscriptionType',
  //   columns: subscriptionTypeTableCols,
  //   stickyHeader: true,
  //   searchableColumns: ['name'],
  //   defaultOrder: {
  //     column: 'id',
  //     order: 'desc',
  //   },
  //   actionItems: tableActions,
  //   onRowClick: (data) => {
  //     setSelectedSubscriptionType(data);
  //     setRecommendationFilterString(data.recommendationsFilter || '');
  //     setIsEdit(true);
  //     toggle();
  //   },
  // });

  const confirmCreateSubscriptionType = async (data: TSubscriptionForm) => {
    await createSubscriptionType({ ...data });
    dispatch(refetchSubscriptionTypes());
    resetState();
    tableFilterProps.refetch();
  };

  const confirmUpdateSubscriptionType = (data: TSubscriptionForm) => {
    setFormData({ ...data });
    setConfirmationMessage('Are you sure you want to update this subscription type?');
    setIsConfirmationModalOpen(true);
  };

  const handleSubscriptionTypeStatusChange = (subscription: TSubscriptionType, isEnabling: boolean) => {
    setNewStatus(isEnabling ? 1 : 2);
    setConfirmationMessage(
      isEnabling
        ? 'Are you sure you want to enable this subscription type?'
        : 'Are you sure you want to disable this subscription type?',
    );
    setSelectedSubscriptionType(subscription);

    if (subscriptionFormProps.open) {
      toggle();
    }

    setIsConfirmationModalOpen(true);
  };

  const handleConfirm = async () => {
    if (selectedSubscriptionType) {
      const payload = {
        id: selectedSubscriptionType.id,
        data: {
          ...(formData ? formData : selectedSubscriptionType),
          status: newStatus
            ? newStatus
            : getEnumValue('SubscriptionTypeStatus', selectedSubscriptionType.status as unknown as string), // selectedSubscriptionType.status becomes a string after being mutated through the table.api
          recommendationsFilter: recommendationFilterString,
        },
      };

      await updateSubscriptionType(payload);

      dispatch(refetchSubscriptionTypes());

      resetState();
      dataTableProps.clearSelection();
      tableFilterProps.refetch();
    }
  };

  const resetState = () => {
    setSelectedSubscriptionType(undefined);
    setFormData(undefined);
    setNewStatus(undefined);
    setIsConfirmationModalOpen(false);
    setIsEdit(false);
    setRecommendationFilterString('');

    if (subscriptionFormProps.open) {
      toggle();
    }
  };

  return (
    <>
      <PageTitle>Subscription Types</PageTitle>

      <ComponentHeader title={t('common.subscriptionTypes')} />
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <BaseCTAButton onClick={toggle}>Create New Subscription</BaseCTAButton>
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={tableActions} />
        </DataTableV2.Actions>
      </DataTableV2>
      <ManageSubscriptionForm
        {...subscriptionFormProps}
        setRecommendationsFilter={setRecommendationFilterString}
        isEdit={isEdit}
        isLoading={isCreating || isUpdating}
      />
      <ActionConfirmationModal
        open={isConfirmationModalOpen}
        onOk={handleConfirm}
        onCancel={() => setIsConfirmationModalOpen(false)}
        message={confirmationMessage}
        isLoading={isUpdating}
      />
    </>
  );
}
