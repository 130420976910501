import useToggle from '@app/hooks/useToggle';
import { TableColumnsModal } from './TableColumnsModal';
import { useDataTableContext } from '../../store/context';
import { IDataTableProps } from '../../DataTable';
import { ListEditIcon } from '@app/components/icons';
import { BaseActionButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { Popover } from 'antd';
import { BasePopoverContent } from '@app/components/common/BaseTypography/BaseTypography';

export default function ManageTableColumnsButton<T extends Record<string, unknown>>() {
  const [isModalOpen, toggleModal, setModalOpen] = useToggle(false);

  const { tableProps, setTableProps } = useDataTableContext<IDataTableProps<T>>();

  return (
    <>
      <Popover
        content={<BasePopoverContent>Modify the list of fields displayed</BasePopoverContent>}
        placement="topLeft"
      >
        <BaseActionButton onClick={() => setModalOpen(true)}>
          <ListEditIcon />
          <span>Modify the table</span>
        </BaseActionButton>
      </Popover>
      <TableColumnsModal
        columns={tableProps.columns}
        open={isModalOpen}
        maskClosable
        onCancel={() => setModalOpen(false)}
        onApply={(newSelectedColumns) => setTableProps({ ...tableProps, columns: newSelectedColumns })}
      />
    </>
  );
}
