import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseActionButton, BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { HorizontalAlignedContainer, SpaceGrower } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { BaseSearchInput, IBaseSearchInputProps } from '@app/components/common/BaseSearch/SearchInput/BaseSearchInput';
import { FiltersIconV2 } from '@app/components/icons';
import { getColumnStringValue } from '@app/utils/utils';
import { Collapse, Drawer } from 'antd';
import { ReactElement, useMemo, useState } from 'react';
import { TableFilterContext } from '../store/context';
import { TBuildFilterProps, TDataCol, TDataColumns } from '../types';
import { AppliedFiltersSection, FilterChip, ValueLabel } from './AppliedFiltersSection';
import { FilterCount } from './FilterCount';
import { BooleanFilter } from './filters/BooleanFilter';
import { DateTimeFilter } from './filters/DateTimeFilter';
import { EnumFilter } from './filters/EnumFilter';
import { NumberFilter } from './filters/NumberFilter';
import { TextFilter } from './filters/TextFilter';
import { TextOptionsFilter } from './filters/TextOptionsFilter';

export interface ITableFiltersProps<T extends Record<string, unknown>> {
  columns: TDataColumns<T>;
  searchInputProps: IBaseSearchInputProps;
  canFilter?: boolean;
  canSearch?: boolean;
  children?: ReactElement<ITableFiltersComponentProps> | ReactElement<ITableFiltersComponentProps>[];
  filterValuesMap?: Map<string, string>; // this is used by the specific filters to reference and change their current values
  buildOdataQueryFromParams?: (props: TBuildFilterProps<T>) => void; // used to fetch fetch/refetch the data with the new/current odata query params
  removeFilter?: (key: string) => void;
  clearFilters?: () => void;
  onApplyFilterCallback?: () => void;
  resetFilterValuesMap?: () => void;
}

const NUMBER_OF_SHOWN_APPLIED_FILTERS = 2;

function TableFiltersV2<T extends Record<string, unknown>>(props: ITableFiltersProps<T>) {
  const {
    columns,
    searchInputProps,
    filterValuesMap,
    canFilter = true,
    canSearch = true,
    children,
    buildOdataQueryFromParams,
    clearFilters,
    onApplyFilterCallback,
    resetFilterValuesMap,
  } = props;

  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const filterColumns = columns.filter((col) => !!col.allowFiltering) || [];

  const ActionComponents = useMemo(() => {
    if (Array.isArray(children)) {
      const TableFilterActionsComponent = children?.find((child) => child.type === TableFilterActions);
      return TableFilterActionsComponent || undefined;
    }

    return children?.type === TableFilterActions ? children : undefined;
  }, [children]);

  function renderFilter(column: TDataCol<T>) {
    switch (column.type) {
      case 'text':
        return <TextFilter {...column} />;
      case 'enum':
      case 'enumArray':
        return <EnumFilter {...column} />;
      case 'boolean':
        return <BooleanFilter {...column} />;
      case 'textOptions':
        return <TextOptionsFilter {...column} />;
      case 'number':
        return <NumberFilter {...column} />;
      case 'date':
        return <DateTimeFilter {...column} />;
    }
  }

  function getColumnFilters(column: TDataCol<T>) {
    const filterValue = filterValuesMap?.get(column.dataIndex as string);

    if (!filterValue) return;

    const columnStringValue = getColumnStringValue(columns, column.dataIndex as string, filterValue);

    if (column.type === 'enum' || column.type === 'enumArray' || column.type === 'textOptions') {
      const values = columnStringValue.split(', ');
      const hiddenFilterValuesCount = values.length - NUMBER_OF_SHOWN_APPLIED_FILTERS;
      return (
        <HorizontalAlignedContainer style={{ gap: '4px', marginRight: '40px' }}>
          {values
            .filter((v, index) => index < NUMBER_OF_SHOWN_APPLIED_FILTERS)
            .map((val, index) => (
              <FilterChip key={index}>
                <ValueLabel>{val}</ValueLabel>
              </FilterChip>
            ))}
          {hiddenFilterValuesCount > 0 && <FilterChip>{`+${hiddenFilterValuesCount}`}</FilterChip>}
        </HorizontalAlignedContainer>
      );
    }

    return (
      <FilterChip style={{ marginRight: '40px' }}>
        <ValueLabel>{columnStringValue}</ValueLabel>
      </FilterChip>
    );
  }

  return (
    <TableFilterContext.Provider value={{ ...props }}>
      <HorizontalAlignedContainer style={{ gap: '16px', marginBottom: '16px' }}>
        {canSearch && <BaseSearchInput {...searchInputProps} />}
        {canFilter && (
          <BaseActionButton size="md" onClick={() => setFilterDrawerOpen(true)}>
            <FiltersIconV2 />
            <span>Filters</span>
            {!!filterValuesMap && filterValuesMap.size > 0 && <FilterCount>{filterValuesMap?.size}</FilterCount>}
          </BaseActionButton>
        )}
        <SpaceGrower />
        {!!ActionComponents && ActionComponents}
      </HorizontalAlignedContainer>

      <AppliedFiltersSection />

      {/* Filter Drawer */}
      <Drawer
        title="Filters"
        placement="right"
        onClose={() => {
          resetFilterValuesMap?.();
          setFilterDrawerOpen(false);
        }}
        destroyOnClose
        open={isFilterDrawerOpen}
        mask={false}
        footer={
          <HorizontalAlignedContainer>
            <BaseButton
              onClick={() => {
                clearFilters?.();
                setFilterDrawerOpen(false);
              }}
              type="text"
              style={{ color: 'var(--red-800)', fontSize: '0.875rem', fontWeight: '500' }}
            >
              Clear all
            </BaseButton>
            <SpaceGrower />
            <BaseCTAButton
              style={{ padding: '12px 40px' }}
              onClick={() => {
                buildOdataQueryFromParams?.({ paginationProps: { skip: 0 } });
                onApplyFilterCallback?.();
              }}
            >
              Apply
            </BaseCTAButton>
          </HorizontalAlignedContainer>
        }
        bodyStyle={{ padding: '14px 16px' }}
      >
        <Collapse ghost expandIconPosition="end">
          {filterColumns
            .sort((a, b) => {
              const aTitle = typeof a.title === 'string' ? a.title : a.altLabel || '';
              const bTitle = typeof b.title === 'string' ? b.title : b.altLabel || '';

              return aTitle.toLowerCase().localeCompare(bTitle.toLowerCase());
            })
            .map((col, index) => (
              <Collapse.Panel
                key={col.dataIndex as string}
                header={typeof col.title === 'string' ? (col.title as string) : col.altLabel}
                style={{ borderBottom: '1px solid var(--grey-100)', borderRadius: '0px', padding: '14px 0px' }}
                extra={getColumnFilters(col)}
              >
                {renderFilter(col)}
              </Collapse.Panel>
            ))}
        </Collapse>
      </Drawer>
    </TableFilterContext.Provider>
  );
}

interface ITableFiltersComponentProps {
  children: ReactElement | ReactElement[];
}

/**
 * Child Components under these Parent components can use the hook below to get the
 * values of the filters:
 * const { filterValuesMap, buildOdataQueryFromParams } = useTableFilterContext<TTableFilterContext<T>>();
 *
 * Note: the typescript generic T type should be the same as the type of the data supplied
 * on the TableFilters component
 */
function TableFilterActions({ children }: ITableFiltersComponentProps) {
  return <>{children}</>;
}

TableFiltersV2.Actions = TableFilterActions;

export default TableFiltersV2;
