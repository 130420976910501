import { IconProps } from '../types';

export default function HelpIcon(props: IconProps) {
  const { fill = 'currentColor', style, className } = props;

  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M8.99984 6.32731C8.48207 6.32731 8.06234 6.74704 8.06234 7.26481C8.06234 7.60999 7.78252 7.88981 7.43734 7.88981C7.09216 7.88981 6.81234 7.60999 6.81234 7.26481C6.81234 6.05669 7.79172 5.07731 8.99984 5.07731C10.208 5.07731 11.1873 6.05669 11.1873 7.26481C11.1873 8.06759 10.7545 8.76862 10.1126 9.14845C9.9486 9.24545 9.81325 9.35137 9.72507 9.45529C9.63946 9.55617 9.62484 9.62198 9.62484 9.66064V10.7023C9.62484 11.0475 9.34502 11.3273 8.99984 11.3273C8.65466 11.3273 8.37484 11.0475 8.37484 10.7023V9.66064C8.37484 9.23907 8.5594 8.89701 8.772 8.64649C8.98202 8.399 9.24391 8.20998 9.47606 8.07263C9.75353 7.90847 9.93734 7.60777 9.93734 7.26481C9.93734 6.74704 9.5176 6.32731 8.99984 6.32731Z"
        fill={fill}
      />
      <path
        d="M8.99984 14.0356C9.46007 14.0356 9.83317 13.6625 9.83317 13.2023C9.83317 12.7421 9.46007 12.369 8.99984 12.369C8.5396 12.369 8.1665 12.7421 8.1665 13.2023C8.1665 13.6625 8.5396 14.0356 8.99984 14.0356Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.95202 0.910645H9.04765C10.9713 0.910634 12.4788 0.910626 13.6551 1.06877C14.859 1.23063 15.8091 1.56842 16.5547 2.31409C17.3004 3.05977 17.6382 4.00979 17.8 5.21373C17.9582 6.38997 17.9582 7.8975 17.9582 9.82117V9.91679C17.9582 11.8405 17.9582 13.348 17.8 14.5242C17.6382 15.7282 17.3004 16.6782 16.5547 17.4239C15.8091 18.1695 14.859 18.5073 13.6551 18.6692C12.4788 18.8273 10.9713 18.8273 9.04765 18.8273H8.95203C7.02836 18.8273 5.52083 18.8273 4.34459 18.6692C3.14065 18.5073 2.19063 18.1695 1.44495 17.4239C0.699277 16.6782 0.361493 15.7282 0.199626 14.5242C0.0414856 13.348 0.0414937 11.8405 0.0415041 9.91681V9.82116C0.0414937 7.89751 0.0414856 6.38997 0.199626 5.21373C0.361493 4.00979 0.699277 3.05977 1.44495 2.31409C2.19063 1.56842 3.14065 1.23063 4.34459 1.06877C5.52083 0.910626 7.02837 0.910634 8.95202 0.910645ZM4.51115 2.30762C3.44582 2.45085 2.80355 2.72326 2.32883 3.19798C1.85412 3.67269 1.58171 4.31496 1.43848 5.38029C1.29283 6.46361 1.2915 7.88712 1.2915 9.86898C1.2915 11.8508 1.29283 13.2743 1.43848 14.3577C1.58171 15.423 1.85412 16.0653 2.32883 16.54C2.80355 17.0147 3.44582 17.2871 4.51115 17.4303C5.59447 17.576 7.01798 17.5773 8.99984 17.5773C10.9817 17.5773 12.4052 17.576 13.4885 17.4303C14.5539 17.2871 15.1961 17.0147 15.6708 16.54C16.1456 16.0653 16.418 15.423 16.5612 14.3577C16.7068 13.2743 16.7082 11.8508 16.7082 9.86898C16.7082 7.88712 16.7068 6.46361 16.5612 5.38029C16.418 4.31496 16.1456 3.67269 15.6708 3.19798C15.1961 2.72326 14.5539 2.45085 13.4885 2.30762C12.4052 2.16197 10.9817 2.16065 8.99984 2.16065C7.01798 2.16065 5.59447 2.16197 4.51115 2.30762Z"
        fill={fill}
      />
    </svg>
  );
}
