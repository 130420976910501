/* eslint-disable no-var */
import { useUpdateMessageTemplate } from '@app/api/message-template.api';
import MessagingForm, { TMessageTemplateForm } from '@app/components/apps/messaging/MessagingForm';
import useMessagingForm from '@app/components/apps/messaging/useMessagingForm';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { getEnumValue } from '@app/services/enum.service';
import { TMessageTemplate } from '@app/types/messaging';
import { useState } from 'react';

const tableColumns: TDataColumns<TMessageTemplate> = [
  {
    title: 'ID',
    dataIndex: 'id',
    allowFiltering: true,
    type: 'number',
    width: '2vw',
  },
  {
    title: 'Last Updated',
    dataIndex: 'lastUpdateTime',
    allowFiltering: true,
    type: 'date',
    width: '4vw',
  },
  {
    title: 'Name',
    dataIndex: 'displayName',
    allowFiltering: true,
    type: 'text',
    width: '4vw',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    allowFiltering: true,
    type: 'text',
    width: '10vw',
  },
];

const MessagingPage = () => {
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<TMessageTemplate | undefined>(undefined);
  const { mutateAsync: updateMessageTemplate, isLoading } = useUpdateMessageTemplate();
  const { toggle, ...messagingFormProps } = useMessagingForm({
    onOk: async ({ id, title, content }: TMessageTemplateForm) => {
      await updateMessageTemplate(
        {
          id: Number(id),
          payload: {
            title,
            content,
          },
        },
        {
          onSuccess: () => {
            setSelectedMessageTemplate(undefined);
            toggle();
            tableFilterProps.refetch();
          },
        },
      );
    },
    onCancel: () => {
      setSelectedMessageTemplate(undefined);
      toggle();
    },
    messageTemplate: selectedMessageTemplate,
  });

  const actionItems: TActionItems<TMessageTemplate>[] = [
    {
      key: 'edit',
      label: 'Edit',
      multiSelect: false,
      onClick: (record) => {
        setSelectedMessageTemplate(record);
        toggle();
      },
    },
  ];

  const tableFilterProps = useTableFiltersV2<TMessageTemplate>({
    columns: tableColumns,
    model: 'MessageTemplate',
    searchableColumns: ['displayName'],
    constantFilter: { status: getEnumValue('MessageTemplateStatus', 'Enabled') },
    orderBy: {
      column: 'displayName',
      order: 'asc',
    },
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  var dataTableProps = useDataTable<TMessageTemplate>({
    data: tableFilterProps.data?.items || [],
    columns: tableColumns,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick(data) {
      setSelectedMessageTemplate(data);
      toggle();
    },
  });

  // const dataTableProps = useDataTable<TMessageTemplate>({
  //   model: 'MessageTemplate',
  //   columns: tableColumns,
  //   searchableColumns: ['displayName'],
  //   actionItems,
  //   constantFilter: `status eq ${getEnumValue('MessageTemplateStatus', 'Enabled')}`,
  //   defaultOrder: {
  //     column: 'displayName',
  //     order: 'asc',
  //   },
  // });

  return (
    <>
      <PageTitle>Messaging</PageTitle>
      <ComponentHeader title="Messaging" />
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps} />
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
        </DataTableV2.Actions>
      </DataTableV2>
      <MessagingForm {...messagingFormProps} isLoading={isLoading} />
    </>
  );
};

export default MessagingPage;
