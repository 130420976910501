import { IconProps } from '../types';

export default function ChevronRight(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2107 7.93889C10.504 7.64641 10.9788 7.64708 11.2713 7.94039L14.7913 11.4704C15.0832 11.7631 15.0832 12.2368 14.7913 12.5295L11.2713 16.0595C10.9788 16.3529 10.504 16.3535 10.2107 16.0611C9.91735 15.7686 9.91668 15.2937 10.2092 15.0004L13.2011 12L10.2092 8.99955C9.91668 8.70624 9.91735 8.23137 10.2107 7.93889Z"
        fill={fill}
        style={style}
      />
    </svg>
  );
}
