import { IconProps } from '../types';

export default function ConnectorsLink(props: IconProps) {
  const { fill = 'none', style, className } = props;

  return (
    <svg
      style={style}
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3257 18.775H8.6746C5.83428 18.775 3.53174 16.4724 3.53174 13.6321V8.63348C3.53174 7.68671 4.29924 6.91919 5.24602 6.91919H18.7542C19.701 6.91919 20.4685 7.68671 20.4685 8.63348V13.6321C20.4685 16.4724 18.166 18.775 15.3257 18.775Z"
        stroke="#092A16"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
      <path
        d="M7.76562 6.91886V0.990967"
        stroke="#092A16"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
      <path
        d="M16.2344 6.91886V0.990967"
        stroke="#092A16"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
      <path
        d="M12 18.7747V23.0089"
        stroke="#092A16"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
    </svg>
  );
}
