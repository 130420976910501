import { setPageTitle } from '@app/store/slices/appSlice';
import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderSubtitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey-600);
  width: 100%;
  margin-bottom: 24px;
`;

interface IBasePageWrapper {
  title: string;
  description?: string;
}

const BasePageWrapper = ({ title, description, children }: PropsWithChildren<IBasePageWrapper>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(title));
  }, [title]);

  return (
    <Container>
      {!!description && <HeaderSubtitle>{description}</HeaderSubtitle>}
      {children}
    </Container>
  );
};

export { BasePageWrapper };
