import React from 'react';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import styled from 'styled-components';
import { Steps } from 'antd';
import parse, { DOMNode, Element, domToReact } from 'html-react-parser';
import { BASE_COLORS, COMPACT_TABS_COLORS, RECOMMENDATION_DETAILS_MODAL_COLORS } from '@app/styles/themes/constants';
import { LuArrowRight } from 'react-icons/lu';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';

const StyledSteps = styled(Steps)`
  .ant-steps-item-description {
    font-size: 14px;
    color: ${COMPACT_TABS_COLORS.darkGray} !important;
    font-weight: normal !important;
  }

  .custom-step-icon {
    background-color: #e3e8fc;
    color: #3c3679;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: semibold;
  }
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #272727;
`;

export default function RemediationSection({
  recommendation,
}: {
  recommendation?: Partial<TAccountRecommendationExtended>;
}) {
  const sanitizeTextNode = (text: string) => {
    const lines = text.split('\n').map((line) => line.trim());

    const filteredLines = lines.filter((line) => !/^\d+$/.test(line));

    const bulletRegex = /^(?:\d+\.)|(?:•)/;
    const cleaned = filteredLines.map((line) => line.replace(bulletRegex, '').trimStart());
    return cleaned.join(' ');
  };

  const parseStepContent = (domNodeChildren: DOMNode[]) => {
    return domToReact(domNodeChildren, {
      replace: (childNode) => {
        if (childNode.type === 'text' && childNode.data) {
          const newData = sanitizeTextNode(childNode.data);

          if (newData.includes('>')) {
            return (
              <>
                {newData.split('>').map((segment, i, arr) => (
                  <React.Fragment key={i}>
                    {segment}
                    {i < arr.length - 1 && <LuArrowRight />}
                  </React.Fragment>
                ))}
              </>
            );
          }

          return newData;
        }

        if ((childNode as Element).tagName === 'strong') {
          return <span style={{ fontWeight: 600 }}>{domToReact((childNode as Element).children as DOMNode[])}</span>;
        }

        if ((childNode as Element).tagName === 'a') {
          const href = (childNode as Element).attribs?.href;
          return (
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: RECOMMENDATION_DETAILS_MODAL_COLORS.blueLink }}
            >
              {domToReact((childNode as Element).children as DOMNode[])}
            </a>
          );
        }

        if ((childNode as Element).tagName === 'code') {
          return (
            <code style={{ color: BASE_COLORS.violetButton }}>
              {domToReact((childNode as Element).children as DOMNode[])}
            </code>
          );
        }

        return childNode;
      },
    });
  };

  const renderSteps = (htmlString: string) => {
    const steps: Array<{ description?: React.ReactNode }> = [];
    let stopParsing = false;

    parse(htmlString, {
      replace: (domNode) => {
        if ((domNode as Element).tagName === 'p' && (domNode as Element).children) {
          const textContent = domToReact((domNode as Element).children as DOMNode[]);
          if (typeof textContent === 'string' && textContent === '\u00a0') {
            stopParsing = true;
            return null;
          }
        }

        if (!stopParsing) {
          if ((domNode as Element).tagName === 'p') {
            const rawText = ((domNode as Element).children || [])
              .map((c) => (c.type === 'text' ? (c as any).data : ''))
              .join('')
              .trim();

            if (/^(\d+\.)|^•/.test(rawText)) {
              const stepContent = parseStepContent((domNode as Element).children as DOMNode[]);
              steps.push({ description: stepContent });
              return null;
            }
          }

          if ((domNode as Element).tagName === 'li') {
            const stepContent = parseStepContent((domNode as Element).children as DOMNode[]);
            steps.push({ description: stepContent });
            return null;
          }
        }
        return domNode;
      },
    });

    return steps;
  };

  const extractNotesSection = (htmlString: string) => {
    const notesStartIndex = htmlString.indexOf('<p>&nbsp;</p>');
    if (notesStartIndex !== -1) {
      const afterNbspIndex = notesStartIndex + '<p>&nbsp;</p>'.length;
      return htmlString.slice(afterNbspIndex).trim();
    }
    return '';
  };

  const remediationSteps = recommendation?.remediation ? renderSteps(recommendation.remediation) : [];
  const defaultHtml = recommendation?.remediation ? extractNotesSection(recommendation.remediation) : '';

  return (
    <>
      {recommendation?.remediation && remediationSteps.length > 0 ? (
        <>
          <StyledSteps
            direction="vertical"
            size="small"
            items={remediationSteps.map((step, index) => ({
              title: null,
              description: step.description,
              status: 'finish',
              icon: <div className="custom-step-icon">{index + 1}</div>,
            }))}
          />
          {defaultHtml && <HtmlEditor initialValue={defaultHtml} readOnly />}
        </>
      ) : (
        <Value>No remediation steps</Value>
      )}
    </>
  );
}
