import { ProductsToggleForm } from '@app/components/apps/general-settings/ProductsToggleForm';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import SettingsConnector from '@app/components/icons/connectors/SettingsConnector';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const MainContainer = styled.div`
  min-height: 50vh;
  border: 1px solid var(--grey-100);
  background-color: white;
  padding: 24px;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;
const Title = styled.span`
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--grey-950);
`;
const TitleContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  align-items: center;
`;

const GeneralSettings = () => {
  const { t } = useTranslation();

  return (
    <BasePageWrapper title={t('common.generalSettings')} description="Configure general accounts settings.">
      <PageTitle>General Settings</PageTitle>

      <MainContainer>
        <TitleContainer>
          <SettingsConnector fill="var(--grey-950)" />
          <Title>Connected Products</Title>
        </TitleContainer>
        <ProductsToggleForm />
      </MainContainer>
    </BasePageWrapper>
  );
};

export default GeneralSettings;
