import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BASE_COLORS } from '@app/styles/themes/constants';
import styled from 'styled-components';

interface ICreateAlertButtonProps {
  disabled?: boolean;
  title: string;
  onPress?: () => void;
}

const CreateAlertButton = ({ disabled = false, title, onPress }: ICreateAlertButtonProps) => {
  return (
    <StyledBaseButton type={disabled ? 'default' : 'primary'} disabled={disabled} onClick={() => onPress?.()}>
      {title}
    </StyledBaseButton>
  );
};

export default CreateAlertButton;

const StyledBaseButton = styled(BaseButton)`
  border-radius: 4px;
  height: 2.75rem;
  background-color: var(--blue-600);
  color: ${BASE_COLORS.white};

  &:hover {
    background-color: var(--blue-400);
  }

  &:disabled {
    opacity: 0.6;
    background: var(--grey-100);
    color: var(--grey-300);
  }
  &:disabled:hover {
    background: var(--grey-100);
    color: var(--grey-300);
  }
`;
