import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/logo-dark.png';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { getEnumValue } from '@app/services/enum.service';
import { LogoIcon } from '@app/components/icons';

const enabledStatusFilter = [
  {
    column: 'status',
    enumKey: 'AccountStatus',
    value: JSON.stringify([1]),
    type: 'enum',
    title: 'Status',
  },
];

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly, desktopOnly } = useResponsive();
  const userRole: number = useSelector<RootState>((state) => state.user.user?.role) as number;

  const logoLink =
    !!userRole && [getEnumValue('UserRole', 'SuperAdmin'), getEnumValue('UserRole', 'Vendor')].includes(userRole)
      ? `/`
      : '/dashboard';

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to={logoLink}>
        {isSiderCollapsed ? <LogoIcon fill="#e8e4e4" /> : <img src={logo} width={'75%'} />}
      </S.SiderLogoLink>
    </S.SiderLogoDiv>
  );
};
