import { IconProps } from '../types';

export default function LightBulb(props: IconProps) {
  const { fill = 'currentColor', style, className } = props;

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill={fill}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70835 17.1188C7.70835 16.7736 7.98818 16.4938 8.33335 16.4938H11.6667C12.0119 16.4938 12.2917 16.7736 12.2917 17.1188C12.2917 17.464 12.0119 17.7438 11.6667 17.7438H8.33335C7.98818 17.7438 7.70835 17.464 7.70835 17.1188ZM8.26391 19.2022C8.26391 18.857 8.54373 18.5772 8.88891 18.5772H11.1111C11.4563 18.5772 11.7361 18.857 11.7361 19.2022C11.7361 19.5473 11.4563 19.8272 11.1111 19.8272H8.88891C8.54373 19.8272 8.26391 19.5473 8.26391 19.2022Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54169 7.71722C3.54169 4.46326 6.50326 1.91049 10 1.91049C13.4968 1.91049 16.4584 4.46326 16.4584 7.71722L16.4583 7.71994C16.4503 9.55856 15.6595 11.3902 14.2546 12.7227L14.2529 12.7243L13.3329 13.5903C13.2002 13.7151 13.125 13.8892 13.125 14.0713C13.125 14.949 12.4135 15.6605 11.5359 15.6605H8.46418C7.58651 15.6605 6.87502 14.949 6.87502 14.0713C6.87502 13.8892 6.79982 13.7152 6.66719 13.5903L5.74714 12.7243L5.74541 12.7227C4.34053 11.3902 3.54969 9.55856 3.54169 7.71994L3.54169 7.71722ZM4.79169 7.71587C4.79855 9.20387 5.44186 10.7115 6.60473 11.8149C6.60502 11.8152 6.60531 11.8155 6.60561 11.8157L7.5239 12.6801C7.90751 13.0411 8.12502 13.5445 8.12502 14.0713C8.12502 14.2586 8.27687 14.4105 8.46418 14.4105H11.5359C11.7232 14.4105 11.875 14.2586 11.875 14.0713C11.875 13.5445 12.0925 13.0411 12.4761 12.6801C12.4761 12.6801 12.4761 12.6801 12.4761 12.6801L13.3944 11.8157C13.3947 11.8155 13.395 11.8152 13.3953 11.815C14.5582 10.7116 15.2015 9.20384 15.2084 7.7158C15.2075 5.28506 12.946 3.16049 10 3.16049C7.05401 3.16049 4.79252 5.2851 4.79169 7.71587Z"
        fill={fill}
      />
    </svg>
  );
}
