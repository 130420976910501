import { IconProps } from '../types';

export default function AccountNotificationsBell(props: IconProps) {
  const { fill = 'currentColor', style, className } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V10C11.25 10.4142 11.5858 10.75 12 10.75C12.4142 10.75 12.75 10.4142 12.75 10V6Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25007 9C4.25007 4.71979 7.71986 1.25 12.0001 1.25C16.2803 1.25 19.7501 4.71979 19.7501 9V9.7041C19.7501 10.401 19.9564 11.0824 20.3429 11.6622L21.4915 13.3851C22.8246 15.3848 21.8069 18.1028 19.4883 18.7351C18.7327 18.9412 17.9706 19.1155 17.2042 19.2581L17.2023 19.2632C16.4333 21.3151 14.378 22.75 12 22.75C9.62201 22.75 7.5667 21.3151 6.79771 19.2632L6.79581 19.2581C6.0294 19.1155 5.26741 18.9412 4.5118 18.7351C2.19321 18.1028 1.1755 15.3848 2.50859 13.3851L3.6572 11.6622C4.04378 11.0824 4.25007 10.401 4.25007 9.7041V9ZM8.62352 19.5369C10.8665 19.8049 13.1335 19.805 15.3765 19.537C14.6655 20.5585 13.429 21.25 12 21.25C10.571 21.25 9.33447 20.5585 8.62352 19.5369ZM12.0001 2.75C8.54829 2.75 5.75007 5.54822 5.75007 9V9.7041C5.75007 10.6972 5.45612 11.668 4.90527 12.4943L3.75667 14.2172C2.9915 15.3649 3.57564 16.925 4.90647 17.288C9.55073 18.5546 14.4494 18.5546 19.0937 17.288C20.4245 16.925 21.0086 15.3649 20.2435 14.2172L19.0949 12.4943C18.544 11.668 18.2501 10.6972 18.2501 9.7041V9C18.2501 5.54822 15.4518 2.75 12.0001 2.75Z"
        fill={fill}
      />
    </svg>
  );
}
