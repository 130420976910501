import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { signUp } from '@app/api/auth.api';
import { UserData, UserModel } from '@app/domain/UserModel';
import { deleteToken, persistSessionToken, readToken } from '@app/services/localStorage.service';
import { getEnumValue } from '@app/services/enum.service';
import { TAccountRegulations } from '@app/types/accountRegulations';

export interface UserState {
  isAuthenticated: boolean;
  name: string;
  numEnabledAccountRecommendations: number;
  user: UserModel | null;
  regulations: TAccountRegulations[];
}

const initialState: UserState = {
  user: null,
  name: '',
  isAuthenticated: readToken() === null ? false : true,
  regulations: [],
  numEnabledAccountRecommendations: 0,
};

export const setUser = createAction<
  PrepareAction<{
    user: UserModel;
    isAuthenticated: boolean;
    regulations: TAccountRegulations[];
    name: string;
    numEnabledAccountRecommendations: number;
  }>
>('user/setUser', (userData: UserData) => {
  const activeUserStatus = getEnumValue('UserStatus', 'Active');

  if (userData?.user && userData?.user?.sessionToken !== null) {
    persistSessionToken(userData?.user?.sessionToken);
  } else if (!userData || (userData && userData?.user?.sessionToken === null)) {
    deleteToken();
  }

  return {
    payload: {
      user: userData?.user,
      name: userData?.name || '',
      numEnabledAccountRecommendations: userData?.numEnabledAccountRecommendations || 0,
      isAuthenticated: !userData || readToken() === null || userData?.user?.status != activeUserStatus ? false : true,
      regulations: userData?.regulations,
    },
  };
});

export const doSignUp = createAsyncThunk('auth/doSignUp', async () => {
  const redirectUri = await signUp();
  window.location.href = redirectUri;
});

export const logout = createAction<PrepareAction<null>>('user/logoutUser', () => {
  deleteToken();

  return {
    payload: null,
  };
});

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload.user;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.regulations = action.payload.regulations;
      state.name = action.payload.name;
      state.numEnabledAccountRecommendations = action.payload.numEnabledAccountRecommendations;
    });
    builder.addCase(logout, (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = false;
      state.regulations = [];
      state.name = '';
      state.numEnabledAccountRecommendations = 0;
    });
  },
});

export default user.reducer;
