import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getModelItems } from '@app/api/table.api';
import ComplianceCard from '@app/components/apps/regulations-compliance/ComplianceCard';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import { SearchComponent } from '@app/components/common/BaseSearch/SearchComponent';
import { useSearchComponent } from '@app/components/common/BaseSearch/useSearch';
import { NoData } from '@app/components/common/NoData';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useLoadMore } from '@app/hooks/useLoadMore';
import { getEnumValue } from '@app/services/enum.service';
import { TAccountRegulations } from '@app/types/accountRegulations';
import { getScreenResolution } from '@app/utils/utils';
import { Spin } from 'antd';
import styled from 'styled-components';
import { throttle } from 'lodash';

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  column-gap: 6px;
  row-gap: 16px;
  margin-top: 3.5vh;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function RegulationsCompliancePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const resolution = getScreenResolution();
  const itemsPerPage = resolution.height >= 1080 ? 9 : 7;
  const useSearchProps = useSearchComponent({
    debounceTimeout: 800,
  });

  const { data, isFetching, loadMoreData, reset, refetch, hasMore } = useLoadMore<
    TAccountRegulations,
    TAccountRegulations
  >({
    queryKey: 'account-regulations',
    itemsPerPage: itemsPerPage,
    fetchFn: async (top, skip) => {
      const filter = useSearchProps.debouncedQuery
        ? `$filter=((contains(tolower(name),'${useSearchProps.debouncedQuery.toLowerCase()}')) and (status eq ${getEnumValue(
            'RegulationStatus',
            'Active',
          )}))&$top=${top}&$skip=${skip}`
        : `$filter=(status eq 1)&$top=${top}&$skip=${skip}`;

      const response = await getModelItems<TAccountRegulations>({
        model: 'AccountRegulations',
        queryParams: filter,
      });

      return response;
    },
  });

  useEffect(() => {
    if (useSearchProps.debouncedQuery) {
      reset();
    }
    refetch();
  }, [useSearchProps.debouncedQuery]);

  const handleScroll = useCallback(
    throttle(() => {
      const mainContainer = document.getElementById('main-content');
      if (!mainContainer) return;

      const { scrollTop, scrollHeight, clientHeight } = mainContainer;

      if (scrollTop + clientHeight >= scrollHeight - 100 && hasMore && !isFetching) {
        loadMoreData();
      }
    }, 300),
    [loadMoreData, hasMore, isFetching],
  );

  useEffect(() => {
    const mainContainer = document.getElementById('main-content');

    if (!mainContainer) {
      return;
    }

    mainContainer.addEventListener('scroll', handleScroll);
    return () => {
      mainContainer.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleCardClick = (id: number) => {
    navigate(`/regulation-information/${id}`);
  };

  return (
    <BasePageWrapper
      title={t('common.regulationsCompliance')}
      description="Below is a summary of your environmental adherence to the set of standard regulations in the field of security posture."
    >
      <PageTitle>{t('common.regulationsCompliance')}</PageTitle>

      <SearchComponent {...useSearchProps} />
      {!isFetching && data.length === 0 && <NoData withIcon={true} />}
      <CardsContainer>
        {(data || []).map((item) => (
          <ComplianceCard
            key={item.id}
            {...{
              ...item,
              numOfRecommendations: parseInt(item.numOfRecommendations),
              numOfCompliant: parseInt(item.numOfCompliant),
              numOfNonCompliant: parseInt(item.numOfNonCompliant),
            }}
            onCardClick={() => handleCardClick(item.id)}
          />
        ))}
      </CardsContainer>
      {isFetching && (
        <LoadingContainer>
          <Spin delay={10} size="large" />
        </LoadingContainer>
      )}
    </BasePageWrapper>
  );
}
