import { BASE_COLORS } from '@app/styles/themes/constants';
import { useState } from 'react';
import { MdOutlineFileDownload, MdOutlineEmail } from 'react-icons/md';
import styled from 'styled-components';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  margin: 0.5rem 0;
`;
const AsyncActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 6px;
  background-color: var(--blue-600);
  padding: 10px 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  width: 13vw;
  cursor: pointer;

  &:hover {
    background-color: var(--blue-500);
    transition: background-color 0.2s;
  }

  &:disabled {
    background-color: var(--grey-100);
    border-color: none;
    color: white;
    cursor: default;
    pointer-events: none;
  }
`;
const CancelButton = styled.button`
  border: 1px solid var(--blue-50);
  border-radius: 6px;
  background-color: transparent;
  padding: 10px 8px;
  color: var(--blue-600);
  font-size: 16px;
  width: 10vw;
  cursor: pointer;

  &:hover {
    background-color: var(--blue-50);
    transition: background-color 0.2s;
  }

  &:disabled {
    border-color: var(--grey-300);
    color: ${BASE_COLORS.gray};
    cursor: default;
    pointer-events: none;
  }
`;
interface IGenerateReportFooterProps {
  actionType: string;
  loadingText: string;
  onActionClick: () => Promise<void>;
  onCancel: () => void;
  isDisabled?: boolean;
}

export default function GenerateReportFooter({
  actionType,
  loadingText,
  onActionClick,
  onCancel,
  isDisabled,
}: IGenerateReportFooterProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleActionClick = async () => {
    setIsLoading(true);
    try {
      await onActionClick();
    } finally {
      setIsLoading(false);
    }
  };

  const buttonContent = isLoading ? (
    loadingText
  ) : actionType === 'download' ? (
    <>
      <MdOutlineFileDownload style={{ marginRight: '6px' }} />
      Download Report
    </>
  ) : (
    <>
      <MdOutlineEmail style={{ marginRight: '6px' }} />
      Send Email Report
    </>
  );

  return (
    <FooterContainer>
      <CancelButton type="button" onClick={onCancel} disabled={isLoading}>
        Cancel
      </CancelButton>
      <AsyncActionButton type="button" onClick={handleActionClick} disabled={isDisabled || isLoading}>
        {buttonContent}
      </AsyncActionButton>
    </FooterContainer>
  );
}
