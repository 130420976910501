import { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';

const ListContainer = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  gap: 20px;
  ${(props) => !props.vertical && 'align-items: center;'}
  ${(props) => !props.vertical && 'flex-wrap: wrap;'}
`;

const ListItem = styled.div<{ active?: boolean }>`
  overflow: hidden;
  border: ${(props) => (props.active ? '2px solid #3a50d1' : '2px solid transparent')};
  border-radius: 10px;
`;

interface IListComponentProps<T extends { id: number | string }> {
  data: T[];
  renderComponent: (data: T) => ReactElement<T>;
  vertical?: boolean;
  containerStyle?: CSSProperties | undefined;
  handleOnItemClick?: (data: T) => void;
  isItemSelected?: (id: T['id']) => boolean;
}

export default function ListComponent<T extends { id: number | string }>({
  data,
  containerStyle,
  vertical,
  renderComponent,
  isItemSelected,
  handleOnItemClick,
}: IListComponentProps<T>) {
  return (
    <ListContainer style={containerStyle} vertical={vertical}>
      {data.map((item, index) => (
        <ListItem key={index}>{renderComponent(item)}</ListItem>
      ))}
    </ListContainer>
  );
}
