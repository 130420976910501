import React from 'react';
import styled, { css } from 'styled-components';

interface SeverityBadgeProps {
  children: React.ReactNode;
  type?: 'low' | 'moderate' | 'high' | 'critical';
}

const SeverityBadge = ({ children, type = 'low' }: SeverityBadgeProps) => {
  return <StyledStatusBadge $type={type}>{children}</StyledStatusBadge>;
};

export default SeverityBadge;

type Badge = {
  $type: 'low' | 'moderate' | 'high' | 'critical';
};

const StyledStatusBadge = styled.div`
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ $type }: Badge) => Variant($type)};
  fontweight: 400;
  width: 100%;
`;

const Variant = (variant = 'enabled') =>
  ({
    low: css`
      background-color: var(--green-50);
      color: var(--green-900);
      border: 0px solid transparent;
    `,
    moderate: css`
      background-color: var(--grey-50);
      color: var(--grey-900);
      border: 0px solid transparent;
    `,
    high: css`
      background-color: var(--red-50);
      color: var(--red-900);
      border: 0px solid transparent;
    `,
    critical: css`
      background-color: #d81c2e1a;
      color: #d81c2e;
      border: 0px solid transparent;
    `,
  }[variant]);
