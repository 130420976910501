import { IconProps } from '../types';

export default function ListEditIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M14 4L2 4" stroke={fill} strokeLinecap="round" />
      <path d="M14 6.66675L2 6.66675" stroke={fill} strokeLinecap="round" />
      <path d="M7.33301 9.33325L1.99967 9.33325" stroke={fill} strokeLinecap="round" />
      <path d="M7.33301 12L1.99967 12" stroke={fill} strokeLinecap="round" />
      <path
        d="M11.963 8.55431L12.2102 8.30714C12.6197 7.89762 13.2837 7.89762 13.6932 8.30714C14.1027 8.71666 14.1027 9.38063 13.6932 9.79015L13.446 10.0373M11.963 8.55431C11.963 8.55431 11.9939 9.07954 12.4573 9.54298C12.9208 10.0064 13.446 10.0373 13.446 10.0373M11.963 8.55431L9.69068 10.8266C9.53677 10.9805 9.45981 11.0575 9.39363 11.1424C9.31556 11.2424 9.24863 11.3507 9.19402 11.4653C9.14772 11.5625 9.11331 11.6657 9.04448 11.8722L8.75281 12.7472M13.446 10.0373L11.1737 12.3096C11.0198 12.4636 10.9428 12.5405 10.858 12.6067C10.7579 12.6848 10.6496 12.7517 10.535 12.8063C10.4378 12.8526 10.3346 12.887 10.1281 12.9558L9.2531 13.2475M9.2531 13.2475L9.03922 13.3188C8.9376 13.3527 8.82557 13.3262 8.74983 13.2505C8.67409 13.1748 8.64764 13.0627 8.68151 12.9611L8.75281 12.7472M9.2531 13.2475L8.75281 12.7472"
        stroke={fill}
      />
    </svg>
  );
}
