import { Modal, ModalFuncProps, Spin, Steps, StepsProps } from 'antd';
import { endOfDay, format, startOfDay } from 'date-fns';
import styled from 'styled-components';

import { useGetModelItems } from '@app/api/table.api';
import { TRecommendationEventType, TRecommendationHistory } from '@app/types/recommendationHistory';
import { EventItem } from '../recommendations/RecommendationDetailsModalTabs/HistorySection';
import { formatDateToDDMMYYYY } from '@app/utils/utils';
import { TEventLogs } from '@app/pages/RecommendationHistory';
import { FONT_WEIGHT } from '@app/styles/themes/constants';
import { useMemo } from 'react';
import { CenterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { formatInTimeZone } from 'date-fns-tz';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.span`
  font-weight: ${FONT_WEIGHT.medium};
  font-size: 16px;
  color: var(--grey-950);
`;

const Value = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #272727;
`;

const GrayValue = styled.span`
  font-weight: ${FONT_WEIGHT.regular};
  font-size: 14px;
  color: var(--grey-500);
`;

const Indicator = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  flex-shrink: 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const GroupedSteps = styled(Steps)`
  .ant-steps-item-title {
    font-weight: bold;
  }

  .ant-steps-item-description {
    font-size: 14px;
    color: #6b6b6b;
  }
  .ant-steps-item-tail:after {
    background-color: var(--blue-100) !important;
  }
`;

const CustomDot = styled.span`
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--blue-200);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IManageAccountsProps extends ModalFuncProps {
  changeLogDate: string;
  events: TEventLogs;
  activeTab: string;
}

export default function EventDetailsModal({ changeLogDate, events, activeTab, ...modalProps }: IManageAccountsProps) {
  const changeField =
    activeTab == 'Configuration Changes'
      ? 'IsCompliant'
      : activeTab === 'Exemptions'
      ? 'IsExempted'
      : activeTab == 'Assignments'
      ? 'Assignment'
      : null;

  console.log('change log date', changeLogDate);

  const { data, isFetching } = useGetModelItems<TRecommendationHistory>({
    model: 'accountRecommendationHistory',
    queryParams: `$filter=${changeField ? "changeField eq '" + changeField + "' and " : ''}date ge ${startOfDay(
      changeLogDate,
    ).toISOString()} and date le ${endOfDay(changeLogDate).toISOString()}&orderby=date desc`,
  });

  const handleCancel = () => {
    modalProps.onCancel?.();
  };

  const groupedData = useMemo(() => {
    if (!data) return {};

    const grouped = data.items.reduce(
      (acc: Record<string, (TRecommendationEventType & { findings: string; validationMethod: string })[]>, item) => {
        const dateTimeKey = item.date;
        if (!acc[dateTimeKey]) acc[dateTimeKey] = [];

        const event: TRecommendationEventType & { findings: string; validationMethod: string } = {
          id: item.id || 0,
          change_type: item.changeType as 'regression' | 'improvement' | null,
          comment: '',
          date: item.date,
          user: item.changedBy || '',
          event_type: item.changeField as 'comment' | 'IsCompliant' | 'IsExempted',
          change_details: item.changeDetails,
          changedDescription: item.changeDescription,
          assigned_to: item.assignedTo || '',
          snapshot_type: parseInt(item.snapshotType, 10),
          findings: item.findings || '',
          validationMethod: item.validationMethod || '0',
        };

        acc[dateTimeKey].push(event);

        return acc;
      },
      {},
    );

    return Object.entries(grouped)
      .sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime())
      .reduce((sortedAcc, [dateTimeKey, events]) => {
        const formattedDate = formatDateToDDMMYYYY(new Date(dateTimeKey));
        if (!sortedAcc[formattedDate]) sortedAcc[formattedDate] = [];
        sortedAcc[formattedDate].push(...events);
        return sortedAcc;
      }, {} as Record<string, (TRecommendationEventType & { findings: string; validationMethod: string })[]>);
  }, [data]);

  const customDot: StepsProps['progressDot'] = () => <CustomDot />;

  return (
    <Modal
      title={
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', fontWeight: `${FONT_WEIGHT.medium}` }}>
          <span>Events Details</span>
          <span>{format(changeLogDate, 'dd/MM/yyyy')}</span>
        </div>
      }
      style={{
        minWidth: '65vw',
      }}
      {...modalProps}
      destroyOnClose={true}
      footer={null}
      onCancel={handleCancel}
    >
      <Container>
        <DetailContainer>
          <Label>Summary</Label>
          <Column>
            {events.totals.becameCompliantTotal > 0 && (
              <Row>
                <Indicator color="green" />
                <Value>{events.totals.becameCompliantTotal}</Value>
                <GrayValue> {events.labels.becameCompliantText}</GrayValue>
              </Row>
            )}
            {events.totals.becameNonCompliantTotal > 0 && (
              <Row>
                <Indicator color="red" />
                <Value>{events.totals.becameNonCompliantTotal}</Value>
                <GrayValue>{events.labels.becameNonCompliantText}</GrayValue>
              </Row>
            )}
            {events.totals.assignmentsTotal > 0 && (
              <Row>
                <Indicator color="orange" />
                <Value>{events.totals.assignmentsTotal}</Value>
                <GrayValue>{events.labels.assignmentsText}</GrayValue>
              </Row>
            )}
            {events.totals.markedExemptedTotal > 0 && (
              <Row>
                <Indicator color="orange" />
                <Value>{events.totals.markedExemptedTotal}</Value>
                <GrayValue>{events.labels.markedExemptedText}</GrayValue>
              </Row>
            )}
            {events.totals.markedNonExemptedTotal > 0 && (
              <Row>
                <Indicator color="orange" />
                <Value>{events.totals.markedNonExemptedTotal}</Value>
                <GrayValue>{events.labels.markedUnexemptedText}</GrayValue>
              </Row>
            )}
          </Column>
        </DetailContainer>
        {isFetching ? (
          <CenterContainer>
            <Spin />
          </CenterContainer>
        ) : (
          <DetailContainer>
            <Label>Details</Label>
            {Object.keys(groupedData).length > 0 ? (
              Object.entries(groupedData).map(([date, events], groupIndex) => {
                return (
                  <div key={groupIndex} style={{ height: '50vh', overflowY: 'auto' }}>
                    <GroupedSteps
                      direction="vertical"
                      progressDot={customDot}
                      status="finish"
                      items={events.map((event, eventIndex) => ({
                        key: event.id || `${event.date}-${event.event_type}-${eventIndex}`,
                        title: (
                          <span style={{ fontSize: '0.8rem', color: `#6b6b6b`, fontWeight: '500' }}>
                            {format(new Date(event.date), 'h:mm a')}
                          </span>
                        ),
                        status: 'finish',
                        description: (
                          <EventItem
                            key={event.id}
                            eventType={event}
                            accountRecommendationId={''}
                            recommendationValidationMethod={parseInt(event.validationMethod)}
                            commentReadOnly={true}
                            showTimeOnly={true}
                            title={`Recommendation "${event.findings}"`}
                          />
                        ),
                      }))}
                    />
                  </div>
                );
              })
            ) : (
              <Value>No data recorded for this recommendation so far.</Value>
            )}
          </DetailContainer>
        )}
      </Container>
    </Modal>
  );
}
