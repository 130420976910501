import { useGetComplianceState } from '@app/api/statistics.api';
import { getEnumValue } from '@app/services/enum.service';
import { Tooltip } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface RecommendationContainerProps {
  gradientStart: string;
  gradientEnd: string;
}

const ParentDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1440px) {
    flex-wrap: wrap;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const PrimaryDataContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: row;
  color: black;
  width: 50%;

  @media (max-width: 1440px) {
    width: 45%;
    gap: 8px;
  }

  @media (max-width: 1024px) {
    gap: 1rem;
  }
`;

const RecommendationContainer = styled.div<RecommendationContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: ${({ gradientStart, gradientEnd }) => `linear-gradient(to right, ${gradientStart}, ${gradientEnd})`};
  padding: 10px;
  border-radius: 6px;
  width: 49%;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transform: translateY(-2px);
  }

  @media (max-width: 1440px) {
    padding: 0.8rem;
    width: 49%;
  }

  @media (max-width: 1024px) {
    padding: 0.8rem;
    gap: 1.2rem;
  }
`;

const PrimaryText = styled.div`
  display: flex;
  font-size: 2rem;
  font-weight: 700;
  color: black;

  @media (max-width: 1440px) {
    font-size: 1.5;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const PrimaryTextSmall = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  color: black;

  .bold-text {
    font-weight: bold;
  }

  .gray-text {
    font-weight: normal;
    color: gray;
  }

  @media (max-width: 1440px) {
    font-size: 1.5rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

const SecondaryText = styled.div`
  font-size: 1.2rem;
  font-weight: 500;

  @media (max-width: 1440px) {
    font-size: 1.1rem;
  }

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const SecondaryTextSmall = styled.div`
  display: flex;
  font-size: 1.1rem;
  font-weight: 500;

  @media (max-width: 1440px) {
    font-size: 1rem;
    justify-content: left;
    align-items: center;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

const CenteredTertiaryText = styled.div`
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  color: black;

  @media (max-width: 1440px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1024px) {
    font-size: 0.7rem;
  }
`;

const InfoText = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  color: gray;

  @media (max-width: 1440px) {
    font-size: 12px;
  }

  @media (max-width: 1024px) {
    font-size: 0.8rem;
  }
`;

export function ComplianceState() {
  const { data: complianceStateData } = useGetComplianceState();
  const navigate = useNavigate();

  const data = useMemo(() => {
    if (complianceStateData) {
      return complianceStateData;
    }

    return {
      compliantRecommendationPercentage: 0,
      totalNumOfNonCompliantRecommendations: 0,
      manualRecommendations: {
        nonCompliant: 0,
        compliant: 0,
        na: 0,
        total: 0,
      },
      automaticRecommendations: {
        nonCompliant: 0,
        compliant: 0,
        total: 0,
      },
    };
  }, [complianceStateData]);

  return (
    <ParentDiv>
      <RowContainer style={{ marginTop: '8px' }}>
        <PrimaryDataContainer>
          <PrimaryText>{`${(data.compliantRecommendationPercentage ?? 0).toFixed(2)}%`}</PrimaryText>
          <CenteredTertiaryText>Compliant recommendation</CenteredTertiaryText>
        </PrimaryDataContainer>
        <PrimaryDataContainer>
          <PrimaryText>{data.totalNumOfNonCompliantRecommendations}</PrimaryText>
          <CenteredTertiaryText>Total Non-Compliant recommendations</CenteredTertiaryText>
        </PrimaryDataContainer>
      </RowContainer>

      <SecondaryTextSmall> Non-compliant recommendation </SecondaryTextSmall>

      <RowContainer>
        <Tooltip
          title="Click to see all manual recommendations"
          placement="top"
          color="black"
          overlayStyle={{ maxWidth: '100%' }}
          overlayInnerStyle={{ fontSize: '13px' }}
        >
          <RecommendationContainer
            gradientStart="#f1fcf4"
            gradientEnd="#fafffa"
            onClick={() =>
              navigate(
                `/recommendations?$filter=(validationMethod eq ${getEnumValue(
                  'RecommendationValidationMethod',
                  'Manual',
                )})`,
              )
            }
          >
            <InfoText> MANUAL </InfoText>
            <PrimaryTextSmall>
              <span className="bold-text">{data.manualRecommendations.nonCompliant}</span>
              <span className="bold-text">/</span>
              <span className="gray-text">{data.manualRecommendations.total}</span>
            </PrimaryTextSmall>
            <RowContainer style={{ flexWrap: 'wrap' }}>
              <ColumnContainer>
                <SecondaryText> {data.manualRecommendations.na} </SecondaryText>
                <InfoText> N/A </InfoText>
              </ColumnContainer>
              <ColumnContainer>
                <SecondaryText> {data.manualRecommendations.compliant} </SecondaryText>
                <InfoText> Compliant </InfoText>
              </ColumnContainer>
              <ColumnContainer>
                <SecondaryText> {data.manualRecommendations.nonCompliant} </SecondaryText>
                <InfoText> Non-Compliant </InfoText>
              </ColumnContainer>
            </RowContainer>
          </RecommendationContainer>
        </Tooltip>

        <Tooltip
          title="Click to see all automatic recommendations"
          placement="top"
          color="black"
          overlayStyle={{ maxWidth: '100%' }}
          overlayInnerStyle={{ fontSize: '13px' }}
        >
          <RecommendationContainer
            gradientStart="#eef0fd"
            gradientEnd="#fbfcff"
            onClick={() =>
              navigate(
                `/recommendations?$filter=(validationMethod eq ${getEnumValue(
                  'RecommendationValidationMethod',
                  'Automatic',
                )})`,
              )
            }
          >
            <InfoText> AUTOMATIC </InfoText>
            <PrimaryTextSmall>
              <span className="bold-text">{data.automaticRecommendations.nonCompliant}</span>
              <span className="bold-text">/</span>
              <span className="gray-text">{data.automaticRecommendations.total}</span>
            </PrimaryTextSmall>
            <RowContainer>
              <ColumnContainer>
                <SecondaryText> {data.automaticRecommendations.compliant} </SecondaryText>
                <InfoText> Compliant </InfoText>
              </ColumnContainer>
              <ColumnContainer>
                <SecondaryText> {data.automaticRecommendations.nonCompliant} </SecondaryText>
                <InfoText> Non-Compliant </InfoText>
              </ColumnContainer>
            </RowContainer>
          </RecommendationContainer>
        </Tooltip>
      </RowContainer>
    </ParentDiv>
  );
}
