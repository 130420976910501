import { useMemo, useState } from 'react';
import { Radio, RadioChangeEvent, Spin } from 'antd';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { TrendByProduct } from '@app/types/dashboardData';
import { format } from 'date-fns';
import { camelCaseToWords } from '@app/utils/utils';
import { NoData } from '../common/NoData';
import { SubTitle } from './ChartGroup';
import { DashboardCenterContainer } from '../common/BaseLayout/BaseLayout.styled';
import CustomTooltip from './CustomTooltip';

interface ILineChartCardProps {
  data?: TrendByProduct;
  isLoading: boolean;
}

const colors = ['#30AE5B', '#FFB156', '#FFE456', '#3B50D0', '#D4DAFF'];

export default function ByProductLineChart({ data, isLoading }: ILineChartCardProps) {
  const [radioValue, setRadioValue] = useState(1);

  const products = useMemo(() => {
    if (data) {
      const keys: Record<string, boolean> = {};
      for (const sc of data.standardScore) {
        for (const product in sc.products) {
          keys[product] = true;
        }
      }
      return Object.keys(keys).sort();
    }
    return [];
  }, [data]);

  const lineChartData = useMemo(() => {
    if (data) {
      const sourceData = radioValue === 1 ? data.standardScore : data.customScore;

      return sourceData.map((entry) => {
        const productKeys = Object.keys(entry.products).map((key) => ({
          [key]: entry.products[key],
        }));

        return {
          name: format(new Date(entry.date), 'MMM dd'),
          ...Object.assign({}, ...productKeys),
        };
      });
    }
    return [];
  }, [data, radioValue]);

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '15px',
          width: '100%',
          marginTop: '20px',
        }}
      >
        {/* <MdOutlineKeyboardArrowLeft /> */}
        {payload.map((entry: any, index: number) => (
          <div
            key={`item-${index}`}
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              fontSize: '0.75rem',
              fontWeight: 400,
              color: '#303030',
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ width: '8px', height: '8px', background: entry.color, borderRadius: '8px' }} />
            {camelCaseToWords(entry.dataKey).trim()}
          </div>
        ))}
        {/* <MdOutlineKeyboardArrowRight /> */}
      </div>
    );
  };

  const onChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '2lh',
          marginBottom: '8px',
          marginTop: '40px',
        }}
      >
        <SubTitle>By Product</SubTitle>
        <div style={{ display: 'flex', fontSize: '.2rem' }}>
          <Radio.Group onChange={onChange} value={radioValue} size="small">
            <Radio value={1} style={{ fontSize: '0.875rem', fontWeight: 400, color: '#272727' }}>
              Baseline
            </Radio>
            <Radio value={2} style={{ fontSize: '0.875rem', fontWeight: 400, color: '#272727' }}>
              Custom
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <div style={{ height: '400px', width: '100%' }}>
        {isLoading ? (
          <DashboardCenterContainer>
            <Spin />
          </DashboardCenterContainer>
        ) : !lineChartData || lineChartData.length === 0 ? (
          <NoData />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={lineChartData}>
              <XAxis dataKey="name" dy={10} axisLine={false} tickLine={false} fontSize={'0.625rem'} />
              <YAxis
                type="number"
                tickFormatter={(tick) => `${tick}%`}
                tickLine={false}
                axisLine={false}
                fontSize={'0.625rem'}
              />
              <Legend iconType="circle" content={renderLegend} />
              <Tooltip />
              {products.map((product, index: number) => (
                <Line
                  key={index}
                  type="basis"
                  dataKey={product}
                  strokeWidth={3}
                  stroke={colors[index % colors.length]}
                  dot={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}
