import React from 'react';
import styled from 'styled-components';
import { TimeFrameOptions } from '@app/utils/utils';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { useTimePeriodDropdown } from './useTimePeriodDropdown';

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 110px;
`;

const SelectedOption = styled.div`
  padding: 2px 6px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: white;
  color: #6455d6;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;

  li {
    padding: 6px 8px;
    font-size: 14px;
    color: gray;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f0f0f0;
      color: black;
    }

    &.selected {
      background-color: #f0f0f0;
      color: black;
    }
  }
`;

const timePeriodOptions: { label: string; value: TimeFrameOptions }[] = [
  { label: 'This Week', value: 'last7days' },
  { label: 'This Month', value: 'last30days' },
  { label: 'This Year', value: 'last12months' },
];

type TimePeriodDropdownProps = {
  onTimePeriodSelect: (option: TimeFrameOptions) => void;
};

export const TimePeriodDropdown: React.FC<TimePeriodDropdownProps> = ({ onTimePeriodSelect }) => {
  const { dropdownRef, isOpen, toggleDropdown, handleOptionClick, selectedOption } = useTimePeriodDropdown();

  return (
    <DropdownContainer ref={dropdownRef}>
      <SelectedOption onClick={toggleDropdown}>
        {timePeriodOptions.find((opt) => opt.value === selectedOption)?.label}
        {isOpen ? <RiArrowDropUpLine size={20} /> : <RiArrowDropDownLine size={20} />}
      </SelectedOption>
      {isOpen && (
        <OptionsList>
          {timePeriodOptions.map((option) => (
            <li
              key={option.value}
              className={option.value === selectedOption ? 'selected' : ''}
              onClick={() => {
                handleOptionClick(option.value);
                onTimePeriodSelect(option.value);
              }}
            >
              {option.label}
            </li>
          ))}
        </OptionsList>
      )}
    </DropdownContainer>
  );
};
