import { ProfileDropdown } from '@app/components/header/components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { getEnumValue } from '@app/services/enum.service';
import { IApplicationState } from '@app/store/slices/appSlice';
import { UserState } from '@app/store/slices/userSlice';
import { RootState } from '@app/store/store';
import { isScreenAllowed } from '@app/utils/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { SidebarNavigationItem, useSideBarNavigation } from '../sidebarNavigation';
import * as S from './SiderMenu.styles';

interface SiderContentProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderMenu: React.FC<SiderContentProps> = ({ isCollapsed, setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const appState = useSelector<RootState>((state) => state.app) as IApplicationState;
  const userState = useSelector<RootState>((state) => state.user) as UserState;

  const navItems = useSideBarNavigation();

  const selectedKeys = useMemo(() => {
    const keys = [];
    if (location.pathname.includes('dashboard')) keys.push('Home');

    navItems.forEach((item) => {
      if (item.children && item.children.length > 0) {
        item.children.forEach((childItem) => {
          if (childItem.url && location.pathname.includes(childItem.url.split('?')[0])) {
            keys.push(childItem.key);
          }
        });
      } else {
        if (item.url && location.pathname.includes(item.url.split('?')[0])) {
          keys.push(item.key);
        }
      }
    });

    return keys;
  }, [location, appState]);

  const items = useMemo(() => {
    if (
      userState.user?.role === getEnumValue('UserRole', 'SuperAdmin') ||
      userState.user?.role === getEnumValue('UserRole', 'Vendor')
    ) {
      return navItems
        .filter((navItem) => shouldShowScreen(navItem, undefined))
        .map((nav) => {
          const isSubMenu = nav.children?.length;
          return {
            key: nav.key,
            title: t(nav.title),
            label:
              isSubMenu && nav.url == null ? (
                t(nav.title)
              ) : (
                <Link to={nav.url || ''} target={nav.newTab ? '__blank' : ''}>
                  {t(nav.title)}
                </Link>
              ),
            icon: nav.icon,
            children:
              isSubMenu &&
              nav.children &&
              nav.children
                .filter((navItem) => shouldShowScreen(navItem, undefined))
                .map((childNav) => ({
                  key: childNav.key,
                  label: (
                    <Link to={childNav.url || ''} target={nav.newTab ? '__blank' : ''}>
                      {t(childNav.title)}
                    </Link>
                  ),
                  title: t(childNav.title),
                })),
          };
        });
    }

    const sidebarItemGroups = appState.appValues?.DashboardScreensGroup as { label: string; value: number }[];

    const groupedItems = sidebarItemGroups.map((group) => {
      return {
        key: group.value,
        label: isCollapsed ? '' : <S.Title>{group.label.toUpperCase()}</S.Title>,
        type: 'group',
        children: navItems
          .filter((navItem) => shouldShowScreen(navItem, group.value))
          .map((nav) => {
            const isSubMenu = nav.children?.length;
            return {
              key: nav.key,
              title: t(nav.title),
              label:
                isSubMenu && nav.url == null ? (
                  t(nav.title)
                ) : (
                  <Link to={nav.url || ''} target={nav.newTab ? '__blank' : ''}>
                    {t(nav.title)}
                  </Link>
                ),
              icon: nav.icon,
              children:
                nav.children &&
                nav.children
                  .filter((navItem) => shouldShowScreen(navItem, undefined))
                  .map((childNav) => ({
                    key: childNav.key,
                    label: (
                      <Link to={childNav.url || ''} target={nav.newTab ? '__blank' : ''}>
                        {t(childNav.title)}
                      </Link>
                    ),
                    title: t(childNav.title),
                  })),
            };
          }),
      };
    });

    return groupedItems;
  }, [isCollapsed, navItems, userState]);

  const currentMenuItem = navItems
    .reduce(
      (result: SidebarNavigationItem[], current) =>
        result.concat(current.children && current.children.length > 0 ? current.children : current),
      [],
    )
    .find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = navItems.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  function shouldShowScreen(nav: SidebarNavigationItem, sidebarItemGroup?: number): boolean {
    const isSubMenu = nav.children?.length;
    if (isSubMenu && !sidebarItemGroup) {
      return true;
    }
    if (appState.appValues == null && appState.rolesAndScreens == null) {
      return false;
    }
    const dashboardScreens = (appState.appValues as any)['DashboardScreens'];
    const rolesAndScreens = appState.rolesAndScreens || [];
    const userRole = userState.user?.role || 0;

    return nav.allow || isScreenAllowed(nav.key, dashboardScreens, rolesAndScreens, userRole, sidebarItemGroup);
  }

  return (
    <S.SiderContainer>
      <S.MenuContainer>
        <S.Menu
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          selectedKeys={selectedKeys}
          items={items}
        />
      </S.MenuContainer>
      <ProfileDropdown isCollapsed={isCollapsed} />
    </S.SiderContainer>
  );
};

export default SiderMenu;
