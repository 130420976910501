import { useState, useEffect } from 'react';

const DEFAULT_DEBOUNCE_TIMEOUT = 300;

interface UseSearchComponentProps {
  onSearch?: (query: string) => void;
  debounceTimeout?: number;
}

export const useSearchComponent = ({
  onSearch,
  debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT,
}: UseSearchComponentProps) => {
  const [query, setQuery] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState<string>('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, debounceTimeout);

    return () => clearTimeout(handler);
  }, [query, debounceTimeout]);

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedQuery);
    }
  }, [debouncedQuery, onSearch]);

  return {
    query,
    debouncedQuery,
    onChange: setQuery,
  };
};
