import { Fragment, useEffect, useMemo, useState } from 'react';
import { Switch } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from '@app/store/store';
import { TEnumEntity } from '@app/types/enumEntity';
import { useGetAccountProducts, useUpdateSettings } from '@app/api/account.api';

import CreateAlertButton from '../recommendations/CreateAlertButton';
import { useAppSelector } from '@app/hooks/reduxHooks';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  background-color: #fafafa;
  padding: 16px;
  border: 1px solid var(--grey-100);
  border-radius: 4px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 33%;
  flex-shrink: 0;
  gap: 12px;
  margin-bottom: 20px;

  ${({ disabled }: { disabled?: boolean }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}

  @media (max-width: 768px) {
    flex-basis: 100%;
    justify-content: space-between;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--blue-500);
    border-color: var(--blue-500);
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #ffffff;
  }
`;

const FieldLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: red;
`;

const ProductsToggleForm = () => {
  const products = useSelector<RootState>((state) => state.app.products) as TEnumEntity[] | null;

  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);

  const { data: accountProducts, isSuccess } = useGetAccountProducts();
  const { mutateAsync: updateSettings, isLoading } = useUpdateSettings();

  const isSaveDisabled = useMemo(() => {
    if (!accountProducts) return true;

    return (
      JSON.stringify(selectedProductIds.sort((a, b) => a - b)) ===
      JSON.stringify(accountProducts.sort((a, b) => a.id - b.id).map((product) => product.id))
    );
  }, [selectedProductIds, accountProducts]);

  const setDefaultSelectedProducts = () => {
    if (!accountProducts) {
      setSelectedProductIds([]);
      return;
    }

    const selectedIds = accountProducts.map((ap) => ap.id) as number[];
    setSelectedProductIds(selectedIds);
  };

  const onToggle = (checked: boolean, productId: number) => {
    if (!checked) {
      const newSelectedProductIds = Array.from(new Set(selectedProductIds.filter((id) => id !== productId)));
      setSelectedProductIds(newSelectedProductIds);
      return;
    }

    const newSelectedProductIds = Array.from(new Set([...selectedProductIds, productId]));
    setSelectedProductIds(newSelectedProductIds);
  };

  const saveChanges = async () => {
    await updateSettings({
      productIds: selectedProductIds,
    });
  };

  useEffect(() => {
    if (!isSuccess) return;

    setDefaultSelectedProducts();
  }, [isSuccess, products]);

  const isScopingEnabled = useAppSelector((state) =>
    Boolean(state.user.user?.account?.subscriptionType?.recommendationScopingEnabled),
  );

  return (
    <Fragment>
      {isScopingEnabled && <FieldLabel>Premium feature - available for premium account only.</FieldLabel>}
      <ProductListContainer>
        {products?.map((product, index) => (
          <Fragment key={index}>
            <ProductContainer disabled={isScopingEnabled}>
              <CustomCheckbox
                checked={selectedProductIds.includes(product.id)}
                onChange={(e) => onToggle(e.target.checked, product.id)}
              />

              <span>{product.displayName}</span>
            </ProductContainer>
          </Fragment>
        ))}
      </ProductListContainer>
      <ButtonContainer>
        <CreateAlertButton title="Save" disabled={isSaveDisabled || isLoading} onPress={saveChanges} />
      </ButtonContainer>
    </Fragment>
  );
};

export { ProductsToggleForm };
