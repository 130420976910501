import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface.d';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from '../../constants';
import { TTableFilterContext, useTableFilterContext } from '../../store/context';
import { TDataCol, TDateTimeFilter } from '../../types';

export function DateTimeFilter<T extends Record<string, unknown>>(props: TDataCol<T>) {
  const { filterValuesMap } = useTableFilterContext<TTableFilterContext<T>>();

  const [selectedRangeValues, setSelectedRangeValues] = useState<RangeValue<moment.Moment> | null>(null);

  const dateFilterMapValue = useMemo(() => {
    return filterValuesMap?.get(props.dataIndex as string) || '';
  }, [filterValuesMap]);

  useEffect(() => {
    if (!dateFilterMapValue) {
      setSelectedRangeValues(null);
      return;
    }

    const parsedDateFilterMapValue = (JSON.parse(dateFilterMapValue) as string[])?.map((val) =>
      moment(val.replaceAll("'", '')),
    ) as RangeValue<Moment>;

    setSelectedRangeValues(parsedDateFilterMapValue);
  }, [dateFilterMapValue]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDatePickerChange = (value: RangeValue<moment.Moment>, dateString: [string, string]) => {
    if (!value) {
      setSelectedRangeValues(null);
      filterValuesMap?.delete(props.dataIndex as string);
      return;
    }

    setSelectedRangeValues(value);
    filterValuesMap?.set(props.dataIndex as string, JSON.stringify(value));
  };

  return (
    <div>
      <DatePicker.RangePicker
        value={selectedRangeValues}
        {...((props as TDateTimeFilter).showTime && { showTime: { format: 'HH:mm' } })}
        format={(props as TDateTimeFilter).showTime ? DEFAULT_DATE_TIME_FORMAT : DEFAULT_DATE_FORMAT}
        onChange={onDatePickerChange}
      />
    </div>
  );
}
