import { useGetAccountStatusInfo } from '@app/api/account.api';
import { useEffect, useState } from 'react';
import { TAccountStatusInfo } from '@app/types/accountStatusInfo';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';

export function TopbarTitle() {
  const pageTitle = useAppSelector((state) => state.app.pageTitle);
  const [accountStatusInfo, setAccountStatusInfo] = useState<TAccountStatusInfo>();
  const userRole = useAppSelector((state) => state.user.user?.role);

  const { data, isSuccess, isFetching, refetch } = useGetAccountStatusInfo(
    !!userRole && userRole !== getEnumValue('UserRole', 'SuperAdmin'),
  );

  useEffect(() => {
    if (isSuccess) {
      setAccountStatusInfo(data);
    }
  }, [data, isSuccess]);

  if (
    userRole &&
    [getEnumValue('UserRole', 'SuperAdmin'), getEnumValue('UserRole', 'Vendor')].includes(userRole) &&
    !isFetching
  ) {
    return (
      <span
        style={{
          fontWeight: 500,
          fontSize: '28px',
        }}
      >
        {userRole === getEnumValue('UserRole', 'SuperAdmin')
          ? 'System Admin Management'
          : 'Resellers Management Dashboard'}
      </span>
    );
  }

  return (
    <span
      style={{
        fontWeight: 500,
        fontFamily: 'Raleway',
        fontSize: '28px',
        color: 'black',
      }}
    >
      {pageTitle || 'Dashboard'}
    </span>
  );
}
