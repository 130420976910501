import { useState } from 'react';
import styled from 'styled-components';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import InviteUsersModal from './InviteUsersModal';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { TGroupExtended } from '@app/types/groupExtended';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';

export const StyledBaseButton = styled(BaseButton)`
  border-radius: 0.625rem;
  height: 2.75rem;
  border: none;
  background-color: #30af5b;
  color: white;

  &:hover {
    background-color: #30af5b;
    color: white;
  }

  &:focus {
    background-color: #30af5b;
    color: white;
  }
`;

interface IInviteUsersComponent {
  onInviteSuccess?: () => void;
  groups: TGroupExtended[];
}

const InviteUsersComponent = ({ onInviteSuccess, groups }: IInviteUsersComponent) => {
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);

  return (
    <>
      <BaseCTAButton onClick={() => setIsInviteUsersModalOpen(true)}>Invite Users</BaseCTAButton>
      <InviteUsersModal
        open={isInviteUsersModalOpen}
        onCancel={() => setIsInviteUsersModalOpen(false)}
        onInviteSuccess={onInviteSuccess}
        groups={groups}
      />
    </>
  );
};

export default InviteUsersComponent;
