import { useState, useEffect, useRef, useCallback } from 'react';
import { TimeFrameOptions } from '@app/utils/utils';

export const useTimePeriodDropdown = (defaultOption: TimeFrameOptions = 'last7days') => {
  const [selectedOption, setSelectedOption] = useState<TimeFrameOptions>(defaultOption);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const calculateDateRange = useCallback((timePeriod: TimeFrameOptions) => {
    const now = new Date();
    let from: Date;
    const to = now;

    switch (timePeriod) {
      case 'last7days':
        from = new Date(now.setDate(now.getDate() - 7));
        break;
      case 'last30days':
        from = new Date(now.setDate(now.getDate() - 30));
        break;
      case 'last12months':
        from = new Date(now.setFullYear(now.getFullYear() - 1));
        break;
      default:
        throw new Error(`Unsupported time period: ${timePeriod}`);
    }

    return { from, to };
  }, []);

  const handleOptionClick = (option: TimeFrameOptions) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dateRange = calculateDateRange(selectedOption);

  return {
    dropdownRef,
    isOpen,
    toggleDropdown,
    handleOptionClick,
    selectedOption,
    setSelectedOption,
    dateRange,
  };
};
