import { HorizontalAlignedContainer, VerticalContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TRecommendationEventType } from '@app/types/recommendationHistory';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { COMPACT_TABS_COLORS, FONT_WEIGHT, RECOMMENDATION_DETAILS_MODAL_COLORS } from '@app/styles/themes/constants';
import styled from 'styled-components';

const CommentBlock = styled.div`
  padding: 8px;
  background-color: ${COMPACT_TABS_COLORS.lightGray};
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
  border-radius: 6px;
`;

const UserText = styled.span`
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.blueLink};
`;

const Badge = styled.div`
  font-weight: 400;
  font-size: 12px;
  background-color: #fff7ed;
  color: #7e2f10;

  width: fit-content;
  height: fit-content;
  padding: 2px 8px;
  border-radius: 6px;
`;

interface IExemptionChanges {
  event: TRecommendationEventType;
  title?: string;
  showTimeOnly?: boolean;
}

const ExemptionChanges = ({ event, title, showTimeOnly }: IExemptionChanges) => {
  const { change_type, user, comment, date } = event;

  const exemptionText = change_type === 'improvement' ? 'Un-Exempted' : 'Exempted';
  const baseMessage = `This recommendation has been marked as ${exemptionText.toLowerCase()} by `;
  const finalMessage = !comment || comment?.length === 0 ? '.' : ' with notes:';

  return (
    <VerticalContainer style={{ gap: '4px', marginBottom: '12px' }}>
      <HorizontalAlignedContainer>
        <span style={{ fontSize: '14px', fontWeight: '500', color: `${COMPACT_TABS_COLORS.darkGray}` }}>
          {`${title ? title : 'Recommendation'} ${exemptionText}`}
        </span>
      </HorizontalAlignedContainer>
      <span style={{ fontSize: '14px', color: 'var(--grey-700)', fontWeight: `${FONT_WEIGHT.regular}` }}>
        {baseMessage}
        <UserText>{user}</UserText>
        {finalMessage}
      </span>
      <Badge>{exemptionText}</Badge>

      {!!comment && comment?.length !== 0 && (
        <CommentBlock>
          <HtmlEditor initialValue={comment} readOnly />
        </CommentBlock>
      )}
    </VerticalContainer>
  );
};

export { ExemptionChanges };
