import { TList } from '@app/types/generalTypes';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

const DEFAULT_ITEMS_PER_PAGE = 10;

interface IUseLoadMoreProps<T, K> {
  fetchFn: (top: number, skip: number) => Promise<TList<T>>;
  queryKey: string;
  itemsPerPage?: number;
  mutateItems?: (data: TList<T>) => TList<K>;
}

/**
 * @description A function that loads more data on demand. This returns the top and skip filters instead of page numbers so that it would be compatible on an odata query.
 * @param fetchFn A function that is called to fetch the data desired for this hook.
 * @param itemsPerPage A number that is used to set the limit of the query ($top filter on the odata query) and also used to derive the next skip filter number.
 */
export function useLoadMore<T, K>({
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
  queryKey,
  fetchFn,
  mutateItems,
}: IUseLoadMoreProps<T, K>) {
  const [accumulatedData, setAccumulatedData] = useState<T[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  // reset to first page
  const reset = () => {
    setAccumulatedData([]);
    setPage(0);
    setHasMore(true);
  };

  const skip = useMemo(() => {
    return page * itemsPerPage;
  }, [page, itemsPerPage]);

  const { isLoading, isError, error, data, isFetching, refetch, isRefetching } = useQuery({
    queryKey: [queryKey, page],
    queryFn: () => fetchFn(itemsPerPage, skip),
    onSuccess: (data) => {
      const newData = accumulatedData.concat(data.items);

      // remove duplicates
      const noDuplicatesData = Array.from(
        new Set(accumulatedData.concat(data.items).map((item: any) => item.id as string)),
      ).map((id) => newData.find((d: any) => d.id === id)!);
      setAccumulatedData(noDuplicatesData);
      setHasMore(data.count > noDuplicatesData.length);
    },
    // select: (data) => {
    //   //   if (!mutateItems) {
    //   //     return data;
    //   //   }

    //   //   const newItems = mutateItems(data);
    //   //   return newItems;
    //   return { count: data.count, items: previousData.current?.items.concat(data.items) };
    // },
  });

  const loadMoreData = () => {
    if (!!data && data?.count > accumulatedData.length && !isFetching && !isRefetching) {
      setPage((oldPage) => oldPage + 1);
    }
  };

  return {
    isLoading,
    isFetching,
    isError,
    error,
    data: accumulatedData,
    loadMoreData,
    reset,
    refetch,
    isRefetching,
    hasMore,
  };
}
