import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { CiCirclePlus } from 'react-icons/ci';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';
import useDebounce from '@app/hooks/useDebounce';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const ScrollableEmailsWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const EmailInputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--grey-200);
  border-radius: 6px;
  padding: 8px 12px;
  width: 100%;
`;

const EmailInput = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  background-color: transparent;
  color: #333;
  font-size: 14px;

  &::placeholder {
    color: #bbb;
  }
`;

const AddEmailButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  border: none;
  background: none;
  color: var(--blue-500);
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  &:hover svg {
    color: red;
  }
`;

const DeleteIcon = styled(DeleteOutlined)`
  color: red;
  font-size: 16px;
`;

const CloseIcon = styled(CloseCircleOutlined)`
  color: #aaa;
  font-size: 14px;

  &:hover {
    color: #000;
  }
`;

interface EmailRecipientsProps {
  defaultEmailList?: string[];
  onAddEmail?: (emailList: string[]) => void;
  handleAddEmail?: (email: string) => void;
  removeEmail: (index: number) => void;
  clearEmails?: () => void;
  emails: string[];
}

export default function EmailRecipients({
  onAddEmail,
  handleAddEmail,
  removeEmail,
  clearEmails,
  emails,
}: EmailRecipientsProps) {
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState('');

  const debouncedEmail = useDebounce(newEmail, 500);

  const validateEmail = (email: string) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const onAddNewEmail = useCallback(() => {
    const trimmed = newEmail.trim();
    if (!trimmed) {
      setError('Email field cannot be empty.');
      return;
    }
    if (!validateEmail(trimmed)) {
      setError('Please enter a valid email address.');
      return;
    }
    if (emails.includes(trimmed)) {
      setError('This email is already added.');
      return;
    }

    setNewEmail('');
    setError('');
    handleAddEmail?.(trimmed);
  }, [newEmail, emails, handleAddEmail]);

  useEffect(() => {
    const trimmed = debouncedEmail.trim();
    if (!trimmed) {
      setError('');
      return;
    }
    if (!validateEmail(trimmed)) {
      setError('Please enter a valid email address.');
    } else if (emails.includes(trimmed)) {
      setError('This email is already added.');
    } else {
      setError('');
    }
  }, [debouncedEmail, emails]);

  // If user hits Enter
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onAddNewEmail();
    }
  };

  // Update an existing email
  const onUpdateEmail = (index: number, updatedEmail: string) => {
    const newEmails = [...emails];
    newEmails[index] = updatedEmail;
    onAddEmail?.(newEmails);
  };

  return (
    <Container>
      {/* Scrollable list of existing emails */}
      <ScrollableEmailsWrapper>
        {emails.map((email, index) => (
          <RowContainer key={index}>
            <EmailInputWrapper style={{ backgroundColor: 'var(--grey-50)' }}>
              <EmailInput value={email} readOnly onChange={(e) => onUpdateEmail(index, e.target.value)} />
              <IconButton type="button" onClick={() => removeEmail(index)}>
                <DeleteIcon />
              </IconButton>
            </EmailInputWrapper>
          </RowContainer>
        ))}
        {emails.length > 0 && <Divider style={{ margin: '10px' }} />}
      </ScrollableEmailsWrapper>

      {/* New email entry field */}
      <RowContainer>
        <EmailInputWrapper>
          <EmailInput
            placeholder="Enter recipient email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {newEmail && (
            <IconButton type="button" onClick={() => setNewEmail('')}>
              <CloseIcon />
            </IconButton>
          )}
        </EmailInputWrapper>
      </RowContainer>

      {/* Error display */}
      {error && <ErrorMessage>{error}</ErrorMessage>}

      {/* Footer Buttons */}
      <HeaderContainer>
        <AddEmailButton type="button" onClick={onAddNewEmail}>
          <CiCirclePlus size="1.5rem" />
          <span style={{ fontSize: '0.9rem' }}>Add Email</span>
        </AddEmailButton>

        {emails.length > 0 && (
          <AddEmailButton type="button" onClick={clearEmails} style={{ color: 'red' }}>
            <DeleteOutlined />
            <span style={{ fontSize: '0.9rem' }}>Clear All</span>
          </AddEmailButton>
        )}
      </HeaderContainer>
    </Container>
  );
}
