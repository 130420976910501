import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HorizontalAlignedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpaceGrower = styled.div`
  flex-grow: 1;
`;

export const DashboardCenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

export const ModalFooterContainer = styled(HorizontalAlignedContainer)`
  gap: 12px;
  justify-content: end;
`;

export const BaseModalTitle = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--grey-950);
  font-family: Raleway;
`;

export const BaseModalCloseButton = styled(CloseOutlined)`
  height: 1rem;
  width: 1rem;
  color: var(--grey-1000);
`;
