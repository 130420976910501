import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useAssignRecommendation } from '@app/api/recommendation.api';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { Select, Spin } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useGetModelItems } from '@app/api/table.api';
import { getEnumValue } from '@app/services/enum.service';
import { TUsers } from '@app/types/users';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { useGetManyRecommendationExtended } from './hooks/useGetRecommendationExtended';
import { BaseModalTitle, ModalFooterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { BaseCancelButton, BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { SearchOutlined } from '@ant-design/icons';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalMessage = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: var(--grey-600);
`;

const FieldLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--grey-950);
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const ErrorMessage = styled.span`
  color: var(--red-800);
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 8px;
`;

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--grey-300);
`;

export type TAssignRecommendationForm = {
  assignedToId: number;
  notes: string;
};

interface IAssignRecommendationModalProps extends ModalFuncProps {
  recommendationIds: number[];
  recommendations?: TAccountRecommendationExtended[];
}

export default function AssignRecommendationModal({
  recommendationIds,
  recommendations,
  ...modalProps
}: IAssignRecommendationModalProps) {
  const { recommendations: fetchedRecommendations, isLoading } = useGetManyRecommendationExtended({
    recommendationIds,
    recommendationsProp: recommendations,
  });

  const { mutateAsync: assignRecommendation, isLoading: isAssigning } = useAssignRecommendation();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<TAssignRecommendationForm>({
    mode: 'onBlur',
  });

  const { data: userResponse } = useGetModelItems<TUsers>({
    model: 'user',
    queryParams: `$filter=(status eq ${getEnumValue('UserStatus', 'Active')})`,
  });

  const userOptions = useMemo(() => {
    if (!userResponse)
      return [
        {
          label: 'Unassign',
          value: -1,
        },
      ];

    const userResponseOptions = userResponse.items
      .map((user) => ({
        label: user.email,
        value: user.id,
      }))
      .sort((a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase()));

    userResponseOptions.unshift({
      label: 'Unassign',
      value: -1,
    });

    return userResponseOptions;
  }, [userResponse]);

  useEffect(() => {
    const assignedToId = recommendations?.[0]?.assignedToId;

    reset({
      assignedToId: assignedToId || undefined,
    });
  }, [modalProps.open, recommendations]);

  const handleAssign: SubmitHandler<TAssignRecommendationForm> = async (data) => {
    const recommendationIds = fetchedRecommendations.map((recommendation) => recommendation.recommendationId);

    const response = await assignRecommendation({
      assignedToId: data.assignedToId,
      notes: data.notes,
      recommendationIds,
    });

    if (response.status === 201) {
      reset();
      modalProps.onOk?.();
    }
  };

  const handleCloseModal = () => {
    reset();
    modalProps.onCancel?.();
  };

  const Placeholder = () => {
    return (
      <PlaceholderContainer style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'var(--grey-300)' }}>
        <SearchOutlined height={16} width={16} />
        {'Assign'}
      </PlaceholderContainer>
    );
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={
        <BaseModalTitle>
          {fetchedRecommendations.length > 1 ? 'Recommendations Assignment' : 'Recommendation Assignment'}
        </BaseModalTitle>
      }
      destroyOnClose={true}
      onCancel={handleCloseModal}
      footer={null}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <FieldContainer>
            <ModalMessage>
              {fetchedRecommendations.length > 1
                ? `${fetchedRecommendations.length} recommendations selected.`
                : fetchedRecommendations[0]?.findings}
            </ModalMessage>
          </FieldContainer>

          <FormContainer onSubmit={handleSubmit(handleAssign)}>
            <FieldContainer>
              <FieldLabel>Assign this recommendation as a task for a user in your team:</FieldLabel>
              <InputContainer>
                <Controller
                  control={control}
                  name="assignedToId"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      allowClear
                      value={field.value}
                      showSearch
                      placeholder={<Placeholder />}
                      style={{ flexBasis: '75%' }}
                      options={userOptions}
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                    >
                      {userOptions.map((opt) => (
                        <Select.Option key={opt.value} value={opt.value}>
                          {opt.label}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
                {!!errors.assignedToId && <ErrorMessage>This field is required</ErrorMessage>}
              </InputContainer>
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>Notes:</FieldLabel>
              <InputContainer>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <HtmlEditor
                      initialValue={field.value}
                      placeholderText="Enter Your Comment"
                      onChange={(content) => setValue('notes', content)}
                    />
                  )}
                />
              </InputContainer>
            </FieldContainer>

            <ModalFooterContainer>
              <BaseCancelButton type="button" onClick={modalProps.onCancel}>
                Cancel
              </BaseCancelButton>
              <BaseCTAButton htmlType="submit" disabled={isAssigning} style={{ padding: '12px 32px' }}>
                Assign
              </BaseCTAButton>
            </ModalFooterContainer>
          </FormContainer>
        </>
      )}
    </Modal>
  );
}
