import { HorizontalAlignedContainer, VerticalContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TRecommendationEventType } from '@app/types/recommendationHistory';
import { Popover } from 'antd';
import { getDetailedChanges } from '@app/utils/utils';
import { getEnumLabel, getEnumValue } from '@app/services/enum.service';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { COMPACT_TABS_COLORS, FONT_WEIGHT, RECOMMENDATION_DETAILS_MODAL_COLORS } from '@app/styles/themes/constants';
import styled from 'styled-components';

const ComplianceStatus = styled.div<{ change_type: 'regression' | 'improvement' | null }>`
  font-weight: 400;
  font-size: 12px;
  background-color: ${({ change_type }) =>
    change_type === null ? '#F6F8FE' : change_type === 'regression' ? '#FFF1F1' : '#E6F4EA'};
  color: ${({ change_type }) => (change_type === null ? '#333' : change_type === 'regression' ? '#D81D2D' : '#1e803e')};

  width: fit-content;
  padding: 2px 8px;
  border-radius: 6px;
`;

const CommentBlock = styled.div`
  padding: 8px;
  background-color: ${COMPACT_TABS_COLORS.lightGray};
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
  border-radius: 6px;
  font-size: 14px;
`;

interface IComplianceStatusChangesProps {
  event: TRecommendationEventType;
  recommendationValidationMethod: number;
  title?: string;
  showTimeOnly?: boolean;
}

const ComplianceStatusChanges = ({
  event,
  recommendationValidationMethod,
  title,
  showTimeOnly,
}: IComplianceStatusChangesProps) => {
  const { date, change_type, user, comment, change_details, snapshot_type } = event;

  const complianceText = change_type === 'improvement' ? 'Compliant' : 'Non-Compliant';
  const isManual =
    (recommendationValidationMethod as unknown as string) === getEnumLabel('RecommendationValidationMethod', 2);
  const complianceMessage =
    snapshot_type == getEnumValue('RecommendationSnapshotType', 'InitialScan')
      ? `Recommendation was created with ${complianceText} status.`
      : isManual
      ? `Recommendation was marked as ${complianceText} by ${user}${
          comment?.length === undefined ? '.' : `, who noted:`
        }`
      : `Recommendation automatically changed to ${complianceText}. `;

  return (
    <VerticalContainer style={{ gap: '4px', marginBottom: '12px' }}>
      <VerticalContainer>
        <span
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: `${COMPACT_TABS_COLORS.darkGray}`,
          }}
        >
          {`${title ? title : 'Recommendation'} ${
            snapshot_type == getEnumValue('RecommendationSnapshotType', 'InitialScan')
              ? 'was created as'
              : 'changed to '
          } ${complianceText}`}
        </span>
        <span style={{ fontSize: '14px', color: 'var(--grey-700)', fontWeight: `${FONT_WEIGHT.regular}` }}>
          {complianceMessage}
        </span>
      </VerticalContainer>
      <span style={{ fontSize: '14px' }}>
        <ComplianceStatus change_type={change_type}>{complianceText}</ComplianceStatus>
        {isManual && comment && (
          <CommentBlock>
            <HtmlEditor initialValue={comment} readOnly />
          </CommentBlock>
        )}
        {false && !isManual && change_details && change_details.length != 0 && (
          <span>
            <Popover
              style={{ marginLeft: '16px', fontSize: '14px' }}
              content={
                <VerticalContainer>
                  {change_details.map((changes, i) => (
                    <div key={i}>{getDetailedChanges(changes)}</div>
                  ))}
                </VerticalContainer>
              }
            >
              <a>View possible causes</a>
            </Popover>
          </span>
        )}
      </span>
    </VerticalContainer>
  );
};

export { ComplianceStatusChanges };
