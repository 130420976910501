import { useState, useEffect } from 'react';
import { TAccountRegulations } from '@app/types/accountRegulations';
import { Card } from 'antd';
import styled, { keyframes, css } from 'styled-components';

const CardInfoContainer = styled.div`
  padding: 16px;
`;

const CardProgressContainer = styled.div<{ backgroundColor: string }>`
  padding: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0 0 10px 10px;
`;

const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  cursor: pointer;
  transition: all ease 200ms;
  min-height: 200px;
  margin-right: 16px;
  border-radius: 10px;

  &:hover {
    transform: scale(1.02);
  }

  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

const ComplianceName = styled.p`
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 600;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Description = styled.p`
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.75rem;
  color: var();
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  flex-grow: 1;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const progressAnimation = (percentage: number) => keyframes`
  from {
    width: 0%;
  }
  to {
    width: ${percentage}%;
  }
`;

const ComplianceProgress = styled.div<{
  percentage: number;
  strokeColor: string;
  numOfCompliant: number;
  total: number;
  animate: boolean;
}>`
  position: relative;
  width: 100%;
  height: 15%;
  background-color: var(--grey-200);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 12px;

  &::before {
    content: '';
    display: block;
    width: ${({ animate, percentage }) => (animate ? '0%' : `${percentage}%`)};
    height: 100%;
    background-color: ${({ strokeColor }) => strokeColor};
    border-radius: 10px;
    ${({ animate, percentage }) =>
      animate &&
      css`
        animation: ${progressAnimation(percentage)} 2s ease-out forwards;
      `}
  }

  &::after {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #000;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

const createCategoryStyledComponent = (backgroundColor: string, borderColor: string) => styled.div`
  border: 1px solid;
  border-radius: 4px;
  background-color: ${backgroundColor};
  border-color: ${borderColor};
  padding: 2px 8px;
  display: flex;
  gap: 4px;
  align-items: center;
`;
const Total = createCategoryStyledComponent('var(--blue-50)', 'var(--blue-100)');
const Compliant = createCategoryStyledComponent('var(--green-50)', 'var(--green-100)');
const NonCompliant = createCategoryStyledComponent('var(--red-50)', 'var(--red-100)');
const None = createCategoryStyledComponent('var(--grey-50)', 'var(--grey-100)');

const TotalText = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  color: var(--grey-500);
`;

const TotalNumber = styled.span`
  font-weight: 500;
  font-size: 1rem;
  color: var(--grey-950);
`;

const ChipRow = styled.div`
  display: flex;
  gap: 8px;
`;

const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;
interface ComplianceCardProps extends TAccountRegulations {
  onCardClick: () => void;
}

const ComplianceCard = ({
  id,
  name,
  description,
  numOfCompliant,
  numOfRecommendations,
  numOfNonCompliant,
  onCardClick,
}: ComplianceCardProps) => {
  const percentage = Math.round((numOfCompliant * 100) / numOfRecommendations);
  const numOfNARecommendations = numOfRecommendations - (numOfCompliant + numOfNonCompliant);
  const validPercentageScore = isNaN(percentage) ? 0 : percentage;
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const strokeColor =
    validPercentageScore <= 25
      ? 'var(--grey-950)'
      : validPercentageScore > 25 && validPercentageScore < 80
      ? 'var(--red-900)'
      : 'var(--green-800)';

  const progressBackgroundColor =
    validPercentageScore <= 25
      ? 'var(--grey-50)'
      : validPercentageScore > 25 && validPercentageScore < 80
      ? 'var(--red-50)'
      : 'var(--green-50)';

  return (
    <CardContainer key={id} onClick={onCardClick}>
      <CardInfoContainer>
        <ComplianceName>{name}</ComplianceName>
        <Description>{description}</Description>
        <ChipRow>
          <Total>
            <TotalNumber>{numOfRecommendations}</TotalNumber>
            <TotalText>Total</TotalText>
          </Total>
          <Compliant>
            <TotalNumber>{numOfCompliant}</TotalNumber>
            <TotalText>Compliant</TotalText>
          </Compliant>
          <NonCompliant>
            <TotalNumber>{numOfNonCompliant}</TotalNumber>
            <TotalText>Non-Compliant</TotalText>
          </NonCompliant>
          <None>
            <TotalNumber>{numOfNARecommendations}</TotalNumber>
            <TotalText>N/A</TotalText>
          </None>
        </ChipRow>
      </CardInfoContainer>
      <CardProgressContainer backgroundColor={progressBackgroundColor}>
        <ProgressInfo>
          <TotalText>Progress</TotalText>
          <TotalNumber style={{ fontSize: '0.75rem' }}>{validPercentageScore}%</TotalNumber>
        </ProgressInfo>

        <ComplianceProgress
          percentage={validPercentageScore}
          strokeColor={strokeColor}
          numOfCompliant={numOfCompliant}
          total={numOfRecommendations}
          animate={animate}
        />
      </CardProgressContainer>
    </CardContainer>
  );
};

export default ComplianceCard;
