export const DEFAULT_LIMIT_INTERVAL = [10, 25, 50];
export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_TOTAL_ROWS = 0;

export const SELECT_QUERY_PARAM_KEY = '$select';
export const TOP_QUERY_PARAM_KEY = '$top';
export const SKIP_QUERY_PARAM_KEY = '$skip';
export const FILTER_QUERY_PARAM_KEY = '$filter';
export const ORDER_BY_QUERY_PARAM_KEY = '$orderby';

export const WORD_COUNT_TO_FETCH = 3;
export const DEFAULT_DATE_FORMAT = 'DD/MM/yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/yyyy HH:mm';

export const DEFAULT_SELECTED_OPERATOR = 'eq';

export const NUMBER_OPERATORS = [
  {
    title: '==',
    value: 'eq',
  },
  {
    title: '!=',
    value: 'ne',
  },
  {
    title: '>',
    value: 'gt',
  },
  {
    title: '<',
    value: 'lt',
  },
  {
    title: '>=',
    value: 'ge',
  },
  {
    title: '<=',
    value: 'le',
  },
];
