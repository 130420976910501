import { useMemo } from 'react';
import { DashboardCenterContainer } from '../common/BaseLayout/BaseLayout.styled';
import Spin from 'antd/lib/spin';
import { AlertItem } from './AlertItem';
import { useGetLatestAlerts } from '@app/api/statistics.api';

const EMPTY_STATE_MESSAGE = 'No alerts triggered so far';

export function LatestAlerts() {
  const { data: latestAlertsData, isLoading } = useGetLatestAlerts();

  const latestAlerts = useMemo(() => {
    if (latestAlertsData) {
      return latestAlertsData;
    }

    return [];
  }, [latestAlertsData]);

  if (isLoading) {
    return (
      <DashboardCenterContainer>
        <Spin />
      </DashboardCenterContainer>
    );
  }

  return (
    <>
      {!!latestAlerts && latestAlerts.length ? (
        latestAlerts.map((alert) => (
          <AlertItem
            key={alert.historyId}
            id={alert.historyId}
            title={alert.alertName}
            status={alert.status}
            time={alert.triggerTime}
          />
        ))
      ) : (
        <DashboardCenterContainer>{EMPTY_STATE_MESSAGE}</DashboardCenterContainer>
      )}
    </>
  );
}
