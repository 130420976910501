import { TStoryForm } from '@app/components/apps/manage-stories/StoryForm';
import { TStoryRecommendationForm } from '@app/components/apps/manage-stories/StoryRecommendationForm';
import { notificationController } from '@app/controllers/notificationController';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { TList } from '@app/types/generalTypes';
import { TRecommendation } from '@app/types/recommendation';
import { TStoryExtended } from '@app/types/storyExtended';
import { TStoryRecommendation, TStoryRecommendationWithStory } from '@app/types/storyRecommendation';
import buildQuery from 'odata-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { httpApi } from './http.api';

export interface DeleteStoryRecommendation {
  storyId: number;
  recommendationId: number;
}

async function deleteStoryRecommendation({ storyId, recommendationId }: DeleteStoryRecommendation) {
  const response = await httpApi.delete(`/story/${storyId}/${recommendationId}`);
}

export const getStoryAccountRecommendations = async (
  storyId: number,
): Promise<TList<TAccountRecommendationExtended>> => {
  const response = await httpApi.get<TList<TAccountRecommendationExtended>>(`/story/${storyId}/accountRecommendations`);
  return response.data;
};

export const useDeleteStoryRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteStoryRecommendation,
    onSuccess() {
      queryClient.invalidateQueries(['get-rec-opts', 'StoryRecommendation-odata']);
      notificationController.success({ message: 'Deleted story recommendation' });
    },
  });
};

export type TStoryRecommendationRow = TStoryRecommendation & {
  findings: string;
};

export async function getStoryRecommendations(
  storyId: number,
  priority?: number,
  findings?: string,
): Promise<TList<TStoryRecommendationRow>> {
  const odataQuery = buildQuery({
    expand: !!findings
      ? ({ ['recommendation']: { filter: { [`tolower(findings)`]: { contains: findings.toLowerCase() } } } } as any)
      : 'recommendation',
    filter: { and: [{ priority }, { storyId }] },
  });
  const response = await httpApi.get<TList<TStoryRecommendation>>(`/odata/storyrecommendation${odataQuery}`);
  if (response?.data?.items) {
    const items = response.data.items
      .map<TStoryRecommendationRow>((sr, index) => ({
        ...sr,
        findings: sr.recommendation?.findings || '',
      }))
      .filter((item) => !!item.findings);
    return { items, count: items.length };
  }
  return { items: [], count: 0 };
}

async function getStoryRecommendationOptions(storyId: number) {
  const response = await httpApi.get<TRecommendation[]>(`/story/${storyId}/recommendationOptions`);
  if (response?.data?.length) {
    return response.data.map((rec) => ({
      value: rec.id,
      label: rec.findings,
    }));
  }
  return [];
}

export const useGetStoryRecommendationOptions = (storyId: number) => {
  return useQuery({
    queryKey: `get-rec-opts`,
    queryFn: () => getStoryRecommendationOptions(storyId),
  });
};

async function updateStoryRecommendation({ storyId, recommendationId, ...fields }: TStoryRecommendationForm) {
  const response = await httpApi.patch(`/story/${storyId}/${recommendationId}`, fields);
}

export const useUpdateStoryRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateStoryRecommendation,
    onSuccess() {
      queryClient.invalidateQueries(['get-rec-opts', 'StoryRecommendation-odata']);
      notificationController.success({ message: 'Updated story recommendation' });
    },
  });
};

async function createStoryRecommendation(
  storyRecommendation: TStoryRecommendationForm,
): Promise<TStoryRecommendation | null> {
  const response = await httpApi.post('/story/addRecommendation', storyRecommendation);
  return response?.data;
}

export const useCreateStoryRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createStoryRecommendation,
    onSuccess() {
      queryClient.invalidateQueries(['get-rec-opts', 'StoryRecommendation-odata']);
      notificationController.success({ message: 'Added new story recommendation' });
    },
  });
};

async function getStory(storyId: string | undefined): Promise<TStoryExtended | null> {
  if (!storyId) {
    return null;
  }

  const response = await httpApi.get(`/odata/StoryExtended?$filter=(id eq ${storyId})`);
  return response?.data?.items.at(0);
}

export const useGetStory = (storyId: string | undefined) => {
  return useQuery({
    queryFn: () => getStory(storyId),
    queryKey: `get-story-${storyId}`,
  });
};

async function createStory(data: TStoryForm) {
  const response = await httpApi.post('story', data);
  return response;
}

export const useCreateStory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createStory,
    onSuccess() {
      queryClient.invalidateQueries('story-odata');
    },
  });
};

export interface UpdateStoryProps {
  id: number;
  data: TStoryForm;
}

async function updateStory({ id, data }: UpdateStoryProps) {
  const response = await httpApi.patch(`story/${id}`, data);
  return response;
}

export const useUpdateStory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateStory,
    onSuccess() {
      queryClient.invalidateQueries('story-odata');
    },
  });
};

async function deleteStory(id: number) {
  const response = await httpApi.patch(`story/${id}`, { status: 3 });
  return response;
}

export const useDeleteStory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteStory,
    onSuccess() {
      queryClient.invalidateQueries('story-odata');
      notificationController.success({ message: `Successfully deleted story.` });
    },
  });
};

async function getRecommendationStories(recommendationId: number): Promise<TStoryRecommendationWithStory[]> {
  const response = await httpApi.get(`/story/recommendation-stories/${recommendationId}`);
  return response?.data;
}

export const useGetRecommendationStories = (recommendationId: number) => {
  return useQuery({
    queryKey: `get-recommendation-stories-${recommendationId}`,
    queryFn: () => getRecommendationStories(recommendationId),
  });
};
