import React from 'react';
import styled from 'styled-components';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { Spin } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;
const ConfirmationMessage = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  color: var(--grey-950);
  text-align: center;
  font-family: Raleway;
`;
const CancelButton = styled.button`
  border: 1px solid var(--blue-200);
  border-radius: 4px;
  background-color: transparent;
  padding: 12px 16px;
  color: var(--blue-800);
  width: 50%;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
`;
const ConfirmButton = styled.button`
  border: 1px solid var(--blue-600);
  border-radius: 4px;
  background-color: var(--blue-600);
  padding: 12px 16px;
  width: 50%;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const StyledHorizontalContainer = styled(HorizontalAlignedContainer)`
  gap: 10px;
  margin-top: 48px;
  width: 100%;
`;
interface IConfirmationModalProps extends ModalFuncProps {
  isLoading?: boolean;
  message?: string;
  confirmButtonMessage?: string;
}

const ConfirmationModal = ({
  isLoading,
  message,
  confirmButtonMessage = 'Yes',
  ...modalProps
}: IConfirmationModalProps) => {
  return (
    <Modal {...modalProps} destroyOnClose closable={false} footer={null} centered style={{ minWidth: '400px' }}>
      <Container>
        <ConfirmationMessage>{message}</ConfirmationMessage>
        <StyledHorizontalContainer>
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton onClick={modalProps.onCancel}>Cancel</CancelButton>
              <ConfirmButton onClick={modalProps.onOk}>{confirmButtonMessage}</ConfirmButton>
            </>
          )}
        </StyledHorizontalContainer>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
