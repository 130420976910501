import { IconProps } from '../types';

export default function CheckDoneIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M1.33325 8.49998C1.33325 5.35728 1.33325 3.78593 2.30956 2.80962C3.28587 1.83331 4.85722 1.83331 7.99992 1.83331C11.1426 1.83331 12.714 1.83331 13.6903 2.80962C14.6666 3.78593 14.6666 5.35728 14.6666 8.49998C14.6666 11.6427 14.6666 13.214 13.6903 14.1903C12.714 15.1666 11.1426 15.1666 7.99992 15.1666C4.85722 15.1666 3.28587 15.1666 2.30956 14.1903C1.33325 13.214 1.33325 11.6427 1.33325 8.49998Z"
        fill="white"
        stroke={fill}
      />
      <path
        d="M5.66675 8.83331L7.00008 10.1666L10.3334 6.83331"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
