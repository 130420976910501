import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getModelItems } from '@app/api/table.api';
import ReportCard from '@app/components/apps/reports/ReportCard';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import ListComponent from '@app/components/common/lists/ListComponent';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';
import { TReport } from '@app/types/reports';
import GenerateReportModal from '@app/components/apps/reports/generate-report-modal/GenerateReportModal';
import { SearchComponent } from '@app/components/common/BaseSearch/SearchComponent';
import { useSearchComponent } from '@app/components/common/BaseSearch/useSearch';
import { getScreenResolution } from '@app/utils/utils';
import { useLoadMore } from '@app/hooks/useLoadMore';
import { NoData } from '@app/components/common/NoData';
import styled from 'styled-components';
import { Spin } from 'antd';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ReportsPage() {
  const { t } = useTranslation();
  const resolution = getScreenResolution();
  const itemsPerPage = resolution.height >= 1080 ? 8 : 6;
  const appValues = useAppSelector((state) => state.app.appValues);

  const [selectedReport, setSelectedReport] = useState<TReport | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const useSearchProps = useSearchComponent({
    debounceTimeout: 1000,
  });

  const activeReportsQueryParams = useMemo(() => {
    if (appValues) {
      const activeReportStatus = getEnumValue('ReportStatus', 'Active');
      return `$filter=((contains(tolower(name), '${useSearchProps.debouncedQuery.toLowerCase()}')) and (status eq ${activeReportStatus}))`;
    }

    return undefined;
  }, [appValues, useSearchProps.debouncedQuery]);

  const {
    data: reports,
    isFetching,
    loadMoreData,
    reset,
    refetch,
  } = useLoadMore<TReport, TReport>({
    queryKey: 'reports',
    itemsPerPage: itemsPerPage,
    fetchFn: async (top, skip) => {
      const filter = useSearchProps.debouncedQuery
        ? `${activeReportsQueryParams}&$top=${top}&$skip=${skip}`
        : `$filter=(status eq ${getEnumValue('ReportStatus', 'Active')})&$top=${top}&$skip=${skip}`;

      const response = await getModelItems<TReport>({
        model: 'Report',
        queryParams: filter,
      });

      return response;
    },
  });

  useEffect(() => {
    if (useSearchProps.debouncedQuery) {
      reset();
    }
    refetch();
  }, [useSearchProps.debouncedQuery]);

  useEffect(() => {
    const mainContainer = document.getElementById('main-content');

    if (!mainContainer) {
      return;
    }

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = mainContainer;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !isFetching && !useSearchProps.debouncedQuery) {
        loadMoreData();
      }
    };

    mainContainer.addEventListener('scroll', handleScroll);
    return () => {
      mainContainer.removeEventListener('scroll', handleScroll);
    };
  }, [loadMoreData, isFetching, useSearchProps.debouncedQuery]);

  const handleGenerateReportClick = (report: TReport) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const parsedCustomData = useMemo(() => {
    if (!selectedReport) return [];

    try {
      const parsedArray: {
        label: string;
        name: string;
        type: 'Date' | 'DateRange' | 'Text';
        isRequired: boolean;
        minDate?: string;
        maxDate?: string;
      }[] = JSON.parse(selectedReport.customData.replaceAll('”', '"').replaceAll('“', '"'));

      return [
        {
          name: selectedReport.name,
          customData: parsedArray,
        },
      ];
    } catch {
      return [];
    }
  }, [selectedReport]);

  return (
    <BasePageWrapper
      title={t('common.reports')}
      description="Choose from our library of predefined report templates to generate detailed reports tailored to your specific needs."
    >
      <PageTitle>Reports</PageTitle>

      <SearchComponent {...useSearchProps} />

      {!isFetching && reports.length === 0 && <NoData withIcon={true} />}

      <ListComponent
        containerStyle={{ marginTop: '2rem' }}
        data={reports || []}
        renderComponent={(report) => (
          <ReportCard {...report} onGenerateReportClick={() => handleGenerateReportClick(report)} />
        )}
      />

      {selectedReport && (
        <GenerateReportModal
          report={parsedCustomData}
          open={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
            setSelectedReport(null);
          }}
        />
      )}

      {isFetching && (
        <LoadingContainer>
          <Spin delay={10} size="large" />
        </LoadingContainer>
      )}
    </BasePageWrapper>
  );
}
