import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { TooltipProps } from 'recharts/types/component/Tooltip';
import { FaArrowTrendUp } from 'react-icons/fa6';

function TooltipDataRow({ name, payload }: Payload<ValueType, NameType>) {
  if (!payload || payload.length === 0 || !name) {
    return <></>;
  }
  return (
    <div style={{ minWidth: 120 }}>
      <span
        style={{
          fontWeight: '500',
          fontSize: '12px',
          color: '#303030',
        }}
      >
        {name}
      </span>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '8px',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '4px',
            backgroundColor: 'var(--green-50)',
          }}
        >
          <FaArrowTrendUp fill="var(--green-900)" />
          <span
            style={{
              fontWeight: '600',
              fontSize: '14px',
              flexGrow: 1,
              marginLeft: '6px',
              color: 'var(--green-900)',
            }}
          >
            {payload?.[name]}%
          </span>
        </div>

        <span
          style={{
            fontWeight: '500',
            fontSize: '12px',
            color: '#303030',
          }}
        >
          {payload?.name}
        </span>
      </div>
    </div>
  );
}

export default function CustomTooltip({ payload, label, active }: TooltipProps<ValueType, NameType>) {
  if (active && !payload?.length) {
    return <></>;
  }

  return (
    <div
      style={{
        padding: '10px',
        borderRadius: '9px',
        backgroundColor: 'white',
      }}
    >
      {payload?.map((p, index) => (
        <TooltipDataRow key={index} {...p} />
      ))}
    </div>
  );
}
