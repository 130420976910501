import { IconProps } from '../types';

export default function NotificationBell(props: IconProps) {
  const { fill = 'currentColor', style, className } = props;

  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 20 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 1.91049C6.43321 1.91049 3.54172 4.80199 3.54172 8.36883V8.95558C3.54172 9.53635 3.36981 10.1041 3.04766 10.5873L2.09049 12.0231C0.979577 13.6895 1.82767 15.9545 3.75983 16.4814C4.3895 16.6532 5.0245 16.7984 5.66317 16.9172L5.66475 16.9215C6.30558 18.6314 8.01834 19.8272 10 19.8272C11.9817 19.8272 13.6944 18.6314 14.3352 16.9215L14.3368 16.9172C14.9755 16.7984 15.6106 16.6532 16.2403 16.4814C18.1724 15.9545 19.0205 13.6895 17.9096 12.0231L16.9524 10.5873C16.6303 10.1041 16.4584 9.53635 16.4584 8.95558V8.36883C16.4584 4.80199 13.5669 1.91049 10.0001 1.91049ZM12.8137 17.1496C10.9446 17.373 9.05539 17.3729 7.18626 17.1496C7.77872 18.0009 8.8092 18.5772 10 18.5772C11.1908 18.5772 12.2213 18.0009 12.8137 17.1496ZM4.79172 8.36883C4.79172 5.49234 7.12357 3.16049 10.0001 3.16049C12.8765 3.16049 15.2084 5.49234 15.2084 8.36883V8.95558C15.2084 9.78313 15.4533 10.5922 15.9124 11.2807L16.8696 12.7165C17.5072 13.6729 17.0204 14.973 15.9114 15.2755C12.0412 16.331 7.95893 16.331 4.08872 15.2755C2.9797 14.973 2.49291 13.6729 3.13055 12.7165L4.08772 11.2807C4.54676 10.5922 4.79172 9.78313 4.79172 8.95558V8.36883Z"
        fill={fill}
      />
    </svg>
  );
}
