import { httpApi } from './http.api';
import { useQuery } from 'react-query';
import {
  TComplianceState,
  TComplianceDrifts,
  TDashboardData,
  TrendByProduct,
  TrendOverTime,
  TNonCompliantRecommendationByProduct,
  TLatestAlert,
  TQuickWins,
} from '@app/types/dashboardData';

export interface Statistic {
  id: number;
  value: number;
  prevValue: number;
  unit: 'kg';
}

export const getStatistics = (): Promise<Statistic[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        {
          id: 1,
          value: 45,
          prevValue: 30,
          unit: 'kg',
        },
        {
          id: 2,
          value: 12,
          prevValue: 20,
          unit: 'kg',
        },
        {
          id: 3,
          value: 90,
          prevValue: 60,
          unit: 'kg',
        },
        {
          id: 4,
          value: 78,
          prevValue: 90,
          unit: 'kg',
        },
      ]);
    }, 0);
  });
};

const getTrendByProduct = async (from: Date, to: Date): Promise<TrendByProduct | undefined> => {
  const response = await httpApi.get<TrendByProduct>(
    `/statistics/trendByProduct?from=${from.toISOString()}&to=${to.toISOString()}`,
  );
  return response.data;
};

export const useGetTrendByProduct = (from: Date, to: Date) =>
  useQuery({
    queryFn: () => getTrendByProduct(from, to),
    queryKey: 'trend-by-product',
  });

const getTrendOverTime = async (from: Date, to: Date): Promise<TrendOverTime | undefined> => {
  const response = await httpApi.get<TrendOverTime>(
    `/statistics/trendOverTime?from=${from.toISOString()}&to=${to.toISOString()}`,
  );
  return response.data;
};

export const useGetTrendOverTime = (from: Date, to: Date) =>
  useQuery({
    queryFn: () => getTrendOverTime(from, to),
    queryKey: 'trend-over-time',
  });

const getComplianceDrifts = async (from: Date, to: Date): Promise<TComplianceDrifts | undefined> => {
  const response = await httpApi.get<TComplianceDrifts>(
    `/statistics/compliance-drifts?from=${from.toISOString()}&to=${to.toISOString()}`,
  );

  return response.data;
};

export const useGetComplianceDrifts = (from: Date, to: Date) => {
  return useQuery(['compliance-drifts', from.toISOString(), to.toISOString()], {
    queryFn: () => getComplianceDrifts(from, to),
    keepPreviousData: true,
  });
};

const getComplianceState = async (): Promise<TComplianceState | undefined> => {
  const response = await httpApi.get<TComplianceState>(`/statistics/compliance-state`);
  return response.data;
};

export const useGetComplianceState = () =>
  useQuery({
    queryFn: () => getComplianceState(),
    queryKey: 'compliance-state',
  });

const getQuickWins = async (productIds: string): Promise<TQuickWins | undefined> => {
  const response = await httpApi.get<TQuickWins>(`statistics/quick-wins?product-ids=${productIds}`);
  return response.data;
};

export const useGetQuickWins = (productIds: string) => {
  return useQuery(['quick-wins', productIds], {
    queryFn: () => getQuickWins(productIds),
    keepPreviousData: true,
  });
};

const getDashboardData = async (): Promise<TDashboardData | undefined> => {
  const response = await httpApi.get<TDashboardData>(`/statistics/dashboard`);
  return response.data;
};

export const useGetDashboardData = () =>
  useQuery({
    queryFn: () => getDashboardData(),
    queryKey: 'dashboard-data',
  });

const getTrendsOverTimeChartValues = async (
  from: Date,
  to: Date,
  productIds: string,
): Promise<{
  bySecurityScore: TrendOverTime;
  byProduct: TrendByProduct;
}> => {
  const response = await httpApi.get<{ bySecurityScore: TrendOverTime; byProduct: TrendByProduct }>(
    `/statistics/trend-over-time?from=${from.toISOString()}&to=${to.toISOString()}&product-ids=${productIds}`,
  );
  return response.data;
};

export const useGetTrendOverTimeChartValues = (from: Date, to: Date, productIds: string) => {
  return useQuery(['trendOverTime', from.toISOString(), to.toISOString(), productIds], {
    queryFn: () => getTrendsOverTimeChartValues(from, to, productIds),
  });
};

const getNonCompliantRecommendationByProduct = async (
  productId: string,
): Promise<TNonCompliantRecommendationByProduct | undefined> => {
  const response = await httpApi.get<TNonCompliantRecommendationByProduct>(
    `/statistics/non-compliants-by-product?product-ids=${productId}`,
  );
  return response.data;
};

export const useGetNonCompliantRecommendationByProduct = (productId: string) => {
  return useQuery({
    queryFn: () => getNonCompliantRecommendationByProduct(productId),
  });
};

const getLatestAlerts = async (): Promise<TLatestAlert[]> => {
  const response = await httpApi.get<TLatestAlert[]>(`/statistics/latest-alerts`);
  return response.data;
};

export const useGetLatestAlerts = () => {
  return useQuery({
    queryFn: () => getLatestAlerts(),
    queryKey: 'latest-alerts',
  });
};
