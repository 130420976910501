import { useExportModelToExcel, useExportToExcel } from '@app/api/table.api';
import { BaseActionButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { BasePopoverContent } from '@app/components/common/BaseTypography/BaseTypography';
import { ExportExcelIcon } from '@app/components/icons';
import useToggle from '@app/hooks/useToggle';
import { TExportExcel } from '@app/types/exportTypes';
import { Popover } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { FILTER_QUERY_PARAM_KEY, ORDER_BY_QUERY_PARAM_KEY, SELECT_QUERY_PARAM_KEY } from '../../constants';
import { TDataColumns } from '../../types';
import { ExportExcelModal } from './ExportExcelModal';

interface IExportExcelButtonProps<T extends Record<string, unknown>> {
  exportType: TExportExcel;
  columns: TDataColumns<T>;
  viewName?: string;
}

export default function ExportExcelButton<T extends Record<string, unknown>>({
  columns,
  exportType,
  viewName,
}: IExportExcelButtonProps<T>) {
  const [isModalOpen, toggleModal, setModalOpen] = useToggle(false);
  const [searchParams] = useSearchParams();

  const { mutateAsync: exportToExcel } = useExportToExcel();
  const { mutateAsync: exportModelToExcel } = useExportModelToExcel();

  async function handleExport(exportType: TExportExcel) {
    /**
     * Note: Currently, the export button only works when the bookmark feature is turned on in both tablefilters and data table
     */
    const selectedFieldsQuery = searchParams.get(SELECT_QUERY_PARAM_KEY);
    const filters = searchParams.get(FILTER_QUERY_PARAM_KEY);
    const orderBy = searchParams.get(ORDER_BY_QUERY_PARAM_KEY);

    let sortOrder:
      | {
          fieldName: string;
          order: 'asc' | 'desc';
        }
      | undefined = undefined;

    if (!selectedFieldsQuery || !filters) return;

    const selectedFields = columns
      .filter((col) => !!col.dataIndex && !col.hideColumn)
      .map((col) => ({ name: col.dataIndex, displayName: col.title })) as { name: string; displayName: string }[];

    if (!!orderBy) {
      const splitOrderBy = orderBy.split(' '); // first element should be column and the second element should be the order (asc or desc)
      sortOrder = { fieldName: splitOrderBy?.[0], order: splitOrderBy?.[1] as any };
    }

    try {
      if (exportType.type === 'Standard') {
        const standardResponse = await exportToExcel({
          modelName: exportType.model || '',
          filters,
          sortOrder,
          selectedFields,
        });
      } else if (exportType.type === 'Model') {
        const modelToExcelResponse = await exportModelToExcel({
          modelName: exportType.model || '',
          viewName: viewName || '',
          filters,
          sortOrder,
          selectedFields,
        });
      }
    } catch (error) {
      throw new Error('Failed to export data');
    }
  }

  return (
    <>
      <Popover content={<BasePopoverContent>Export to excel</BasePopoverContent>} placement="topLeft">
        <BaseActionButton onClick={() => setModalOpen(true)}>
          <ExportExcelIcon />
          <span>Export to excel</span>
        </BaseActionButton>
      </Popover>
      <ExportExcelModal
        exportType={exportType}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onApply={(selectedExport) => {
          handleExport(selectedExport);
          setModalOpen(false);
        }}
      />
    </>
  );
}
