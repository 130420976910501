import { IconProps } from '../types';

export default function ChevronLeftIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7898 7.93889C14.0831 8.23137 14.0838 8.70624 13.7913 8.99955L10.7994 12L13.7913 15.0004C14.0838 15.2937 14.0831 15.7686 13.7898 16.0611C13.4965 16.3535 13.0216 16.3529 12.7292 16.0595L9.20915 12.5295C8.91726 12.2368 8.91726 11.7631 9.20915 11.4704L12.7292 7.94039C13.0216 7.64708 13.4965 7.64641 13.7898 7.93889Z"
        fill={fill}
        style={style}
      />
    </svg>
  );
}
