import { Button, Card, Popover } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { GrCircleInformation } from 'react-icons/gr';
import { PopoverContent } from '../common/charts/Legend/Legend.styles';

type IDashboardCardBaseProps = PropsWithChildren<{
  title: string;
  toolTipContent?: string;
}> &
  (TWithActionCallback | TWithRender | TWithoutActionCallback);

type TWithActionCallback = {
  actionCallbackName: string;
  actionCallback: () => void;
  render?: never;
};
type TWithoutActionCallback = {
  actionCallbackName?: never;
  actionCallback?: never;
  render?: never;
};

type TWithRender = {
  actionCallbackName?: never;
  actionCallback?: never;
  render: ReactNode;
};

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 1.2rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
`;

export const DashboardCard = (props: IDashboardCardBaseProps) => {
  const { title, toolTipContent, children, actionCallbackName } = props;

  return (
    <Card style={{ height: '100%' }}>
      <MainContainer>
        <TitleContainer>
          <span style={{ color: 'var(--grey-950)', fontSize: '1.5rem', fontFamily: 'Raleway' }}>
            {title}{' '}
            {toolTipContent && (
              <Popover content={<PopoverContent>{toolTipContent}</PopoverContent>} placement="bottom">
                <GrCircleInformation style={{ fontSize: '1rem', color: 'gray', marginLeft: '10px' }} />
              </Popover>
            )}
          </span>
        </TitleContainer>

        {actionCallbackName && 'actionCallback' in props ? (
          <Button
            size="small"
            type="text"
            style={{ fontSize: '0.85rem', color: '#6455D6' }}
            onClick={props.actionCallback}
          >
            {actionCallbackName}
          </Button>
        ) : (
          'render' in props && props.render
        )}
      </MainContainer>
      {children}
    </Card>
  );
};
