import { useState } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';

import { useInviteVendor } from '@app/api/user.api';
import { SemiCirleCheckIcon } from '@app/components/icons';
import { CenterContainer, ModalFooterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { useEmailGroupInput } from '../manage-users/EmailGroupInput/useEmailGroupInput';
import EmailGroupInput from '../manage-users/EmailGroupInput/EmailGroupInput';
import { BaseCancelButton, BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { useBaseSearchInput } from '@app/components/common/BaseSearch/SearchInput/useBaseSearchInput';
import { BaseSearchInput } from '@app/components/common/BaseSearch/SearchInput/BaseSearchInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FieldLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--grey-950);
`;

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

const Message = styled.span`
  font-size: 32px;
  font-weight: 800;
  line-height: 50px;
  color: #272727;
  text-align: center;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onApply, onApplyDisabled }: IModalFooterProps) => {
  return (
    <ModalFooterContainer>
      <BaseCancelButton type="button" onClick={() => onCancel?.()}>
        Cancel
      </BaseCancelButton>
      <BaseCTAButton
        htmlType="button"
        onClick={() => onApply?.()}
        disabled={onApplyDisabled}
        style={{ padding: '12px 32px' }}
      >
        Invite
      </BaseCTAButton>
    </ModalFooterContainer>
  );
};

interface IInviteVendorsModalProps extends ModalFuncProps {
  isLoading?: boolean;
  onInviteSuccess?: () => void;
}

export default function InviteVendorsModal({ onInviteSuccess, ...modalProps }: IInviteVendorsModalProps) {
  const { mutateAsync: inviteVendors, isLoading: isInviteVendorsLoading } = useInviteVendor();
  const [isSuccess, setIsSuccess] = useState(false);

  const emailGroupInputProps = useEmailGroupInput();
  const searchInputProps = useBaseSearchInput({
    placeholder: 'Reseller name',
  });

  const handleInviteUsers = async () => {
    const invitees = emailGroupInputProps.emails.map((email) => ({
      email: email.trim(),
    }));

    const response = await inviteVendors({ email: invitees[0].email, vendorName: searchInputProps.searchValue });
    if (response) {
      setIsSuccess(true);
      onInviteSuccess?.();
    }
  };

  const handleCloseModal = () => {
    setIsSuccess(false);
    emailGroupInputProps.clearEmails();
    searchInputProps.onChange('');
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={!isInviteVendorsLoading && isSuccess ? null : 'Invite Reseller'}
      destroyOnClose={true}
      closable={false}
      onCancel={handleCloseModal}
      footer={
        isInviteVendorsLoading ? (
          <FooterContainer>
            <Spin />
          </FooterContainer>
        ) : isSuccess ? (
          <FooterContainer>
            <ApplyButton onClick={handleCloseModal}>Continue</ApplyButton>
          </FooterContainer>
        ) : (
          <ModalFooter
            onCancel={handleCloseModal}
            onApply={handleInviteUsers}
            onApplyDisabled={emailGroupInputProps.emails.length === 0}
          />
        )
      }
    >
      {isSuccess ? (
        <CenterContainer
          style={{
            gap: '16px',
            marginTop: '60px',
          }}
        >
          <SemiCirleCheckIcon />
          <Message>Resellers Are Invited!</Message>
        </CenterContainer>
      ) : (
        <Container>
          <FieldContainer>
            <FieldLabel>Reseller Email Address:</FieldLabel>
            <EmailGroupInput single {...emailGroupInputProps} />
          </FieldContainer>
          <FieldContainer>
            <FieldLabel>Reseller Name:</FieldLabel>
            <BaseSearchInput {...searchInputProps} style={{ width: '100%' }} />
          </FieldContainer>
        </Container>
      )}
    </Modal>
  );
}
