import { useMemo } from 'react';
import styled from 'styled-components';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 4px;
`;

const PaginationRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const PagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px;
  background-color: #fff;
`;

const PreviousNextIconContainer = styled.div`
  margin-right: 12px;
  margin-left: 12px;
  padding-top: 5px;
  cursor: pointer;
  border-radius: 9999px;
  :hover {
    background-color: #e9eaeb;
  }
`;

const PageNumber = styled.div`
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  color: var(--grey-400);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 8px;

  :hover {
    background-color: #e9eaeb;
  }
`;

const ActivePageNumber = styled.div`
  height: 20px;
  width: 20px;
  background-color: #eceffd;
  border-radius: 5px;
  color: #141414;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 8px;
`;

const MAX_PAGE_COUNT = 4;

export interface IPaginationProps {
  currentPage: number;
  limit: number;
  totalRows: number;
  totalPages: number;
  onNext?: (currentPage: number) => void;
  onPrev?: (currentPage: number) => void;
  onPageNumberChange?: (newSkipInterval: number) => void;
}

export default function DashboardPagination({
  currentPage,
  limit,
  totalRows,
  totalPages,
  onNext,
  onPrev,
  onPageNumberChange,
}: IPaginationProps) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const visiblePages = useMemo(() => {
    if (totalRows < limit * MAX_PAGE_COUNT) {
      const pageCount = Math.ceil(totalRows / limit);

      // default to a single visible page if no pages exist
      if (pageCount === 0) {
        return [1];
      }

      return Array.from({ length: pageCount }, (_, i) => i + 1);
    }

    return [currentPage - 1, currentPage, currentPage + 1];
  }, [currentPage, limit, totalRows]);

  return (
    <PaginationContainer>
      <PaginationRightContainer>
        <PagesContainer>
          <PreviousNextIconContainer
            style={{
              color: isFirstPage ? '#B0B0B0' : '#272727',
              pointerEvents: isFirstPage ? 'none' : 'auto',
            }}
            onClick={() => {
              if (!isFirstPage) {
                onPrev?.(currentPage);
              }
            }}
          >
            <MdOutlineKeyboardArrowLeft />
          </PreviousNextIconContainer>
          {visiblePages.map((page) => {
            if (page === currentPage) {
              return (
                <ActivePageNumber key={page} onClick={() => onPageNumberChange?.(page)}>
                  {page}
                </ActivePageNumber>
              );
            }

            return (
              <PageNumber key={page} onClick={() => onPageNumberChange?.(page)}>
                {page}
              </PageNumber>
            );
          })}
          <PreviousNextIconContainer
            style={{
              color: isLastPage ? '#B0B0B0' : '#272727',
              pointerEvents: isLastPage ? 'none' : 'auto',
            }}
            onClick={() => {
              if (!isLastPage) {
                onNext?.(currentPage);
              }
            }}
          >
            <MdOutlineKeyboardArrowRight />
          </PreviousNextIconContainer>
        </PagesContainer>
      </PaginationRightContainer>
    </PaginationContainer>
  );
}
