import styled from 'styled-components';

export const FilterCount = styled.div`
  border-radius: 999px;
  background-color: var(--orange-300);
  color: var(--blue-950);
  font-size: 0.625rem;
  padding: 4px 8px;
  position: absolute;
  right: -8px;
  top: -8px;
`;
