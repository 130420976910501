import { useQuery } from 'react-query';
import { httpApi } from './http.api';

const getAccountRecommendationsDailyEvents = async (accountId: string, userTimezone: string, from: Date, to: Date) => {
  const response = await httpApi.get(
    `/account-recommendation/daily-events?accountId=${accountId}&timezone=${userTimezone}&from=${from.toISOString()}&to=${to.toISOString()}`,
  );
  return response?.data;
};

export const useGetAccountRecommendationsDailyEvents = (
  accountId: string,
  userTimezone: string,
  from: Date,
  to: Date,
) => {
  return useQuery({
    queryKey: ['get-account-recommendations-daily-events', accountId, userTimezone, from, to],
    queryFn: () => getAccountRecommendationsDailyEvents(accountId, userTimezone, from, to),
  });
};
