import { FONT_WEIGHT } from '@app/styles/themes/constants';
import { TDailyEvents } from '@app/types/changelogs';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { getTotalChanges } from './EventHistoryChart';
import { TTimelineType } from '@app/components/tables/AntdTableWrapper/components/Timeline';
import { FaArrowRight } from 'react-icons/fa6';
import { TEventLogs } from '@app/pages/RecommendationHistory';
import { format, formatInTimeZone } from 'date-fns-tz';

const Indicator = styled.div<{ color: string }>`
  width: 3px;
  height: 50px;
  border-radius: 20%;
  background-color: ${({ color }) => color};
  margin-right: 2px;
  flex-shrink: 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const TooltipContainer = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 12vw;
  font-size: 12px;

  pointer-events: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Labels = styled.span`
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--grey-950);
`;

const TextLabels = styled.span`
  width: 100%;
  color: var(--grey-500);
  font-weight: ${FONT_WEIGHT.regular};
`;

const AdditionalDetailsText = styled.span`
  display: flex;
  align-items: center;
  color: var(--blue-600);
  font-weight: ${FONT_WEIGHT.regular};
  gap: 8px;
  cursor: pointer;
  pointer-events: auto;

  :hover {
    text-decoration: underline;
  }
`;

interface IEventToolTipProps {
  type: TTimelineType;
  item: TDailyEvents;
  handleAdditionalDetailsClicked: (props: { changeLogDate: string; eventsText: TEventLogs }) => void;
}

export default function EventTooltip({ type, item, handleAdditionalDetailsClicked }: IEventToolTipProps) {
  if (!item) {
    return null;
  }

  const changes = getTotalChanges(item, type);

  const becameNonCompliantText = `${
    changes.becameNonCompliantTotal > 0
      ? `${pluralize('recommendation', changes.becameNonCompliantTotal)} changed to non-compliant${
          changes.riskyNonCompliantTotal > 0 ? `. ${changes.riskyNonCompliantTotal} with high risk level.` : '.'
        }`
      : ''
  }`;

  const becameCompliantText = `${
    changes.becameCompliantTotal > 0
      ? `${pluralize('recommendation', changes.becameCompliantTotal)} changed to compliant.`
      : ''
  }`;

  const markedExemptedText =
    changes.markedExemptedTotal > 0 ? `${pluralize('recommendation', changes.markedExemptedTotal)} exempted.` : '';

  const markedUnexemptedText =
    changes.markedNonExemptedTotal > 0
      ? `${pluralize('recommendation', changes.markedNonExemptedTotal)} unexempted.`
      : '';

  const assignmentsText =
    changes.assignmentsTotal > 0 ? `${pluralize('recommendation', changes.assignmentsTotal)} assignments changed.` : '';

  const totalOtherChanges = changes.markedExemptedTotal + changes.markedNonExemptedTotal + changes.assignmentsTotal;

  const otherChangesText = totalOtherChanges > 0 ? `${pluralize('other change', totalOtherChanges)} made.` : '';

  const numOfImprovements = changes.becameCompliantTotal + changes.markedNonExemptedTotal;
  const numOfRegressions = changes.becameNonCompliantTotal + changes.markedExemptedTotal;

  return (
    <TooltipContainer>
      <Column>
        <Labels>Date</Labels>
        <TextLabels>{format(item.local_date, 'dd/MM/yyyy')}</TextLabels>
      </Column>
      <Column>
        <Labels>Events</Labels>
        {changes.becameNonCompliantTotal > 0 && (
          <Row>
            <Indicator color="red" />
            <Column>
              <Labels>{changes.becameNonCompliantTotal}</Labels>
              <TextLabels>{becameNonCompliantText}</TextLabels>
            </Column>
          </Row>
        )}
        {changes.becameCompliantTotal > 0 && (
          <Row style={{ marginTop: '8px' }}>
            <Indicator color="green" />
            <Column>
              <Labels>{changes.becameCompliantTotal}</Labels>
              <TextLabels>{becameCompliantText}</TextLabels>
            </Column>
          </Row>
        )}
        {/* Check conditions for other changes */}
        {totalOtherChanges > 0 && (
          <>
            {/* If both becameCompliant and becameNonCompliant are 0 */}
            {changes.becameCompliantTotal === 0 && changes.becameNonCompliantTotal === 0 && (
              <>
                {numOfRegressions > 0 && (
                  <Row style={{ marginTop: '8px' }}>
                    <Indicator color="red" />
                    <Column>
                      <Labels>{changes.markedExemptedTotal}</Labels>
                      <TextLabels>{markedExemptedText}</TextLabels>
                    </Column>
                  </Row>
                )}
                {numOfImprovements > 0 && (
                  <Row style={{ marginTop: '8px' }}>
                    <Indicator color="green" />
                    <Column>
                      <Labels>{changes.markedNonExemptedTotal}</Labels>
                      <TextLabels>{markedUnexemptedText}</TextLabels>
                    </Column>
                  </Row>
                )}
                {changes.assignmentsTotal > 0 && (
                  <Row style={{ marginTop: '8px' }}>
                    <Indicator color="gray" />
                    <Column>
                      <Labels>{changes.assignmentsTotal}</Labels>
                      <TextLabels>{assignmentsText}</TextLabels>
                    </Column>
                  </Row>
                )}
              </>
            )}

            {/* If either becameCompliant or becameNonCompliant > 0 */}
            {(changes.becameCompliantTotal > 0 || changes.becameNonCompliantTotal > 0) && (
              <Row style={{ marginTop: '8px' }}>
                <Indicator color="gray" />
                <Column>
                  <Labels>{totalOtherChanges}</Labels>
                  <TextLabels>{otherChangesText}</TextLabels>
                </Column>
              </Row>
            )}
          </>
        )}
      </Column>
      <Row>
        <AdditionalDetailsText
          onClick={() =>
            handleAdditionalDetailsClicked({
              changeLogDate: item.local_date,
              eventsText: {
                labels: {
                  becameCompliantText,
                  becameNonCompliantText,
                  markedExemptedText,
                  markedUnexemptedText,
                  assignmentsText,
                },
                totals: {
                  becameCompliantTotal: changes.becameCompliantTotal,
                  becameNonCompliantTotal: changes.becameNonCompliantTotal,
                  markedExemptedTotal: changes.markedExemptedTotal,
                  markedNonExemptedTotal: changes.markedNonExemptedTotal,
                  assignmentsTotal: changes.assignmentsTotal,
                },
              } as TEventLogs,
            })
          }
        >
          Additional Details <FaArrowRight />
        </AdditionalDetailsText>
      </Row>
    </TooltipContainer>
  );
}
