import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import React from 'react';
import styled from 'styled-components';

interface SearchComponentProps {
  query: string;
  onChange: (query: string) => void;
  placeholder?: string;
}
const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px;
  gap: 12px;
  width: 35%;
  border: 1px solid var(--grey-200);
  border-radius: 4px;
`;
const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  width: 100%;

  ::placeholder {
    color: #acacac;
  }
`;

export const SearchComponent: React.FC<SearchComponentProps> = ({ query, onChange, placeholder = 'Search' }) => {
  return (
    <SearchContainer style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <SearchOutlined style={{ color: '#ACACAC' }} />
      <SearchInput type="text" value={query} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
    </SearchContainer>
  );
};
