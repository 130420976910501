import React, { useEffect, useMemo, useState } from 'react';
import ByProductLineChart from '@app/components/dashboard/ByProductLineChart';
import BySecurityScoreLineChart from '@app/components/dashboard/BySecurityScoreLineChart';
import { usePagination } from '../tables/AntdTableWrapper/hooks/usePagination';
import DashboardPagination from './DashboardPagination';
import styled from 'styled-components';
import { useGetTrendOverTimeChartValues } from '@app/api/statistics.api';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { TEnumEntity } from '@app/types/enumEntity';

export const SubTitle = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--grey-950);
`;

interface ChartGroupProps {
  trendOverTimeChartValuesDate: {
    from: Date;
    to: Date;
  };
}

const ChartGroup: React.FC<ChartGroupProps> = ({ trendOverTimeChartValuesDate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const selectedProducts = useSelector<RootState>((state) => state.app.accountProducts) as TEnumEntity[] | null;

  const productIds = useMemo(() => {
    if (selectedProducts) {
      return selectedProducts.map((product) => product.id);
    }
    return [];
  }, [selectedProducts]);

  const totalRows = productIds.length;

  const paginatedProductIds = useMemo(() => {
    const start = (currentPage - 1) * limit;
    const end = start + limit;
    return productIds.slice(start, end);
  }, [currentPage, limit, productIds]);

  const { data: trendOverTimeChartValues, isLoading } = useGetTrendOverTimeChartValues(
    trendOverTimeChartValuesDate.from,
    trendOverTimeChartValuesDate.to,
    paginatedProductIds.join(','),
  );

  const paginationProps = usePagination({
    limit,
    skip: (currentPage - 1) * limit,
    totalRows,
    limitIntervals: [5],
    onNext() {
      if (currentPage * limit < totalRows) {
        setCurrentPage(currentPage + 1);
      }
    },
    onPrev() {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    },
    onPageClicked(callbackProps) {
      setCurrentPage(callbackProps.currentPage);
    },
  });

  return (
    <>
      <BySecurityScoreLineChart data={trendOverTimeChartValues?.bySecurityScore} isLoading={isLoading} />
      <ByProductLineChart data={trendOverTimeChartValues?.byProduct} isLoading={isLoading} />
      <DashboardPagination {...paginationProps} currentPage={currentPage} />
    </>
  );
};

export default ChartGroup;
