import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BASE_COLORS, COMPACT_TABS_COLORS, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

export const TabsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 0.75rem;
  background-color: ${COMPACT_TABS_COLORS.lightGray};
  padding: 0.4rem;
  max-width: 100%;
  overflow-x: hidden;
`;

type TabProps = {
  $isActive: boolean;
};

export const Tab = styled(BaseButton)<TabProps>`
  border: none;
  height: 2.5rem;
  display: flex;
  align-items: center;
  flex: 1;

  /* Default background and text color */
  background-color: ${({ $isActive }) => ($isActive ? '#fff' : COMPACT_TABS_COLORS.lightGray)};
  color: ${({ $isActive }) => ($isActive ? COMPACT_TABS_COLORS.darkGray : BASE_COLORS.gray)};

  /* Hover effects for inactive and disabled tabs */
  &:hover {
    background-color: ${({ $isActive }) => ($isActive ? '#fff' : '#f5f5f5')};
    color: ${({ $isActive }) => ($isActive ? COMPACT_TABS_COLORS.darkGray : COMPACT_TABS_COLORS.darkGray)};
  }

  /* Disable hover effects on active tabs */
  ${({ $isActive }) =>
    $isActive &&
    `
    cursor: pointer;
    &:hover {
      background-color: #fff;
      color: ${COMPACT_TABS_COLORS.darkGray};
    }
  `}

  /* Focus styling */
  &:focus {
    background-color: ${({ $isActive }) => ($isActive ? '#fff' : '#f9f9f9')};
    color: ${({ $isActive }) => ($isActive ? COMPACT_TABS_COLORS.darkGray : COMPACT_TABS_COLORS.darkGray)};
  }

  /* Disabled tabs styling */
  &:disabled {
    background-color: ${COMPACT_TABS_COLORS.lightGray};
    color: #888888;

    /* Hover effects for disabled tabs */
    &:hover {
      background-color: ${COMPACT_TABS_COLORS.lightGray};
      color: #acacac;
    }
  }

  /* Font weight for active/inactive tabs */
  font-weight: ${({ $isActive }) => ($isActive ? FONT_WEIGHT.semibold : FONT_WEIGHT.medium)};
`;
