import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { CloseCircleIcon } from '@app/components/icons';
import { getColumnStringValue } from '@app/utils/utils';
import { useMemo } from 'react';
import styled from 'styled-components';
import { TTableFilterContext, useTableFilterContext } from '../store/context';

const Container = styled(HorizontalAlignedContainer)`
  flex-wrap: wrap;
  gap: 12px;
`;

export const FilterChip = styled(HorizontalAlignedContainer)`
  padding: 4px 8px;
  border: 1px solid var(--grey-200);
  border-radius: 4px;
  background-color: var(--grey-50);
  color: var(--grey-500);
  font-size: 0.875rem;
  font-weight: 400;
`;

export const ValueLabel = styled.span`
  color: var(--grey-900);
  font-size: 0.875rem;
  font-weight: 500;
`;

export const NoStylButton = styled.button`
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AppliedFiltersSection = <T extends Record<string, unknown>>() => {
  const { columns, filterValuesMap, removeFilter } = useTableFilterContext<TTableFilterContext<T>>();

  const columnValues = useMemo(() => {
    const values: { dataIndex: string; column: string; value: any }[] = [];
    filterValuesMap?.forEach((val, key) => {
      const filterColumn = columns.find((col) => col.dataIndex === key);
      const columnTitle =
        typeof filterColumn?.title === 'string' ? filterColumn?.title?.toString() : filterColumn?.altLabel;
      const columnStringValue = getColumnStringValue(columns, key, val);

      values.push({
        column: columnTitle || '',
        dataIndex: filterColumn?.dataIndex as string,
        value: columnStringValue,
      });
    });

    return values;
  }, [filterValuesMap, columns]);

  return (
    <Container>
      {columnValues.map((colVal, index) => (
        <FilterChip key={index}>
          <span style={{ marginRight: '4px' }}>{`${colVal.column}:`}</span>
          <ValueLabel>{colVal.value}</ValueLabel>
          <NoStylButton onClick={() => removeFilter?.(colVal.dataIndex)}>
            <CloseCircleIcon fill="var(--grey-900)" style={{ marginLeft: '5px' }} />
          </NoStylButton>
        </FilterChip>
      ))}
    </Container>
  );
};
