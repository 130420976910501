import React, { useState } from 'react';
import styled from 'styled-components';
import { useWhoAmI } from '@app/api/auth.api';
import { MdOutlineWarning, MdOutlineClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';

const AlertBannerContainer = styled.div`
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 15px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: center;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const NumRecommendations = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin: 0 5px;
`;

const CloseIcon = styled(MdOutlineClose)`
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }

  @media (min-width: 768px) {
    font-size: 1.8rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const EmailLink = styled.a`
  text-decoration: underline;
  font-weight: bold;
  text-decoration-color: #3a50d1;
  color: white;

  &:hover {
    color: #ccc;
  }
`;

const AlertBanner = () => {
  const { data, isFetching } = useWhoAmI();
  const [isVisible, setIsVisible] = useState(true);
  const recommendationScopingEnabled = useSelector<RootState>(
    (state) => state.user.user?.account?.subscriptionType?.recommendationScopingEnabled,
  );
  if (!isVisible || isFetching || !recommendationScopingEnabled) return null;

  return (
    <AlertBannerContainer>
      <CloseIcon size={18} onClick={() => setIsVisible(false)} />
      <ContentContainer>
        <MdOutlineWarning size={24} style={{ flexShrink: 0 }} />
        <div>
          Your account subscription is limited to a selected set of
          <NumRecommendations>{data?.numEnabledAccountRecommendations || 0}</NumRecommendations>
          recommendations.
        </div>
      </ContentContainer>
      <div style={{ paddingLeft: '5px' }}>
        <EmailLink href="mailto:support@griffin31.ai?subject=Unlock Griffin31 Features">
          Click here unlock all of Griffin31&apos;s available features
        </EmailLink>
      </div>
    </AlertBannerContainer>
  );
};

export default AlertBanner;
