import { createContext, useContext } from 'react';
import { ITableFiltersProps } from '../components/TableFilters';

type TDataTableContext<T> = { tableProps: T; setTableProps: (s: T) => void };
export const DataTableContext = createContext<TDataTableContext<any>>(undefined as any);

export function useDataTableContext<T>() {
  const context = useContext<TDataTableContext<T>>(DataTableContext);

  if (context === undefined) {
    // assert if context is available
    throw new Error('No context provided');
  }

  return context;
}

export type TTableFilterContext<T extends Record<string, unknown>> = Pick<
  ITableFiltersProps<T>,
  'columns' | 'buildOdataQueryFromParams' | 'filterValuesMap' | 'removeFilter'
>;
export const TableFilterContext = createContext<TTableFilterContext<any>>(undefined as any);

export function useTableFilterContext<T extends Record<string, unknown>>() {
  const context = useContext<TTableFilterContext<T>>(TableFilterContext);

  if (context === undefined) {
    // assert if context is available
    throw new Error('No context provided');
  }

  return context;
}
