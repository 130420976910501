import { useAddRecommendationComment, useGetACcountRecommendationHistory } from '@app/api/recommendation.api';
import { ExemptionChanges } from '../RecommendationDetailsModal/ExemptionChanges';
import { ComplianceStatusChanges } from '../RecommendationDetailsModal/ComplianceStatusChanges';
import { CommentBlock } from '../RecommendationDetailsModal/CommentBlock';
import { AssignementChanges } from '../RecommendationDetailsModal/AssignmentChanges';
import styled from 'styled-components';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { TRecommendationEventType } from '@app/types/recommendationHistory';
import { Collapse, Steps, StepsProps } from 'antd';
import { useMemo, useState } from 'react';
import { formatDate } from '@app/utils/utils';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { DownOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const HistoryEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: min-content;
  max-height: 50vh;
  overflow-y: auto;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #272727;
`;

const GroupedSteps = styled(Steps)`
  .ant-steps-item-title {
    font-weight: bold;
  }

  .ant-steps-item-description {
    font-size: 14px;
    color: #6b6b6b;
  }
`;

const CustomDot = styled.span`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${BASE_COLORS.lightPurple};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RotatingIcon = styled(DownOutlined)<{ isActive?: boolean }>`
  font-size: 14px;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => (props.isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export interface IEventItem {
  eventType: TRecommendationEventType;
  accountRecommendationId: string;
  recommendationValidationMethod: number;
  commentReadOnly?: boolean;
  title?: string;
  showTimeOnly?: boolean;
}

export default function History({
  recommendationId,
  recommendationValidationMethod,
  accountRecommendationId,
}: {
  recommendationId: string;
  accountRecommendationId: string;
  recommendationValidationMethod: number;
}) {
  const { data: events } = useGetACcountRecommendationHistory(accountRecommendationId);
  const { mutateAsync: addComment, isLoading: isAddingComment } = useAddRecommendationComment(accountRecommendationId);
  const [activeKey, setActiveKey] = useState<string | string[]>('');

  const handleCollapseChange = (key: string | string[]) => {
    setActiveKey(key);
  };

  const handleAddComment = async (htmlString?: string) => {
    if (!htmlString) return;
    await addComment({ accountRecommendationId: accountRecommendationId, comment: htmlString });
  };

  // group events by day using stepper
  const groupedEvents = useMemo(() => {
    if (!events || events.length === 0) return {};
    return events.reduce((acc: Record<string, TRecommendationEventType[]>, event) => {
      const date = new Date(event.date).toLocaleDateString();
      if (!acc[date]) acc[date] = [];
      acc[date].push(event);
      return acc;
    }, {});
  }, [events]);

  const customDot: StepsProps['progressDot'] = () => <CustomDot />;

  return (
    <ColumnsContainer>
      <HistoryEventsContainer>
        {Object.keys(groupedEvents).length > 0 ? (
          Object.entries(groupedEvents).map(([date, events], index) => (
            <div key={index}>
              <GroupedSteps
                direction="vertical"
                progressDot={customDot}
                status="finish"
                items={events.map((event, index) => ({
                  key: event.id || `${event.date}-${event.event_type}-${index}`,
                  title: (
                    <span style={{ fontSize: '0.8rem', color: `#6b6b6b`, fontWeight: '500' }}>
                      {formatDate(event.date)}
                    </span>
                  ),
                  status: 'finish',
                  description: (
                    <EventItem
                      eventType={event}
                      accountRecommendationId={accountRecommendationId}
                      recommendationValidationMethod={recommendationValidationMethod}
                    />
                  ),
                }))}
              />
            </div>
          ))
        ) : (
          <Value>No data recorded for this recommendation so far.</Value>
        )}
      </HistoryEventsContainer>

      <Collapse
        expandIconPosition="right"
        ghost
        expandIcon={({ isActive }) => <DownOutlined style={{ fontSize: '14px' }} rotate={isActive ? -180 : 0} />}
      >
        <Panel header={<span style={{ fontWeight: '500', fontSize: '16px' }}>Add a comment</span>} key="footer">
          <HtmlEditor
            height={100}
            placeholderText="Enter your comment"
            onSave={handleAddComment}
            showClearButton
            showSaveSection={true}
            saveDisabled={isAddingComment}
          />
        </Panel>
      </Collapse>
    </ColumnsContainer>
  );
}

export function EventItem({
  eventType,
  accountRecommendationId,
  recommendationValidationMethod,
  commentReadOnly,
  title,
  showTimeOnly,
}: IEventItem) {
  if (eventType.event_type === 'IsCompliant') {
    return (
      <ComplianceStatusChanges
        event={eventType}
        recommendationValidationMethod={recommendationValidationMethod}
        title={title}
        showTimeOnly={showTimeOnly}
      />
    );
  }

  // Recommendation Exemptions
  if (eventType.event_type === 'IsExempted') {
    return <ExemptionChanges event={eventType} title={title} showTimeOnly={showTimeOnly} />;
  }

  // Recommendation Comments
  if (eventType.event_type === 'comment') {
    return (
      <CommentBlock
        accountRecommendationId={accountRecommendationId}
        event={eventType}
        readOnly={commentReadOnly}
        showTimeOnly={showTimeOnly}
      />
    );
  }

  return <AssignementChanges event={eventType} title={title} showTimeOnly={showTimeOnly} />;
}
