import { BaseActionButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { CircleBottomUpIcon } from '@app/components/icons';
import { Dropdown, Menu, Popover } from 'antd';
import { TActionItems } from '../../AntdTableWrapper/hooks/useTableFilters';
import { IDataTableProps } from '../DataTable';
import { useDataTableContext } from '../store/context';
import { useMemo } from 'react';
import { BasePopoverContent } from '@app/components/common/BaseTypography/BaseTypography';

interface ITableActions<T extends Record<string, unknown>> {
  actionItems: TActionItems<T>[];
}

export function TableActions<T extends Record<string, unknown>>({ actionItems }: ITableActions<T>) {
  const { tableProps } = useDataTableContext<IDataTableProps<T>>();

  const menuItems = useMemo(() => {
    return (
      actionItems
        ?.filter((item) => {
          if (!!tableProps.selectedRows && tableProps.selectedRows.length > 0) {
            if (!!item.show) {
              if (tableProps.selectedRows.length > 0) {
                if (!item.multiSelect) {
                  return item.show(tableProps.selectedRows[0]);
                } else {
                  return item.show(tableProps.selectedRows);
                }
              }
            }
            return true;
          }
          return false;
        })
        .map((item) => {
          const isItemDisabled = () => {
            if (!tableProps.selectedRows || (!!tableProps.selectedRows && tableProps.selectedRows.length < 1)) {
              return true;
            }

            if (!item.multiSelect && tableProps.selectedRows.length > 1) {
              return true;
            }

            if (item.multiSelect && tableProps.selectedRows.length < 1) {
              return true;
            }

            return false;
          };

          return (
            <Menu.Item
              key={item.key}
              disabled={isItemDisabled()}
              onClick={() => {
                if (!!tableProps.selectedRows && tableProps.selectedRows.length > 0) {
                  if (!item.multiSelect && tableProps.selectedRows.length === 1) {
                    item.onClick(tableProps.selectedRows[0]);
                    return;
                  }

                  if (item.multiSelect && tableProps.selectedRows.length > 0) {
                    item.onClick(tableProps.selectedRows);
                    return;
                  }
                }
              }}
            >
              {item.label}
            </Menu.Item>
          );
        }) || []
    );
  }, [actionItems, tableProps.selectedRows]);

  if (menuItems.length < 1 || (!!tableProps.selectedRows && tableProps.selectedRows.length < 1)) {
    return;
  }

  return (
    <Dropdown
      overlay={<Menu>{menuItems}</Menu>}
      trigger={(tableProps.selectedRows || []).length > 0 ? ['click'] : []}
      arrow
    >
      <Popover
        content={<BasePopoverContent>Select an action to perform on the selected rows</BasePopoverContent>}
        placement="topLeft"
      >
        <BaseActionButton>
          <CircleBottomUpIcon />
          <span>Select an action</span>
        </BaseActionButton>
      </Popover>
    </Dropdown>
  );
}
