import OpenFile from '@app/components/icons/open-file/OpenFile';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface NoDataProps {
  withIcon?: boolean;
  mainText?: string;
  subText?: string;
}

const NoDataContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
`;
const IconContainer = styled.div`
  height: 30%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const MainTextSpan = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: var(--grey-950);
`;
const SubTextSpan = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--grey-500);
`;

export const NoData: React.FC<NoDataProps> = ({
  withIcon,
  mainText = 'No data available',
  subText = 'Data for this section isn’t available yet. Please check back later.',
}) => {
  const { t } = useTranslation();
  return (
    <NoDataContainer>
      {withIcon && mainText && (
        <IconContainer>
          <OpenFile style={{ height: '50%', width: '50%' }} fill="var(--grey-1000)" />
        </IconContainer>
      )}
      <TextContainer>
        {mainText && <MainTextSpan>{mainText}</MainTextSpan>}
        {mainText && subText && <SubTextSpan>{subText}</SubTextSpan>}
      </TextContainer>
    </NoDataContainer>
  );
};
