/* eslint-disable no-var */
import {
  useCreateGroup,
  useDeleteGroup,
  useGetGroupPermissions,
  useGetGroups,
  useUpdateGroup,
} from '@app/api/groups.api';
import ConfirmationModal from '@app/components/apps/manage-groups/ConfirmationModal';
import DeleteConfirmationModal from '@app/components/apps/manage-groups/DeleteConfirmationModal';
import GroupForm from '@app/components/apps/manage-groups/GroupForm';
import useGroups from '@app/components/apps/manage-groups/useGroups';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { getEnumValue } from '@app/services/enum.service';
import { TGroupExtended } from '@app/types/groupExtended';
import { TGroupPermission } from '@app/types/groupPermission';
import { format } from 'date-fns';
import { useState } from 'react';

const manageGroupsTableCols: TDataColumns<TGroupExtended> = [
  {
    title: 'Group Name',
    dataIndex: 'name',
    type: 'text',
    showSortDirections: true,
    allowFiltering: true,
    width: '10vw',
  },
  {
    title: 'Creation Date',
    dataIndex: 'creationTime',
    type: 'date',
    width: '10vw',
    render: (text) => format(new Date(text), 'MM/dd/yyyy'),
  },
  {
    title: 'Last Update Time',
    dataIndex: 'lastUpdatedTime',
    type: 'date',
    width: '10vw',
    render: (text) => format(new Date(text), 'MM/dd/yyyy hh:mm aa'),
  },
  {
    title: 'No. Of Members',
    dataIndex: 'numberOfUsers',
    type: 'number',
    width: '10vw',
  },
];

const ManageGroupsPage = () => {
  const [selectedGroup, setSelectedGroup] = useState<TGroupExtended | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { mutateAsync: deleteGroup, isLoading: isDeleting } = useDeleteGroup();
  const { mutateAsync: createGroup, isLoading: isCreating } = useCreateGroup();
  const { mutateAsync: updateGroup, isLoading: isUpdating } = useUpdateGroup();
  const { data: groupPermissions } = useGetGroupPermissions();
  const { data: groups } = useGetGroups();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const { toggle, ...groupFormProps } = useGroups({
    onOk: ({
      id,
      name,
      description,
      creationTime,
      lastUpdatedTime,
      numberOfUsers,
      status,
      accountId,
      permissions,
      recommendationScopingEnabled,
      recommendationsFilter,
    }) => {
      setSelectedGroup({
        id: id || '',
        name,
        description,
        creationTime,
        lastUpdatedTime,
        numberOfUsers,
        status,
        accountId,
        permissions,
        recommendationScopingEnabled,
        recommendationsFilter,
      });
      setIsConfirmationModalOpen(true);
      setIsEdit(isEdit);
    },
    onCancel: () => {
      setSelectedGroup(undefined);
      setIsEdit(false);
    },
    group: selectedGroup,
  });

  const tableFilterProps = useTableFiltersV2<TGroupExtended>({
    columns: manageGroupsTableCols,
    model: 'groupExtended',
    constantFilter: { status: getEnumValue('GroupStatus', 'Active') },
    searchableColumns: ['name'],
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  var dataTableProps = useDataTable<TGroupExtended>({
    data: tableFilterProps.data?.items || [],
    columns: manageGroupsTableCols,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick: ({
      id,
      name,
      description,
      creationTime,
      lastUpdatedTime,
      numberOfUsers,
      status,
      accountId,
      recommendationScopingEnabled,
      recommendationsFilter,
    }) => {
      setSelectedGroup({
        id,
        name,
        description,
        creationTime,
        lastUpdatedTime,
        numberOfUsers,
        status,
        accountId,
        permissions: Array.isArray(groups)
          ? groups
              ?.find((g: TGroupExtended) => g.id === id)
              ?.permissions.map((permission: TGroupPermission) => permission.id)
          : [],
        recommendationScopingEnabled,
        recommendationsFilter,
      });
      setIsEdit(true);
      toggle();
    },
  });

  const actionItems: TActionItems<TGroupExtended>[] = [
    {
      label: 'Edit',
      key: 'edit',
      multiSelect: false,
      onClick: ({
        id,
        name,
        description,
        creationTime,
        lastUpdatedTime,
        numberOfUsers,
        status,
        accountId,
        recommendationScopingEnabled,
        recommendationsFilter,
      }) => {
        setSelectedGroup({
          id: id || '',
          name,
          description,
          creationTime,
          lastUpdatedTime,
          numberOfUsers,
          status,
          accountId,
          permissions: Array.isArray(groups)
            ? groups
                .find((g: TGroupExtended) => g.id === id)
                ?.permissions.map((permission: TGroupPermission) => permission.id)
            : [],
          recommendationScopingEnabled,
          recommendationsFilter,
        });
        setIsEdit(true);
        toggle();
      },
    },
    {
      label: 'Delete',
      key: 'delete',
      multiSelect: false,
      onClick: (group) => {
        setSelectedGroup(group);
        setIsDeleteConfirmationModalOpen(true);
      },
      show: (group) => Number(group.numberOfUsers) === 0,
    },
  ];

  const resetState = () => {
    setSelectedGroup(undefined);
    setIsConfirmationModalOpen(false);
    toggle();
    dataTableProps.clearSelection();
    tableFilterProps.refetch();
  };

  const handleCreateOrUpdateGroup = async () => {
    if (selectedGroup) {
      const payload = {
        name: selectedGroup.name,
        description: selectedGroup.description,
        permissions: selectedGroup.permissions || [],
        recommendationScopingEnabled: selectedGroup.recommendationScopingEnabled,
        recommendationsFilter: selectedGroup.recommendationsFilter,
      };

      if (isEdit) {
        await updateGroup(
          {
            id: Number(selectedGroup.id),
            payload,
          },
          {
            onSuccess: () => resetState(),
          },
        );
      } else {
        await createGroup(payload, {
          onSuccess: () => resetState(),
        });
      }
    }
  };

  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleDeleteGroup = async () => {
    if (selectedGroup) {
      await deleteGroup(Number(selectedGroup.id));
      tableFilterProps.refetch();
      setIsDeleteConfirmationModalOpen(false);
      dataTableProps.clearSelection();
    }
  };

  const handleDeleteConfirmationModalClose = () => {
    setSelectedGroup(undefined);
    setIsDeleteConfirmationModalOpen(false);
  };

  return (
    <BasePageWrapper
      title="Groups Management"
      description="Create and manage user groups. Use groups allow fine-grained control of permissions and access provided to users in your environment."
    >
      <PageTitle>Groups Management</PageTitle>
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <BaseCTAButton onClick={toggle}>Create Group</BaseCTAButton>
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
        </DataTableV2.Actions>
      </DataTableV2>
      <GroupForm
        {...groupFormProps}
        group={selectedGroup}
        groupPermissions={groupPermissions}
        isEdit={isEdit}
        error={error}
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        message={`Are you sure you want to ${isEdit ? 'Update' : 'Create'} this group?`}
        onOk={handleCreateOrUpdateGroup}
        onCancel={handleConfirmationModalClose}
        isLoading={isCreating || isUpdating}
        confirmButtonMessage={isEdit ? 'Update' : 'Create'}
      />
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen}
        message={`Are you sure you want to delete this group?`}
        onOk={handleDeleteGroup}
        onCancel={handleDeleteConfirmationModalClose}
        isLoading={isDeleting}
      />
    </BasePageWrapper>
  );
};

export default ManageGroupsPage;
