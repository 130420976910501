import { format, parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TimePeriodDropdown } from './TimePeriodDropdown/TimePeriodDropdown';
import { getTimeFrameFromOption, TimeFrameOptions } from '@app/utils/utils';
import { ComplianceDriftsSkeleton } from '../common/Loading/SkeletonLoading';
import { useGetComplianceDrifts } from '@app/api/statistics.api';

const ComplianceDriftsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 180px;
  margin-top: 6px;
`;

const EmptyStateMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  font-size: 16px;
  margin-top: 14px;
  height: 100%;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .filter-option {
    display: flex;
    align-items: center;
    background-color: #fafafa;
    padding: 1px 6px;
    border-radius: 6px;
    font-size: 0.95rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    span {
      &:first-child {
        font-weight: 500;
        font-size: 1rem;
        color: var(--grey-950);
      }

      &:last-child {
        color: var(--grey-500);
        margin-left: 4px;
        font-weight: 400;
        font-size: 0.75rem;
      }
    }
  }

  .all {
    background-color: #f0f0f0;
    color: gray;
    border: 1px solid #e6e6e6;
    &:hover {
      background-color: #e6e6e6;
      color: black;
    }
    &.selected {
      background-color: #e6e6e6;
      color: black;
      border: 1px solid #cccccc;
    }
  }

  .regression {
    background-color: #ffe6e6;
    color: gray;
    border: 1px solid #ffcccc;
    &:hover {
      background-color: #ffcccc;
      color: black;
    }
    &.selected {
      background-color: #ffcccc;
      color: black;
      border: 1px solid #ff9999;
    }
  }

  .remediation {
    background-color: #e6ffe6;
    color: gray;
    border: 1px solid #ccffcc;
    &:hover {
      background-color: #ccffcc;
      color: black;
    }
    &.selected {
      background-color: #ccffcc;
      color: black;
      border: 1px solid #99ff99;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--onHoverGrey);
  }

  .findings {
    font-weight: 500;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props: { isRegression: boolean }) => (props.isRegression ? '#a50f0f' : '#1f763d')};
  }

  .product-name {
    color: black;
    align-items: left;
    width: 120px;
    overflow: hidden;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .date {
    color: gray;
    font-size: 0.85rem;
    width: 130px;
  }
`;

export function ComplianceDrifts() {
  const [complianceDriftsDate, setComplianceDriftsDate] = useState(getTimeFrameFromOption('last7days'));
  const [selectedFilter, setSelectedFilter] = useState<string | null>('all');
  const [timePeriod, setTimePeriod] = useState<string>('week');

  const navigate = useNavigate();
  const { data: complianceDriftsData, isFetching } = useGetComplianceDrifts(
    complianceDriftsDate.from,
    complianceDriftsDate.to,
  );

  const data = useMemo(
    () =>
      complianceDriftsData ?? {
        total: 0,
        totalNumOfRegressions: 0,
        recentRegressions: [],
        totalNumOfImprovements: 0,
        recentImprovements: [],
        recentComplianceDriftsRecs: [],
      },
    [complianceDriftsData],
  );

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
  };

  const getFilteredData = () => {
    if (selectedFilter === 'all') {
      return data.recentComplianceDriftsRecs;
    }
    if (selectedFilter === 'regression') {
      return data.recentRegressions;
    }
    if (selectedFilter === 'remediation') {
      return data.recentImprovements;
    }
    return [];
  };

  const filteredData = getFilteredData();

  const getEmptyStateMessage = () => {
    if (selectedFilter === 'all') {
      return `No compliance drifts for this ${timePeriod}`;
    }
    if (selectedFilter === 'regression') {
      return `No regressions for this ${timePeriod}`;
    }
    if (selectedFilter === 'remediation') {
      return `No improvements for this ${timePeriod}`;
    }
    return 'No data available';
  };

  const handleTimePeriodSelect = (timeFrame: TimeFrameOptions) => {
    setComplianceDriftsDate(getTimeFrameFromOption(timeFrame));

    const periodMap: { [key in TimeFrameOptions]: string } = {
      last7days: 'week',
      last30days: 'month',
      last12months: 'year',
      last90days: '',
      alltime: '',
    };
    setTimePeriod(periodMap[timeFrame] || 'period');
  };

  return (
    <ComplianceDriftsContent>
      <HeaderContainer>
        <FilterContainer>
          <div
            className={`filter-option all ${selectedFilter === 'all' ? 'selected' : ''}`}
            onClick={() => handleFilterClick('all')}
          >
            <span>{data.total}</span>
            <span>All</span>
          </div>
          <div
            className={`filter-option regression ${selectedFilter === 'regression' ? 'selected' : ''}`}
            onClick={() => handleFilterClick('regression')}
          >
            <span>{data.totalNumOfRegressions}</span>
            <span> Regressions</span>
          </div>
          <div
            className={`filter-option remediation ${selectedFilter === 'remediation' ? 'selected' : ''}`}
            onClick={() => handleFilterClick('remediation')}
          >
            <span>{data.totalNumOfImprovements}</span>
            <span> Remediations</span>
          </div>
        </FilterContainer>
        <TimePeriodDropdown onTimePeriodSelect={handleTimePeriodSelect} />
      </HeaderContainer>
      {isFetching ? (
        <ComplianceDriftsSkeleton />
      ) : filteredData.length === 0 ? (
        <EmptyStateMessage>{getEmptyStateMessage()}</EmptyStateMessage>
      ) : (
        <ListContainer>
          {filteredData.map((record, index) => (
            <ListItem
              key={index}
              onClick={() => {
                navigate(`/recommendations/${record.recommendation_id}`);
              }}
              isRegression={record.change_type === 'regression'}
            >
              <span className="findings">{record.arh_findings}</span>
              <span className="product-name">{record.product_name}</span>
              <span className="date">{format(parseISO(record.change_date), 'MM/dd/yyyy HH:mm')}</span>
            </ListItem>
          ))}
        </ListContainer>
      )}
    </ComplianceDriftsContent>
  );
}
