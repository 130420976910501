import { Checkbox, Radio, RadioChangeEvent, Space } from 'antd';
import { TDataCol, TTextOptionsFilter } from '../../types';
import { useEffect, useMemo, useState } from 'react';
import { TTableFilterContext, useTableFilterContext } from '../../store/context';

export function TextOptionsFilter<T extends Record<string, unknown>>(props: TDataCol<T>) {
  const { filterValuesMap } = useTableFilterContext<TTableFilterContext<T>>();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const textOptionsFilterValue = useMemo(() => {
    return filterValuesMap?.get(props.dataIndex as string) || '';
  }, [filterValuesMap]);

  useEffect(() => {
    if (!textOptionsFilterValue) {
      setSelectedValues([]);
      return;
    }

    const parsedSelectedValues = (JSON.parse(textOptionsFilterValue) as string[]).map((val) => val.replaceAll("'", ''));
    setSelectedValues(parsedSelectedValues);
  }, [textOptionsFilterValue]);

  const handleOnSelect = (isChecked: boolean, value: string) => {
    let newSelectedValues: string[];

    if (isChecked) {
      newSelectedValues = [...selectedValues, value];
    } else {
      newSelectedValues = selectedValues.filter((sv) => sv != value);
    }
    setSelectedValues(newSelectedValues);

    const stringifiedSelectedValues = JSON.stringify(newSelectedValues);
    filterValuesMap?.set(props.dataIndex as string, stringifiedSelectedValues);
  };

  const handleFilterOptionsRadioChange = (e: RadioChangeEvent) => {
    setSelectedValues([e.target.value]);
    filterValuesMap?.set(props.dataIndex as string, JSON.stringify(e.target.value));
  };

  return (
    <div>
      {!!(props as TTextOptionsFilter).multipleSelect ? (
        <Space direction="vertical">
          {(props as TTextOptionsFilter).options.map((opt) => (
            <Checkbox
              key={opt.key}
              checked={selectedValues.includes(opt.key.toLowerCase())}
              onChange={(e) => handleOnSelect(e.target.checked, opt.key.toLowerCase())}
            >
              {opt.value}
            </Checkbox>
          ))}
        </Space>
      ) : (
        <Radio.Group onChange={handleFilterOptionsRadioChange} value={selectedValues[0]}>
          <Space direction="vertical">
            {(props as TTextOptionsFilter).options?.map((opt) => (
              <Radio key={opt.key} value={opt.value}>
                {opt.value}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      )}
    </div>
  );
}
