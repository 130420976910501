import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { Select } from 'antd';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import { TRecommendationForm } from '../RecommendationForm';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { CheckBox } from '@app/components/header/components/searchDropdown/searchOverlay/SearchFilter/SearchFilter.styles';
import { TRecommendation } from '@app/types/recommendation';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 35%;
  flex-shrink: 0;
`;

const FieldArea = styled.textarea`
  flex-basis: 75%;
  background-color: transparent;
  border: 1px solid #acacac;
  border-radius: 10px;
  padding: 12px;
  outline: none;
  resize: none;
`;

type TOption = {
  value: number;
  label: string;
};

interface OverviewFieldsProps {
  productOptions: TOption[];
  control: Control<TRecommendationForm>;
  errors: FieldErrors<TRecommendationForm>;
  register: UseFormRegister<TRecommendationForm>;
  setValue: UseFormSetValue<TRecommendationForm>;
  severityOptions: TOption[];
  operatingSystemOptions: TOption[];
  estimatedWorkOptions: TOption[];
  recommendationScopeOptions: TOption[];
  easeOfFixOptions: TOption[];
  recommendationValidationMethodOptions: TOption[];
  recommendation: TRecommendation | undefined;
}

export default function OverviewFields({
  productOptions,
  control,
  errors,
  register,
  setValue,
  severityOptions,
  operatingSystemOptions,
  estimatedWorkOptions,
  recommendationScopeOptions,
  easeOfFixOptions,
  recommendationValidationMethodOptions,
  recommendation,
}: OverviewFieldsProps) {
  return (
    <FormContainer>
      <HorizontalAlignedContainer>
        <FieldLabel>Findings:</FieldLabel>
        <InputContainer>
          <FieldInput hasError={!!errors.findings} {...register('findings', { required: true })} />
          {!!errors.findings && <ErrorMessage>This field is required</ErrorMessage>}
        </InputContainer>
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel style={{ alignSelf: 'start' }}>Description:</FieldLabel>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <HtmlEditor initialValue={field.value} onChange={(content) => setValue('description', content)} />
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Corrective Action:</FieldLabel>
        <InputContainer>
          <FieldInput hasError={!!errors.correctiveAction} {...register('correctiveAction', { required: false })} />
          {!!errors.correctiveAction && <ErrorMessage>This field is required</ErrorMessage>}
        </InputContainer>
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Estimated Work:</FieldLabel>
        <Controller
          control={control}
          name="estimatedWork"
          render={({ field }) => (
            <Select
              {...field}
              value={field.value}
              showSearch
              style={{ flexBasis: '75%' }}
              options={estimatedWorkOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {estimatedWorkOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Operating System:</FieldLabel>
        <Controller
          control={control}
          name="operatingSystem"
          render={({ field }) => (
            <Select
              {...field}
              value={field.value}
              showSearch
              style={{ flexBasis: '75%' }}
              options={operatingSystemOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {operatingSystemOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Scopes:</FieldLabel>
        <Controller
          control={control}
          name="scopes"
          render={({ field }) => (
            <Select
              {...field}
              mode="multiple"
              allowClear
              value={field.value}
              showSearch
              style={{ flexBasis: '75%' }}
              options={recommendationScopeOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {recommendationScopeOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Tenant Wide:</FieldLabel>
        <InputContainer>
          <Controller
            control={control}
            name="tenantWide"
            render={({ field }) => <CheckBox {...field} checked={field.value} />}
          />
        </InputContainer>
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Product Name:</FieldLabel>
        <Controller
          control={control}
          name="productId"
          render={({ field }) => (
            <Select
              {...field}
              value={field.value}
              showSearch
              style={{ flexBasis: '75%' }}
              options={productOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {productOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Severity:</FieldLabel>
        <Controller
          control={control}
          name="severity"
          render={({ field }) => (
            <Select
              {...field}
              value={field.value}
              showSearch
              style={{ flexBasis: '75%' }}
              options={severityOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {severityOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Ease of Fix:</FieldLabel>
        <Controller
          control={control}
          name="easeOfFix"
          render={({ field }) => (
            <Select
              {...field}
              value={field.value}
              showSearch
              style={{ flexBasis: '75%' }}
              options={easeOfFixOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {easeOfFixOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Reference:</FieldLabel>
        <InputContainer>
          <Controller control={control} name="reference" render={({ field }) => <FieldArea {...field} />} />
          {!!errors.reference && <ErrorMessage>This field is required</ErrorMessage>}
        </InputContainer>
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>User Impact:</FieldLabel>
        <Controller
          control={control}
          name="userImpact"
          render={({ field }) => (
            <HtmlEditor initialValue={field.value} onChange={(content) => setValue('userImpact', content)} />
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Query:</FieldLabel>
        <InputContainer>
          <Controller
            control={control}
            name="query"
            rules={{ required: !!recommendation && (recommendation.validationMethod as any) == 'Automatic' }}
            render={({ field }) => <FieldArea {...field} />}
          />
          {!!errors.query && <ErrorMessage>This field is required</ErrorMessage>}
        </InputContainer>
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Validation Method:</FieldLabel>
        <Controller
          control={control}
          name="validationMethod"
          render={({ field }) => (
            <Select
              {...field}
              value={field.value}
              showSearch
              style={{ flexBasis: '75%' }}
              options={recommendationValidationMethodOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {recommendationValidationMethodOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </HorizontalAlignedContainer>
    </FormContainer>
  );
}
