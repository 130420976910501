import { useSelector } from 'react-redux';
import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { HorizontalAlignedContainer, VerticalContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { RootState } from '@app/store/store';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { Col } from 'antd';
import { useUpdateRecommendationComment } from '@app/api/recommendation.api';
import { TRecommendationEventType } from '@app/types/recommendationHistory';
import { COMPACT_TABS_COLORS, FONT_WEIGHT, RECOMMENDATION_DETAILS_MODAL_COLORS } from '@app/styles/themes/constants';

const EditIcon = styled(EditOutlined)`
  font-size: 14px;
  margin-left: 4px;
  cursor: pointer;
`;

const CommentBlockContainer = styled.div`
  padding: 8px;
  background-color: ${COMPACT_TABS_COLORS.lightGray};
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
  border-radius: 6px;
`;

interface CommentBlockProps {
  event: TRecommendationEventType;
  accountRecommendationId: string;
  readOnly?: boolean;
  showTimeOnly?: boolean;
}

const CommentBlock = ({ event, accountRecommendationId, readOnly, showTimeOnly }: CommentBlockProps) => {
  const { id, date, comment, user: email } = event;

  const [commentValue, setCommentValue] = useState(comment);
  const [isEditMode, setIsEditMode] = useState(false);

  const loggedUserEmail = useSelector<RootState>((state) => state.user.user?.email);

  const isLoggedUsersComment = loggedUserEmail === email;

  const { mutateAsync: updateComment, isLoading: isUpdatingComment } =
    useUpdateRecommendationComment(accountRecommendationId);

  const handleUpdateComment = async (value?: string) => {
    if (!value) return;
    const response = await updateComment({ commentId: id, comment: value, accountRecommendationId });

    if (response.status === 200) {
      setIsEditMode(false);
      setCommentValue(value);
    }
  };

  return (
    <VerticalContainer style={{ gap: '4px' }}>
      <HorizontalAlignedContainer>
        <span
          style={{
            fontSize: '14px',
            fontWeight: `${FONT_WEIGHT.medium}`,
            color: 'var(--grey-950)',
          }}
        >
          {email}
        </span>
        <span
          style={{
            fontSize: '14px',
            marginLeft: '4px',
            color: 'var(--grey-700)',
          }}
        >
          - added a comment
        </span>
        {isLoggedUsersComment && !isEditMode && !readOnly && <EditIcon onClick={() => setIsEditMode(true)} />}
      </HorizontalAlignedContainer>
      <CommentBlockContainer>
        {isEditMode ? (
          <Col style={{ marginBottom: '8px' }}>
            <HtmlEditor
              height={150}
              initialValue={commentValue}
              onSave={handleUpdateComment}
              onDiscard={() => setIsEditMode(false)}
              showSaveSection={true}
              readOnly={!isEditMode}
            />
          </Col>
        ) : (
          <HtmlEditor initialValue={commentValue} readOnly />
        )}
      </CommentBlockContainer>
    </VerticalContainer>
  );
};

export { CommentBlock };
