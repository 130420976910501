import { useBaseSearchInput } from '@app/components/common/BaseSearch/SearchInput/useBaseSearchInput';
import { TTableFilterContext, useTableFilterContext } from '../../store/context';
import { TDataCol } from '../../types';
import { BaseSearchInput } from '@app/components/common/BaseSearch/SearchInput/BaseSearchInput';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { useEffect, useMemo } from 'react';

export function TextFilter<T extends Record<string, unknown>>(props: TDataCol<T>) {
  const { filterValuesMap } = useTableFilterContext<TTableFilterContext<T>>();

  const searchInputProps = useBaseSearchInput({
    placeholder: (props.title as string) || props.altLabel || '',
    debouncedFunc: (newValue) => {
      filterValuesMap?.set(props.dataIndex as string, newValue);
    },
  });

  const textFilterValue = useMemo(() => {
    return filterValuesMap?.get(props.dataIndex as string) || '';
  }, [filterValuesMap]);

  useEffect(() => {
    searchInputProps.onChange(textFilterValue);
  }, [textFilterValue]);

  return (
    <HorizontalAlignedContainer>
      <BaseSearchInput {...searchInputProps} style={{ flexGrow: 1 }} />
    </HorizontalAlignedContainer>
  );
}
