import { Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TimeFrameOptions, getTimeFrameFromOption } from '@app/utils/utils';
import { useNavigate } from 'react-router-dom';
import ChartGroup from '@app/components/dashboard/ChartGroup';
import { ComplianceDrifts } from '@app/components/dashboard/ComplianceDrifts';
import { ComplianceState } from '@app/components/dashboard/ComplianceState';
import { DashboardCard } from '@app/components/dashboard/DashboardCard';
import { TimePeriodDropdown } from '@app/components/dashboard/TimePeriodDropdown/TimePeriodDropdown';
import { LatestAlerts } from '@app/components/dashboard/LatestAlerts';
import { QuickWins } from '@app/components/dashboard/QuickWins';
import { NonCompliantByProduct } from '@app/components/dashboard/NonCompliantByProduct/NonCompliantByProduct';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [trendOverTimeChartValuesDate, setTrendOverChartValuesDate] = useState(getTimeFrameFromOption('last7days'));
  const handleTrendOverTimeDateChange = (value: TimeFrameOptions) => {
    setTrendOverChartValuesDate(getTimeFrameFromOption(value));
  };

  return (
    <BasePageWrapper title="Dashboard">
      <PageTitle>Home</PageTitle>
      <Space direction="vertical" style={{ display: 'flex', gap: '20px' }}>
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <DashboardCard
              title="Compliance State"
              actionCallbackName="Show More"
              actionCallback={() => navigate('/recommendations?$filter=(isExempted eq false)')}
              toolTipContent="This shows the compliance state of recommendations."
            >
              <ComplianceState />
            </DashboardCard>
          </Col>
          <Col span={12}>
            <DashboardCard
              title="Non-compliant Recommendations by Product"
              toolTipContent="The number of currently non-compliant recommendations per product."
            >
              <NonCompliantByProduct />
            </DashboardCard>
          </Col>
        </Row>

        <Row gutter={[20, 0]} style={{ marginTop: '20px', display: 'flex', alignItems: 'stretch' }}>
          <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <DashboardCard title="Quick Wins" toolTipContent="Key improvements organized by product for fast results.">
              <QuickWins />
            </DashboardCard>

            <DashboardCard
              title="Latest Compliance Drifts"
              actionCallbackName="Show More"
              actionCallback={() => navigate('/recommendation-history')}
              toolTipContent="Latest drifts in compliance states for tracked recommendations. You can change the selected time period and click on recommendations to view more details."
            >
              <ComplianceDrifts />
            </DashboardCard>

            <DashboardCard
              title="Latest Alerts"
              actionCallbackName="Show More"
              actionCallback={() => navigate('/alerts-history')}
              toolTipContent="This Shows The Latest Alerts Triggered"
            >
              <LatestAlerts />
            </DashboardCard>
          </Col>
          <Col span={12}>
            <DashboardCard
              title="Trend Over Time"
              toolTipContent="Your account security scores and compliance trends over time."
              render={<TimePeriodDropdown onTimePeriodSelect={handleTrendOverTimeDateChange} />}
            >
              <ChartGroup trendOverTimeChartValuesDate={trendOverTimeChartValuesDate} />
            </DashboardCard>
          </Col>
        </Row>
      </Space>
    </BasePageWrapper>
  );
};

export default HomePage;
