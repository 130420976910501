import { Radio, RadioChangeEvent, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { TrendOverTime } from '@app/types/dashboardData';
import CustomTooltip from './CustomTooltip';
import moment from 'moment';
import { NoData } from '../common/NoData';
import { SubTitle } from './ChartGroup';
import { DashboardCenterContainer } from '../common/BaseLayout/BaseLayout.styled';

interface ILineChartCardProps {
  data?: TrendOverTime;
  isLoading: boolean;
}

export default function BySecurityScoreLineChart({ data, isLoading }: ILineChartCardProps) {
  const [radioValue, setRadioValue] = useState(1);

  const chartData = useMemo(() => {
    if (!data) return [];
    return data
      .slice()
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map((entry) => ({
        total: 100,
        custom: entry.customScore,
        standard: entry.standardScore,
        name: moment(entry.date).format('MMM DD'),
      }));
  }, [data]);

  const onChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const filteredData = useMemo(() => {
    if (radioValue === 1) return chartData;
    if (radioValue === 2) return chartData.map(({ name, standard, total }) => ({ name, standard, total }));
    if (radioValue === 3) return chartData.map(({ name, custom, total }) => ({ name, custom, total }));
    return chartData;
  }, [chartData, radioValue]);

  return (
    <div style={{ backgroundColor: 'white' }}>
      {/* HEADER */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '2lh',
          marginBottom: '8px',
          marginTop: '30px',
        }}
      >
        <SubTitle>By Security Score</SubTitle>
        <div style={{ display: 'flex', fontSize: '.2rem' }}>
          <Radio.Group onChange={onChange} value={radioValue} size="small">
            <Radio value={1} style={{ fontSize: '0.875rem', fontWeight: 400, color: '#272727' }}>
              All
            </Radio>
            <Radio value={2} style={{ fontSize: '0.875rem', fontWeight: 400, color: '#272727' }}>
              Baseline
            </Radio>
            <Radio value={3} style={{ fontSize: '0.875rem', fontWeight: 400, color: '#272727' }}>
              Custom
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <div style={{ height: '400px', width: '100%' }}>
        {isLoading ? (
          <DashboardCenterContainer>
            <Spin />
          </DashboardCenterContainer>
        ) : !chartData || chartData.length === 0 ? (
          <NoData />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={filteredData}>
              <XAxis dataKey="name" dy={10} axisLine={false} tickLine={false} fontSize={'0.625rem'} />
              <YAxis
                dataKey="total"
                type="number"
                tickFormatter={(tick) => `${tick}%`}
                axisLine={false}
                tickLine={false}
                fontSize={'0.625rem'}
              />
              <Tooltip content={CustomTooltip} cursor={{ strokeDasharray: '20 20' }} />
              <Line type="basis" dataKey="custom" strokeWidth={3} stroke="#FFB156" fill="#FFB156" dot={false} />
              <Line type="basis" dataKey="standard" strokeWidth={3} stroke="#8884d8" fill="#8884d8" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}
