import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';

export const useRecommendationScoping = () => {
  const { isBigScreen } = useResponsive();

  const productOptions =
    useAppSelector((state) => state.app.products)?.map((opt) => ({
      key: opt.displayName,
      value: opt.displayName,
    })) || [];
  const licenseOptions =
    useAppSelector((state) => state.app.licenses)?.map((opt) => ({
      key: opt.name,
      value: opt.displayName,
    })) || [];

  const recommendationScopingCols: TDataColumns<TAccountRecommendationExtended> = [
    {
      title: 'ID',
      dataIndex: 'id',
      showSortDirections: true,
      hideColumn: true,
    },
    {
      title: 'Findings',
      dataIndex: 'findings',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: isBigScreen ? '16vw' : '20vw',
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationSeverity',
      showSortDirections: true,
      width: isBigScreen ? '6vw' : '7vw',
    },
    {
      title: 'Impact',
      dataIndex: 'easeOfFix',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationEaseOfFix',
      showSortDirections: true,
      width: isBigScreen ? '5vw' : '6vw',
    },
    {
      title: 'Effort',
      dataIndex: 'estimatedWork',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationEstimatedWork',
      showSortDirections: true,
      width: isBigScreen ? '5vw' : '6vw',
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      align: 'center',
      allowFiltering: true,
      type: 'textOptions',
      options: productOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: isBigScreen ? '6vw' : '8vw',
    },
    {
      title: 'License',
      dataIndex: 'licenseNames',
      align: 'center',
      allowFiltering: true,
      type: 'textOptions',
      options: licenseOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: '10vw',
      hideColumn: true,
    },
    // {
    //   title: 'Compliance',
    //   dataIndex: 'isCompliant',
    //   allowFiltering: true,
    //   type: 'enum',
    //   enumValuesKey: 'BooleanEnum',
    //   showSortDirections: true,
    //   width: isBigScreen ? '5vw' : '9vw',
    //   align: 'center',
    // },
    {
      title: 'Exemption',
      dataIndex: 'isExempted',
      allowFiltering: false,
      type: 'boolean',
      showSortDirections: true,
      width: isBigScreen ? '5vw' : '8vw',
      align: 'center',
    },
    {
      title: 'Validation Method',
      altLabel: 'Validation Method',
      dataIndex: 'validationMethod',
      allowFiltering: true,
      showSorterTooltip: false,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'RecommendationValidationMethod',
      showSortDirections: false,
      width: '5vw',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '25vw',
      hideColumn: true,
    },
    {
      title: 'Operating System',
      dataIndex: 'operatingSystem',
      allowFiltering: true,
      showSorterTooltip: false,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'OperatingSystem',
      showSortDirections: false,
      hideColumn: true,
      width: '10vw',
    },
    {
      title: 'Scopes',
      dataIndex: 'scopes',
      allowFiltering: true,
      type: 'enumArray',
      enumValuesKey: 'RecommendationScope',
      showSortDirections: true,
      width: '25vw',
      hideColumn: true,
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '25vw',
      hideColumn: true,
    },
    {
      title: 'Tenant Wide',
      dataIndex: 'tenantWide',
      allowFiltering: true,
      type: 'boolean',
      showSortDirections: true,
      width: '25vw',
      hideColumn: true,
    },
    {
      title: 'Assigned To',
      dataIndex: 'assigneeEmail',
      allowFiltering: false,
      type: 'text',
      align: 'center',
      showSortDirections: true,
      width: '20vw',
      hideColumn: true,
    },
    {
      title: 'Exemption Reason',
      dataIndex: 'exemptionReason',
      align: 'center',
      allowFiltering: false,
      type: 'text',
      showSortDirections: true,
      width: '12vw',
      hideColumn: true,
    },
    {
      title: 'Exempted By',
      dataIndex: 'exemptedByEmail',
      align: 'center',
      allowFiltering: false,
      type: 'text',
      showSortDirections: true,
      width: '5vw',
      hideColumn: true,
    },

    {
      title: 'Exemption Date',
      dataIndex: 'exemptionDate',
      allowFiltering: false,
      type: 'date',
      showSortDirections: true,
      width: '5vw',
      hideColumn: true,
    },
    {
      title: 'Assignment Date',
      dataIndex: 'assignmentTime',
      allowFiltering: true,
      type: 'date',
      showSortDirections: true,
      width: '5vw',
      hideColumn: true,
    },
    {
      title: 'Assigned By',
      dataIndex: 'assignedByEmail',
      align: 'center',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '5vw',
      hideColumn: true,
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   align: 'center',
    //   allowFiltering: true,
    //   type: 'enum',
    //   enumValuesKey: 'RecommendationCategory',
    //   showSortDirections: true,
    //   width: 100,
    // },
  ];

  return { recommendationScopingCols };
};
