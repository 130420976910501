import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TTableFilterContext, useTableFilterContext } from '../../store/context';
import { TDataCol } from '../../types';
import { Dropdown, MenuProps } from 'antd';
import { DEFAULT_SELECTED_OPERATOR, NUMBER_OPERATORS } from '../../constants';
import styled from 'styled-components';
import { ChevronDownIcon } from '@app/components/icons';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

const OperatorsDropdownTrigger = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9eaeb;
  gap: 16px;
  border: 1px solid #acacac;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
`;

const OperatorsText = styled.span`
  color: #6d6d6d;
  font-weight: 600;
`;

const NumberInputContainer = styled.div`
  padding: 10px 12px;
  border: 1px solid #acacac;
  border-radius: 10px;
`;

const NumberInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  outline: none;
`;

export function NumberFilter<T extends Record<string, unknown>>(props: TDataCol<T>) {
  const { filterValuesMap } = useTableFilterContext<TTableFilterContext<T>>();
  const [value, setValue] = useState<string | undefined>(); // value should contain the operator and the number separated by a space;

  const numberFilterValue = useMemo(() => {
    return filterValuesMap?.get(props.dataIndex as string) || '';
  }, [filterValuesMap]);

  const operatorValue = useMemo(() => {
    return NUMBER_OPERATORS.find((op) =>
      !!value ? op.value === value?.split(' ')[0] : op.value === DEFAULT_SELECTED_OPERATOR,
    );
  }, [value]);

  const numberValue = useMemo(() => {
    return value?.split(' ')?.[1]?.replaceAll("'", '') || '';
  }, [value]);

  useEffect(() => {
    setValue(numberFilterValue);
  }, [numberFilterValue]);

  const handleOperatorChange: MenuProps['onClick'] = (info) => {
    const newValue = `${NUMBER_OPERATORS.find((op) => op.value === info.key)?.value} ${numberValue}`;
    setValue(newValue);
    filterValuesMap?.set(props.dataIndex as string, newValue);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = `${operatorValue?.value} ${e.target.value}`;
    setValue(newValue);
    filterValuesMap?.set(props.dataIndex as string, newValue);
  };

  return (
    <HorizontalAlignedContainer style={{ marginBottom: '12px', gap: '12px' }}>
      <Dropdown
        menu={{
          items: NUMBER_OPERATORS.map((op) => ({ label: op.title, key: op.value })),
          onClick: handleOperatorChange,
          selectable: true,
          defaultSelectedKeys: ['0'],
        }}
        trigger={['click']}
      >
        <OperatorsDropdownTrigger>
          <OperatorsText>{operatorValue?.title}</OperatorsText>
          <ChevronDownIcon fill="#3A50D1" />
        </OperatorsDropdownTrigger>
      </Dropdown>

      <NumberInputContainer>
        <NumberInput type="number" onChange={handleInputChange} value={numberValue} />
      </NumberInputContainer>
    </HorizontalAlignedContainer>
  );
}
