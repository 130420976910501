import { IconProps } from '../types';

export default function CircleBottomUpIcon(props: IconProps) {
  const { fill = 'currentColor', style } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g clipPath="url(#clip0_4274_4329)">
        <path
          d="M1.83301 7.99992C1.83301 4.59416 4.59392 1.83325 7.99967 1.83325C11.4054 1.83325 14.1663 4.59416 14.1663 7.99992C14.1663 11.4057 11.4054 14.1666 7.99967 14.1666C7.72353 14.1666 7.49967 14.3904 7.49967 14.6666C7.49967 14.9427 7.72353 15.1666 7.99967 15.1666C11.9577 15.1666 15.1663 11.958 15.1663 7.99992C15.1663 4.04188 11.9577 0.833252 7.99967 0.833252C4.04163 0.833252 0.833008 4.04188 0.833008 7.99992C0.833008 8.27606 1.05687 8.49992 1.33301 8.49992C1.60915 8.49992 1.83301 8.27606 1.83301 7.99992Z"
          fill={fill}
        />
        <path
          d="M3.33301 8.16659C3.05687 8.16659 2.83301 8.39044 2.83301 8.66659C2.83301 8.94273 3.05687 9.16659 3.33301 9.16659H6.1259L1.64612 13.6464C1.45086 13.8416 1.45086 14.1582 1.64612 14.3535C1.84138 14.5487 2.15797 14.5487 2.35323 14.3535L6.83301 9.87369V12.6666C6.83301 12.9427 7.05687 13.1666 7.33301 13.1666C7.60915 13.1666 7.83301 12.9427 7.83301 12.6666V8.66659C7.83301 8.39044 7.60915 8.16659 7.33301 8.16659H3.33301Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4274_4329">
          <rect width="16" height="16" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
