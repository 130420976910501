import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import FontSize from 'tiptap-extension-font-size';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import { Extension } from '@tiptap/core';

import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import { Video } from './extensions/VideoExtension';
import { ExtendedImage } from './extensions/ExtendedImage';
import Placeholder from '@tiptap/extension-placeholder';

import {
  FaBold,
  FaItalic,
  FaStrikethrough,
  FaUnderline,
  FaRulerHorizontal,
  FaLink,
  FaCode,
  FaList,
  FaListOl,
  FaImage,
} from 'react-icons/fa6';
import { TbBlockquote } from 'react-icons/tb';
import { BiUndo, BiRedo } from 'react-icons/bi';
import StylingDropdown from './StylingDropDown';
import { TbClearFormatting } from 'react-icons/tb';

import { HorizontalAlignedContainer, VerticalContainer } from '../BaseLayout/BaseLayout.styled';
import ColorPicker from './ColorPicker';
import TextAlignDropdown from './TextAlignDropdown';
import FontSizePicker from './FontSizePicker';
import { LineHeight } from './extensions/LineHeightExtension';
import LineHeightDropdown from './LineHeightDropdown';
import { LoadingNode } from './extensions/LoadingNode';
import { BASE_COLORS, COMPACT_TABS_COLORS } from '@app/styles/themes/constants';

const FieldArea = styled.div<{ height?: number }>`
  flex-basis: 75%;
  background-color: white;
  border: 1px solid #acacac;
  border-radius: 6px;
  outline: none;
  resize: none;
  overflow-x: auto;
  word-wrap: break-word;

  .ProseMirror {
    height: ${(props) => (props.height ? `${props.height}px` : '150px')};
    border: none;
  }

  .ProseMirror {
    line-height: inherit;
  }

  :first-child {
    margin-top: 0;
  }

  p.is-editor-empty:first-child::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
    color: ${BASE_COLORS.gray};
  }

  hr {
    border: none;
    border-top: 1px solid gray;
    cursor: pointer;
    margin: 1.5rem 0;

    &.ProseMirror-selectednode {
      border-top: 1px solid #60a5fa;
    }
  }

  img {
    display: block;
    height: auto;
    margin: 1.5rem 0;
    max-width: 100%;

    &.ProseMirror-selectednode {
      outline: 2px solid #60a5fa;
    }
  }
`;

const MenuBarContainer = styled.div`
  background-color: ${COMPACT_TABS_COLORS.lightGray};
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
`;

const MenuBarButton = styled.button<{ isActive?: boolean }>`
  background-color: ${(props) => (props.isActive ? 'black' : 'transparent')};
  border: none;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.isActive ? 'white' : 'black')};

  &:hover {
    background-color: ${(props) => (props.isActive ? 'black' : 'lightgray')};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

const SaveButton = styled.button`
  border: 1px solid ${BASE_COLORS.violetButton};
  border-radius: 6px;
  background-color: ${BASE_COLORS.violetButton};
  padding: 12px 28px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  width: 10vw;
  cursor: pointer;

  &:disabled {
    background-color: #acacac;
    border-color: #acacac;
    cursor: default;
  }
`;

const DiscardButton = styled.button`
  border: 1px solid #272727;
  border-radius: 6px;
  background-color: transparent;
  padding: 12px 20px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  width: 10vw;
  cursor: pointer;

  &:disabled {
    background-color: #acacac;
    border-color: #acacac;
    color: white;
    cursor: default;
  }
`;

const ClearButton = styled.button`
  border: 1px solid #272727;
  border-radius: 6px;
  background-color: transparent;
  padding: 12px 20px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  width: 10vw;
  cursor: pointer;

  &:disabled {
    border-color: #acacac;
    color: ${BASE_COLORS.gray};
    cursor: default;
  }
`;

interface MenuBarProps {
  editor: Editor | null;
  enableMedia: boolean;
  onMediaSelected?: (file: File[], previewUrl: string[]) => void;
}

const MenuBar = ({ editor, enableMedia, onMediaSelected }: MenuBarProps) => {
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  const setLink = useCallback((editor: Editor) => {
    const previousUrl = editor.getAttributes('link').href;
    const initialUrl = previousUrl || 'https://';
    const url = window.prompt('Enter URL', initialUrl);

    if (url === null) {
      return;
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    const textToDisplay = window.prompt('Enter Text to Display', 'Link text');

    if (textToDisplay === null) {
      return;
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    if (textToDisplay !== '') {
      editor.commands.insertContent(`<a href="${url}" target="_blank" rel="noopener noreferrer">${textToDisplay}</a>`);
    }
  }, []);

  const setMedia = useCallback(
    (editor: Editor) => {
      if (!enableMedia) return;

      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*,video/*';
      input.multiple = true;

      input.onchange = async (event) => {
        const files = Array.from((event.target as HTMLInputElement).files || []);

        if (files.length > 0) {
          const newPreviewUrls = files.map((file) => URL.createObjectURL(file));

          setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);

          onMediaSelected?.(files, newPreviewUrls);

          files.forEach((file, index) => {
            const previewUrl = newPreviewUrls[index];
            if (file.type.includes('image/')) {
              editor.chain().focus().setImage({ src: previewUrl, title: file.name, alt: file.name }).run();
            } else if (file.type.includes('video/')) {
              editor.chain().focus().setVideo({ src: previewUrl, height: '300px' }).run();
            }
          });
        }
      };

      input.click();
    },
    [enableMedia, onMediaSelected],
  );

  useEffect(() => {
    return () => {
      previewUrls.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [previewUrls]);

  if (!editor) {
    return null;
  }

  return (
    <MenuBarContainer>
      <StylingDropdown editor={editor} />
      <FontSizePicker editor={editor} />
      <GroupContainer>
        <MenuBarButton
          title="bold"
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          isActive={editor.isActive('bold')}
        >
          <FaBold />
        </MenuBarButton>
        <MenuBarButton
          title="italic"
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          isActive={editor.isActive('italic')}
        >
          <FaItalic />
        </MenuBarButton>
        <MenuBarButton
          title="strike-through"
          type="button"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().unsetUnderline().run()}
          isActive={editor.isActive('underline')}
        >
          <FaUnderline />
        </MenuBarButton>
        <MenuBarButton
          title="strike-through"
          type="button"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          isActive={editor.isActive('strike')}
        >
          <FaStrikethrough />
        </MenuBarButton>

        <ColorPicker editor={editor} />
        <ColorPicker editor={editor} isTextHighlight={true} />
      </GroupContainer>

      <TextAlignDropdown editor={editor} />

      <LineHeightDropdown editor={editor} />

      <GroupContainer>
        <MenuBarButton
          title="code block"
          type="button"
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          isActive={editor.isActive('code')}
        >
          <FaCode />
        </MenuBarButton>
        <MenuBarButton
          title="block quote"
          type="button"
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          isActive={editor.isActive('blockquote')}
        >
          <TbBlockquote />
        </MenuBarButton>
      </GroupContainer>

      <GroupContainer>
        <MenuBarButton
          title="bullet list"
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive('bulletList')}
        >
          <FaList />
        </MenuBarButton>
        <MenuBarButton
          title="ordered list"
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive('orderedList')}
        >
          <FaListOl />
        </MenuBarButton>
      </GroupContainer>

      <GroupContainer>
        <MenuBarButton
          title="insert link"
          type="button"
          onClick={() => setLink(editor)}
          isActive={editor.isActive('link')}
        >
          <FaLink />
        </MenuBarButton>

        {enableMedia && (
          <MenuBarButton
            title="Add media"
            type="button"
            onClick={() => setMedia(editor)}
            isActive={editor.isActive('media')}
          >
            <FaImage />
          </MenuBarButton>
        )}

        <MenuBarButton
          title="Horizontal rule"
          type="button"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <FaRulerHorizontal />
        </MenuBarButton>

        <MenuBarButton title="clear formatting" type="button" onClick={() => editor.chain().focus().clearNodes().run()}>
          <TbClearFormatting />
        </MenuBarButton>

        <MenuBarButton
          type="button"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <BiUndo />
        </MenuBarButton>

        <MenuBarButton
          type="button"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <BiRedo />
        </MenuBarButton>
      </GroupContainer>

      {/* <button type="button" onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          Clear marks
        </button> */}
      {/* <button type="button" onClick={() => editor.chain().focus().setParagraph().run()}>
          Paragraph
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}>
          H1
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}>
          H2
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}>
          H3
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}>
          H4
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}>
          H5
        </button>
        <button type="button" onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}>
          H6
        </button> */}
      {/* <button type="button" onClick={() => editor.chain().focus().toggleBlockquote().run()}>
          Blockquote
        </button>
        <button type="button" onClick={() => editor.chain().focus().setHardBreak().run()}>
          Hard break
        </button> */}
    </MenuBarContainer>
  );
};

const getExtensions = (enableMedia: boolean, placeholderText?: string) => {
  const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false,
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false,
      },
    }),
    Placeholder.configure({
      placeholder: placeholderText ?? 'Type something...',
    }),
    TextStyle,
    Color,
    Underline,
    FontSize.configure({
      types: ['textStyle'],
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: 'https',
    }),
    Highlight.configure({
      multicolor: true,
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    LineHeight.configure({
      types: ['heading', 'paragraph'],
      heights: ['1.0', '1.15', '1.5', '2.0'],
      defaultHeight: '1.0',
    }),
    LoadingNode,
  ];

  if (enableMedia) {
    extensions.push(ExtendedImage as Extension);
    extensions.push(Video as Extension);
  }

  return extensions;
};

export interface HtmlEditorProps {
  initialValue?: string;
  height?: number;
  showSaveSection?: boolean;
  saveDisabled?: boolean;
  readOnly?: boolean;
  enableMedia?: boolean;
  showClearButton?: boolean;
  placeholderText?: string;
  onMediaSelected?: (file: File[], previewUrl: string[]) => void;
  onMediaNodesChange?: (mediaUrls: string[]) => void;
  onSave?: (savedValue?: string) => void;
  onDiscard?: () => void;
  onChange?: (htmlValue: string) => void;
}

export default function HtmlEditor({
  initialValue,
  height,
  showSaveSection,
  saveDisabled,
  readOnly,
  enableMedia = false,
  showClearButton = false,
  placeholderText,
  onMediaSelected,
  onMediaNodesChange,
  onChange,
  onSave,
  onDiscard,
}: HtmlEditorProps) {
  const editor = useEditor({
    extensions: getExtensions(enableMedia, placeholderText),
    content: initialValue || '',
    editable: !readOnly,
    editorProps: {
      attributes: {
        class: readOnly ? 'read-only' : '',
      },
    },
    onUpdate: (props) => {
      onChange?.(props.editor.getHTML());

      const html = editor?.getHTML();
      if (html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const mediaElements = doc.querySelectorAll('img[src], video[src]');
        const mediaUrls = Array.from(mediaElements).map((el) => el.getAttribute('src') || '');
        onMediaNodesChange?.(mediaUrls);
      }
    },
  });

  const handleSave = () => {
    onSave?.(editor?.getHTML());
    editor?.commands.clearContent();
  };

  const handleClear = () => {
    editor?.commands.clearContent();
  };

  if (readOnly) {
    return <EditorContent editor={editor} />;
  }

  return (
    <>
      <FieldArea height={height}>
        <VerticalContainer>
          <MenuBar editor={editor} enableMedia={enableMedia} onMediaSelected={onMediaSelected} />
          <EditorContent editor={editor} />
        </VerticalContainer>
      </FieldArea>
      {showSaveSection && (
        <HorizontalAlignedContainer
          style={{
            gap: '8px',
            marginTop: '12px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {onDiscard && (
            <DiscardButton type="button" onClick={() => onDiscard()}>
              Discard
            </DiscardButton>
          )}
          {showClearButton && (
            <ClearButton type="button" onClick={handleClear} disabled={editor?.getText().length === 0}>
              Erase
            </ClearButton>
          )}
          <SaveButton type="button" disabled={editor?.getText().length === 0 || saveDisabled} onClick={handleSave}>
            Save
          </SaveButton>
        </HorizontalAlignedContainer>
      )}
    </>
  );
}
