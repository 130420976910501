import { useMutation, useQuery, useQueryClient } from 'react-query';
import { httpApi } from '@app/api/http.api';
import { TAccountStatusInfo } from '@app/types/accountStatusInfo';
import { TAccountSetupData } from '@app/types/accountSetupData';
import { TManageAccountsForm } from '@app/components/apps/manage-accounts/ManageAccountsForm';
import { notificationController } from '@app/controllers/notificationController';
import { getEnumValue } from '@app/services/enum.service';
import { TEnumEntity } from '@app/types/enumEntity';
import { TManageAccounts } from '@app/types/manageAccounts';

const finalizeAccountSignUp = async (setupData: TAccountSetupData): Promise<string> => {
  const response = await httpApi.post('/account/finishSetup', setupData);
  return response.data;
};

export const useFinalizeAccountSignUp = () =>
  useMutation({
    mutationFn: finalizeAccountSignUp,
  });

const getAccountStatusInfo = async (): Promise<TAccountStatusInfo> => {
  const response = await httpApi.get<TAccountStatusInfo>('/account/status');
  return response.data;
};

export const useGetAccountStatusInfo = (fetchStats?: boolean) =>
  useQuery({
    queryKey: 'account-status-info',
    enabled: fetchStats,
    queryFn: () => getAccountStatusInfo(),
    refetchInterval: (data, query) => {
      if (data?.status === getEnumValue('AccountStatus', 'PendingInitialScan')) {
        return 30000;
      }
      return false;
    },
  });

export const checkAppAccess = async (): Promise<string> => {
  const { data } = await httpApi.post<{ connectionStatus: string }>('/account/checkAppAccess');
  return data.connectionStatus;
};

const getAccount = async (): Promise<TManageAccounts> => {
  const response = await httpApi.get<TManageAccounts>('/account');
  return response.data;
};

export const useGetAccount = () =>
  useQuery({
    queryKey: 'get-account',
    queryFn: () => getAccount(),
  });

const createAccount = async (data: TManageAccountsForm) => {
  const response = await httpApi.post<any>('/account', data);

  if (response?.status === 201) {
    notificationController.success({
      message: `Account Created`,
    });
  }

  return response?.data;
};

export const useCreateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAccount,
    onSuccess() {
      queryClient.invalidateQueries('accountExtended-odata');
    },
  });
};

const updateAccount = async (payload: { id: string; data: Partial<TManageAccountsForm> }) => {
  const { id, data } = payload;
  try {
    const response = await httpApi.patch<any>(`/account/${id}`, data);

    if (response?.status === 200) {
      notificationController.success({
        message: `Account Updated`,
      });
    }
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const useUpdateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAccount,
    onSuccess() {
      queryClient.invalidateQueries('accountExtended-odata');
    },
  });
};

const deleteAccount = async (accountId: string) => {
  try {
    const response = await httpApi.delete(`/account/${accountId}`);

    if (response?.status === 200) {
      notificationController.success({
        message: `Account Deleted`,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAccount,
    onSuccess() {
      queryClient.invalidateQueries('accountExtended-odata');
    },
  });
};

const completeAccountSetup = async (data: { appId: string; appSecret: string }) => {
  const response = await httpApi.post<any>(`/account/completeSetup`, data);

  if (response?.status === 200) {
    notificationController.success({
      message: `Completed Account Setup`,
    });
  }

  return response?.data;
};

export const useCompleteAccountSetup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: completeAccountSetup,
  });
};

const scanAccount = async (id: string) => {
  httpApi.post<any>(`/account/scan/${id}`);
  notificationController.success({
    message: `Account Scan Initialized`,
  });
};

export const useScanAccount = () => {
  return useMutation({
    mutationFn: scanAccount,
    onSuccess() {
      console.log('scan');
    },
  });
};

export const getAccountProducts = async (): Promise<TEnumEntity[]> => {
  const response = await httpApi.get<TEnumEntity[]>('/account/products');
  if (response.status === 403) return [];
  return response.data;
};

export const useGetAccountProducts = () =>
  useQuery({
    queryKey: 'account-products',
    queryFn: getAccountProducts,
  });

const updateSettings = async ({ productIds }: { productIds: number[] }) => {
  const response = await httpApi.patch<any>('/account/update-settings', { productIds });
  if (response.status !== 200) throw undefined;
  return response.data;
};

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSettings,
    onSuccess() {
      queryClient.invalidateQueries('account-products');
      notificationController.success({ message: `Successfully updated the connected products.` });
    },
  });
};
