import styled from 'styled-components';

export const ConnectorStatus = styled.div`
  width: 8px;
  height: 8px;
  background-color: var(--green-500);
  border-radius: 100%;
  position: absolute;
  bottom: 4px;
  right: 4px;
`;
