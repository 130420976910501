import { useMemo } from 'react';
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import { ChevronDownIconV2, ChevronLeftIcon, ChevronRight } from '@app/components/icons';
import styled from 'styled-components';
import { CenterContainer, SpaceGrower } from '@app/components/common/BaseLayout/BaseLayout.styled';

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Texts = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--grey-400);
`;

const LimitText = styled(Texts)`
  font-weight: 400;
  color: var(--blue-800);
`;

const PaginationRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const RowsDropdownTrigger = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--blue-200);
  gap: 8px;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: var(--white);
  cursor: pointer;
`;

const PagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px;
  background-color: var(--white);
  margin-left: 8px;
  gap: 12px;
`;

const PreviousNextIconContainer = styled(CenterContainer)`
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background-color: var(--ashgrey);
  }
`;

const PageNumber = styled.div`
  padding: 8px 16px;
  background-color: var(--white);
  color: var(--grey-400);
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;

  :hover {
    background-color: var(--ashgrey);
  }
`;

const PageNumberWithEllipsis = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  padding: 8px 16px;
  background-color: var(--white);
  color: var(--grey-400);
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;

  :hover {
    background-color: var(--ashgrey);
  }
`;

const ActivePageNumber = styled.div`
  padding: 8px 16px;
  background-color: var(--blue-50);
  border-radius: 5px;
  color: var(--grey-950);
  font-size: 0.75rem;
  font-weight: 500;
`;

const MAX_PAGE_COUNT = 4;

export interface IPaginationProps {
  currentPage: number;
  limit: number;
  totalRows: number;
  limitIntervals: number[];
  totalPages: number;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  selectedRows?: number;
  canSelectRows?: boolean;
  onNext?: (currentPage: number) => void;
  onPrev?: (currentPage: number) => void;
  onLimitChanged?: (newLimit: number) => void;
  onPageNumberChange?: (newSkipInterval: number) => void;
}

export default function Pagination({
  currentPage,
  limit,
  limitIntervals,
  totalRows,
  totalPages,
  isNextDisabled,
  isPrevDisabled,
  selectedRows = 0,
  canSelectRows = true,
  onNext,
  onPrev,
  onLimitChanged,
  onPageNumberChange,
}: IPaginationProps) {
  const rowIntervals = useMemo(() => {
    return limitIntervals.map((rowNum, index) => ({
      key: index,
      label: rowNum,
    }));
  }, [limitIntervals]);

  const isActivePageNearTheStart = currentPage <= 3;
  const isActivePageNearTheEnd = totalPages - currentPage <= 1;

  const totalPageRows = useMemo(() => {
    return limit > totalRows ? totalRows : limit;
  }, [limit, totalRows]);

  const visiblePages = useMemo(() => {
    if (totalRows === 0) {
      return [1];
    }

    if (totalRows !== 0 && totalRows < limit * MAX_PAGE_COUNT) {
      const pageCount = Math.ceil(totalRows / limit);
      return Array.from({ length: pageCount }, (_, i) => i + 1);
    }

    if (isActivePageNearTheStart) {
      return [1, 2, 3, 4];
    }

    if (isActivePageNearTheEnd) {
      return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }

    return [currentPage - 1, currentPage, currentPage + 1];
  }, [currentPage, totalPages]);

  const handleOnRowIntervalChange = (e: any) => {
    const index = parseInt(e.key);
    const newLimit = limitIntervals[index];
    onLimitChanged?.(newLimit);
  };

  return (
    <PaginationContainer>
      {canSelectRows && <Texts>{`Selected: ${selectedRows} of ${totalPageRows}`}</Texts>}

      <SpaceGrower />

      <PaginationRightContainer>
        <Texts>Show rows</Texts>

        <Dropdown
          menu={{
            items: rowIntervals,
            onClick: handleOnRowIntervalChange,
            selectable: true,
            defaultSelectedKeys: [`${rowIntervals.find((r) => r.label === limit)?.key || 0}`],
          }}
          trigger={['click']}
        >
          <RowsDropdownTrigger>
            <LimitText>{limit}</LimitText>
            <ChevronDownIconV2 />
          </RowsDropdownTrigger>
        </Dropdown>

        <PagesContainer>
          <PreviousNextIconContainer
            onClick={() => {
              if (isPrevDisabled) {
                return;
              }
              onPrev?.(currentPage);
            }}
          >
            <ChevronLeftIcon fill={isPrevDisabled ? '#B0B0B0' : '#141414'} />
          </PreviousNextIconContainer>
          {(isActivePageNearTheEnd || (!isActivePageNearTheStart && !isActivePageNearTheEnd)) &&
          totalRows > limit * MAX_PAGE_COUNT ? (
            <PageNumberWithEllipsis onClick={() => onPageNumberChange?.(1)}>
              1 <EllipsisOutlined style={{ marginLeft: '4px' }} />
            </PageNumberWithEllipsis>
          ) : null}
          {visiblePages.map((page) => {
            if (page === currentPage) {
              return (
                <ActivePageNumber key={page} onClick={() => onPageNumberChange?.(page)}>
                  {page}
                </ActivePageNumber>
              );
            }

            return (
              <PageNumber key={page} onClick={() => onPageNumberChange?.(page)}>
                {page}
              </PageNumber>
            );
          })}
          {(isActivePageNearTheStart || (!isActivePageNearTheStart && !isActivePageNearTheEnd)) &&
          totalRows > limit * MAX_PAGE_COUNT ? (
            <div>
              <PageNumberWithEllipsis onClick={() => onPageNumberChange?.(totalPages)}>
                <EllipsisOutlined style={{ marginRight: '4px' }} /> {totalPages}
              </PageNumberWithEllipsis>
            </div>
          ) : null}
          <PreviousNextIconContainer
            onClick={() => {
              if (isNextDisabled) {
                return;
              }
              onNext?.(currentPage);
            }}
          >
            <ChevronRight fill={isNextDisabled ? '#B0B0B0' : '#141414'} />
          </PreviousNextIconContainer>
        </PagesContainer>
      </PaginationRightContainer>
    </PaginationContainer>
  );
}
