import { useTranslation } from 'react-i18next';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { TInsight } from '@app/types/insight';
import { Collapse, Divider } from 'antd';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import InsightsLightBulb from '@app/components/icons/lightbulb/InsightsLightBulb';
import styled from 'styled-components';
import { getEnumValue } from '@app/services/enum.service';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
`;

const StyledCollapse = styled(Collapse)`
  border: none;
  border-radius-bottom: 0px;
  background-color: white;
  .ant-collapse-item-active {
    background-color: var(--blue-50);
  }
  .ant-collapse-content {
    background-color: var(--blue-50);
    padding: 0px;
  }
  &:hover {
    background-color: var(--blue-50);
  }
`;
const StyledCollapsePanel = styled(Collapse.Panel)``;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ViewDetailsSpan = styled.span`
  color: var(--blue-600);
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 3.5%;
`;

const Heading = styled.span`
  font-size: 0.875rem;
  color: var(--grey-950);
  font-weight: 500;
`;

const Description = styled.p`
  font-size: 0.875rem;
  color: var(--grey-700);
  font-weight: 400;
`;

const StyledLink = styled.a`
  font-size: 0.9rem;
  color: var(--blue-600);
  text-decoration: underline;
  &:hover {
    color: var(--blue-700);
  }
`;

export default function Insights() {
  const { t } = useTranslation();

  const Header = (title: string) => {
    return (
      <HeaderContainer>
        <HeaderContent>
          <InsightsLightBulb style={{ paddingBottom: '1px' }} />
          {title}
        </HeaderContent>
        <ViewDetailsSpan>View Details</ViewDetailsSpan>
      </HeaderContainer>
    );
  };

  const tableCols: TDataColumns<TInsight> = [
    {
      title: 'Insight Summary',
      dataIndex: 'title',
      type: 'text',
      showSortDirections: false,
      allowFiltering: true,
      width: 400,
      onCell: () => ({
        style: {
          padding: 0,
        },
      }),
      render: (_, record) => {
        return (
          <StyledCollapse expandIconPosition="end">
            <StyledCollapsePanel
              showArrow={true}
              header={Header(record.title)}
              key={record.id}
              style={{ borderRadius: '0px', border: 'none' }}
            >
              <div style={{ padding: '0 16px 16px' }}>
                <Heading>Description</Heading>
                <Description>{record.description}</Description>
                {record.reference && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Heading>Link:</Heading>
                    <StyledLink href={record.reference} target="_blank" rel="noopener noreferrer">
                      {record.reference}
                    </StyledLink>
                  </div>
                )}
              </div>
            </StyledCollapsePanel>
          </StyledCollapse>
        );
      },
    },
  ];

  const tableFilterProps = useTableFiltersV2<TInsight>({
    columns: tableCols,
    model: 'insight',
    constantFilter: { status: getEnumValue('InsightStatus', 'Active') },
    searchableColumns: ['title'],
    canFilter: false,
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  const dataTableProps = useDataTable<TInsight>({
    data: tableFilterProps.data?.items || [],
    columns: tableCols,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    showHeader: false,
    canSelectRows: false,
    canModifyTable: false,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
  });

  return (
    <BasePageWrapper
      title={t('common.insights')}
      description="Our team of experts has assembled valuable insights specifically tailored to your environment, providing relevant information and practical advice to enhance your security."
    >
      <PageTitle>{t('common.insights')}</PageTitle>

      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps} />
      </div>
      <DataTableV2 {...dataTableProps} />
    </BasePageWrapper>
  );
}
