import { useEffect, useMemo, useState } from 'react';

import { getModelItems } from '@app/api/table.api';
import CustomRadioInput from '@app/components/common/CustomInputs/CustomRadio';
import ItemSelect, { useItemSelect } from '@app/components/common/selects/ItemSelect';
import Separator from '@app/components/common/Separator/Separator';
import Filters from '@app/components/tables/AntdTableWrapper/components/Filters';
import { useTableFilters } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TFilterValue } from '@app/components/tables/AntdTableWrapper/types';
import { useLoadMore } from '@app/hooks/useLoadMore';
import { getEnumValue } from '@app/services/enum.service';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { constructFilterStateFromUrl, constructUrlFromState } from '@app/utils/utils';
import * as S from './RecommendationScoping.style';
import { useRecommendationScoping } from '../hooks/useRecommendationScoping';

interface IRecommendationScopingProps {
  onFilterChange: (filterString: string) => void;
  initialFilter?: string;
}

const RecommendationScoping = ({ onFilterChange, initialFilter }: IRecommendationScopingProps) => {
  const [searchString, setSearchString] = useState('');
  const [classificationFilters, setClassificationFilters] = useState<TFilterValue[]>([]);
  const [specificRecommendationFilter, setSpecificRecommendationFilter] = useState<string>('');
  const [scope, setScope] = useState<'classification_rules' | 'specific_recommendation'>(() => {
    if (initialFilter?.includes('id eq ')) {
      return 'specific_recommendation';
    }
    return 'classification_rules';
  });
  const [searchedResults, setSearchedresults] = useState<TAccountRecommendationExtended[] | undefined>(undefined);
  const [isSearching, setIsSearching] = useState(false);

  const { data, isFetching, loadMoreData } = useLoadMore({
    fetchFn: async (top, skip) => {
      const response = await getModelItems<TAccountRecommendationExtended>({
        model: 'recommendationExtended',
        queryParams: `$filter=(status eq ${getEnumValue('RecommendationStatus', 'Enabled')})&$top=${top}&$skip=${skip}`,
      });

      return response;
    },
    queryKey: 'specific-recommendation-scoping',
  });

  const { recommendationScopingCols } = useRecommendationScoping();

  useEffect(() => {
    if (scope === 'specific_recommendation' && initialFilter) {
      setSpecificRecommendationFilter(initialFilter);
    } else if (scope === 'classification_rules' && initialFilter) {
      const urlSearchParams = new URLSearchParams(initialFilter);
      if (!urlSearchParams) return;
      const parsedFilterValues = constructFilterStateFromUrl(urlSearchParams, recommendationScopingCols);
      setClassificationFilters(parsedFilterValues!);
    }
  }, [initialFilter, recommendationScopingCols, scope]);

  //* Classification Rules Scoping Filters
  const filterProps = useTableFilters({
    columns: recommendationScopingCols,
    defaultFilter: scope === 'classification_rules' ? classificationFilters : undefined,
    onFiltersChanged: (newFilters) => {
      if (scope === 'classification_rules') {
        setClassificationFilters(newFilters || []);
        const filterString = constructUrlFromState(
          { top: 50, skip: 0, filterValues: newFilters || [] },
          recommendationScopingCols,
        );
        onFilterChange(filterString);
      }
    },
  });

  //* Specific Recommendations Scoping Filters
  const recommendationItems = useMemo(() => {
    if (data) {
      return data
        .map((item) => ({ ...item, key: item.id, label: item.findings }))
        .sort((a, b) => (a.findings < b.findings ? -1 : 1));
    }
    return [];
  }, [data]);

  const searchedRecommendationItems = useMemo(() => {
    if (!searchedResults) {
      return undefined;
    }

    if (searchedResults.length > 0) {
      return searchedResults
        .map((item) => ({ ...item, key: item.id, label: item.findings }))
        .sort((a, b) => (a.findings < b.findings ? -1 : 1));
    }

    return [];
  }, [searchedResults]);

  const defaultSelectedItems = useMemo(() => {
    if (
      !specificRecommendationFilter ||
      typeof specificRecommendationFilter !== 'string' ||
      scope !== 'specific_recommendation' ||
      !data
    )
      return [];

    const selectedRecommendations: TAccountRecommendationExtended[] = [];
    const recommendationIds = specificRecommendationFilter
      .replaceAll('$filter=', '')
      .replaceAll('id eq ', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll("'", '')
      .split(' or ');

    recommendationIds.forEach((id) => {
      const selectedRecommendation = data.find((item) => item.id.toString() === id);
      if (selectedRecommendation) {
        selectedRecommendations.push(selectedRecommendation);
      }
    });

    return selectedRecommendations.map((item) => ({ ...item, key: item.id, label: item.findings }));
  }, [specificRecommendationFilter, scope, data]);

  const itemSelectProps = useItemSelect({
    items: !!searchedRecommendationItems ? searchedRecommendationItems : recommendationItems,
    defaultSelectedItems: defaultSelectedItems,
    isLoading: isFetching,
    isSearching: isSearching,
    onItemsSelectCallback: (selectedItems) => {
      if (selectedItems.length === 0) {
        setSpecificRecommendationFilter('');
        onFilterChange('');
      } else if (scope === 'specific_recommendation') {
        const filterString = `$filter=${selectedItems.map((item) => `(id eq '${item.id}')`).join(' or ')}`;
        setSpecificRecommendationFilter(filterString);
        onFilterChange(filterString);
      }
    },
    onSearchItemCallback: (searchString) => setSearchString(searchString),
    onEndReached: (e) => {
      // only load more data if the results shown is from the searched query and not the default loaded query
      if (!!searchedRecommendationItems) {
        return;
      }
      loadMoreData();
    },
  });

  useEffect(() => {
    const delayedSearch = setTimeout(async () => {
      setIsSearching(true);
      if (searchString === '') {
        setSearchedresults(undefined);
        setIsSearching(false);
        return;
      }

      const response = await getModelItems<TAccountRecommendationExtended>({
        model: 'recommendationExtended',
        queryParams: `$filter=contains(tolower(findings),'${searchString.toLowerCase()}') and (status eq ${getEnumValue(
          'RecommendationStatus',
          'Enabled',
        )})`,
      });

      setSearchedresults(response.items);
      setIsSearching(false);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchString]);

  return (
    <S.ScopingContainer>
      <S.ScopeWrapper>
        <S.RadioWrapper>
          <CustomRadioInput
            name="scope"
            value="classification_rules"
            checked={scope === 'classification_rules'}
            onChange={() => {
              setScope('classification_rules');
              setSpecificRecommendationFilter('');
              setClassificationFilters([]);
              onFilterChange('');
            }}
          />
          <span>Classification Rules</span>
        </S.RadioWrapper>

        {scope === 'classification_rules' && (
          <>
            <Separator />
            <Filters {...filterProps} />
          </>
        )}
      </S.ScopeWrapper>

      <S.ScopeWrapper>
        <S.RadioWrapper>
          <CustomRadioInput
            name="scope"
            value="specific_recommendation"
            checked={scope === 'specific_recommendation'}
            onChange={() => {
              setScope('specific_recommendation');
              setSpecificRecommendationFilter('');
              setClassificationFilters([]);
              onFilterChange('');
            }}
          />
          <span>Specific Recommendations</span>
        </S.RadioWrapper>

        {scope === 'specific_recommendation' && (
          <>
            <Separator />
            <ItemSelect {...itemSelectProps} />
          </>
        )}
      </S.ScopeWrapper>
    </S.ScopingContainer>
  );
};

export default RecommendationScoping;
