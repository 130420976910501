import { Spin } from 'antd';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import styled from 'styled-components';

import { useUpdateVendor } from '@app/api/user.api';
import { BaseCancelButton, BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { ModalFooterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { BaseSearchInput } from '@app/components/common/BaseSearch/SearchInput/BaseSearchInput';
import { useBaseSearchInput } from '@app/components/common/BaseSearch/SearchInput/useBaseSearchInput';
import { TVendorsExtended } from '@app/types/vendors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FieldLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--grey-950);
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onApply, onApplyDisabled }: IModalFooterProps) => {
  return (
    <ModalFooterContainer>
      <BaseCancelButton type="button" onClick={() => onCancel?.()}>
        Cancel
      </BaseCancelButton>
      <BaseCTAButton
        htmlType="button"
        onClick={() => onApply?.()}
        disabled={onApplyDisabled}
        style={{ padding: '12px 32px' }}
      >
        Save
      </BaseCTAButton>
    </ModalFooterContainer>
  );
};

interface IEditVendorModalProps extends ModalFuncProps {
  vendor: TVendorsExtended;
  isLoading?: boolean;
  onUpdateSuccess?: () => void;
}

export default function EditVendorModal({ vendor, onUpdateSuccess, ...modalProps }: IEditVendorModalProps) {
  const { mutateAsync: updateVendor, isLoading: isUpdateVendorLoading } = useUpdateVendor();

  const searchInputProps = useBaseSearchInput({
    placeholder: 'Reseller name',
  });

  const hanldeEditVendor = async () => {
    const response = await updateVendor({ vendorId: vendor.vendorId, vendorName: searchInputProps.searchValue });
    if (response) {
      onUpdateSuccess?.();
    }
  };

  const handleCloseModal = () => {
    searchInputProps.onChange('');
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={'Edit Reseller'}
      destroyOnClose={true}
      closable={false}
      onCancel={handleCloseModal}
      footer={
        isUpdateVendorLoading ? (
          <FooterContainer>
            <Spin />
          </FooterContainer>
        ) : (
          <ModalFooter
            onCancel={handleCloseModal}
            onApply={hanldeEditVendor}
            onApplyDisabled={searchInputProps.searchValue.length === 0}
          />
        )
      }
    >
      <Container>
        <FieldContainer>
          <FieldLabel>Reseller Name:</FieldLabel>
          <BaseSearchInput {...searchInputProps} style={{ width: '100%' }} />
        </FieldContainer>
      </Container>
    </Modal>
  );
}
