import { SearchOutlined } from '@ant-design/icons';
import { CSSProperties } from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px;
  gap: 12px;
  width: 35%;
  border: 1px solid var(--grey-200);
  border-radius: 4px;
`;
const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  width: 100%;

  ::placeholder {
    color: #acacac;
  }
`;

export interface IBaseSearchInputProps {
  searchValue?: string;
  placeholder?: string;
  style?: CSSProperties;
  onChange?: (newValue: string) => void;
}

export function BaseSearchInput({ onChange, placeholder, searchValue, style }: IBaseSearchInputProps) {
  return (
    <SearchContainer style={{ display: 'flex', alignItems: 'center', gap: '8px', ...style }}>
      <SearchOutlined style={{ color: '#ACACAC' }} />
      <SearchInput
        type="text"
        value={searchValue}
        onChange={(e) => onChange?.(e.target.value)}
        placeholder={placeholder}
      />
    </SearchContainer>
  );
}
